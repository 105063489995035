import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/joy';
import {
  CategoriesSelect,
  setSelectedCategoryId
} from '@features/games-filter-by-category';
import { Search, setSearch } from '@features/games-filter-by-search';
import { SelectFavorite, setIsFavorite } from '@features/games-filter-by-favorite';
import {
  FilterTypeEnum,
  selectActiveFilterType,
  setActiveFilterType,
  useGamesListWidgetSelector
} from '../model';

export const GamesFilter: FC = () => {
  const dispatch = useDispatch();

  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);

  const handleChangeCategory = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Category));
    dispatch(setSearch(''));
    dispatch(setIsFavorite(false));
  };

  const handleChangeSearch = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Search));
    dispatch(setSelectedCategoryId(null));
    dispatch(setIsFavorite(false));
  };

  const handleClearSearch = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Category));
  };

  const handleClickFavorite = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Favorite));
    dispatch(setSearch(''));
    dispatch(setSelectedCategoryId(null));
  };

  return (
    <Stack
      sx={(theme) => ({
        p: '0.75rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '0.5rem',
        background: theme.colorSchemes.dark.palette.common[475],
        columnGap: '0.5rem',
        [theme.breakpoints.down(912)]: {
          position: 'relative',
          width: 'fit-content',
          justifyContent: 'center',
          maxWidth: '100%',
          flexDirection: 'column',
          p: 0,
          gap: '1rem',
          background: theme.colorSchemes.dark.palette.common[900],
          pt: 'calc(1rem + 2.75rem)',
        },
      })}
    >
      <CategoriesSelect
        preloadByFirstCategory={activeFilterType === FilterTypeEnum.Category}
        selectFavoriteSlot={<SelectFavorite onSelect={handleClickFavorite} />}
        onChange={handleChangeCategory}
      />
      <Search
        onClear={handleClearSearch}
        onSearch={handleChangeSearch}
      />
    </Stack>
  );
};
