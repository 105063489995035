import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectUserStatus } from '@entities/session';
import {
  type HomePageState,
  type HomePageTitlePlayInThreeSteps,
  type HomePageStepPlayItem,
  type HomePageCarouselSliderItem,
} from './types';
import { homePageSlice } from './slice';

type RootState = {
  [homePageSlice.name]: HomePageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[homePageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[homePageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[homePageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[homePageSlice.name]?.canonical
);

export const selectTitlePlayInThreeSteps = (state: RootState): Maybe<HomePageTitlePlayInThreeSteps> => (
  state[homePageSlice.name]?.playInThreeSteps?.title
);

export const selectStepsPlayInThreeSteps = (state: RootState): Maybe<Array<HomePageStepPlayItem>> => (
  state[homePageSlice.name]?.playInThreeSteps?.steps
);

export const selectSlider = createSelector(
  selectUserStatus,
  (state: RootState): Array<HomePageCarouselSliderItem> => (
    state[homePageSlice.name]?.slider ?? []
  ),
  (userState, sliderItems) => {
    const result = sliderItems.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });
    return result;
  }
);

export const selectHomePageGamesByCategoriesBlockTop = (
  state: RootState
): Maybe<Array<number>> => (
  state[homePageSlice.name]?.gamesByCategoriesBlockTop
);

export const selectHomePageGamesByCategoriesBlockBottom = (
  state: RootState
): Maybe<Array<number>> => (
  state[homePageSlice.name]?.gamesByCategoriesBlockBottom
);

export const selectTournamentWidgetTitle = (
  state: RootState
): Maybe<string> => (
  state[homePageSlice.name].tournamentWidgetTitle
);

export const useHomePageSelector: TypedUseSelectorHook<{
  [homePageSlice.name]: HomePageState;
}> = useSelector;
