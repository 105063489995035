import { FC } from 'react';
import { Stack } from '@mui/joy';

export const SomethingWrongPage: FC = () => {
  return (
    <Stack
      sx={{
        height: '100vh',
        width: '100vw',
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Something went wrong
    </Stack>
  );
};
