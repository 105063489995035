import { ComponentType, useEffect, useMemo } from 'react';
import { Typography } from '@mui/joy';
import { useToaster } from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import { UserRegistrationStatusEnum } from '@shared/types';
import {
  useSessionSelector,
  selectIsAuthenticated,
  UserKycStatusEnum,
  selectRegistrationStatus,
  selectKycStatus,
} from '@entities/session';
import {
  CoinPackagesModal,
  PleaseConfirmYourIdentityModal,
  CheckoutModal,
} from '@features/buy-coins';

export const withBuyModal = <T extends object>(WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const isOpenBuyModal = useQueryParamModalVisibility('buy');
    const { error } = useToaster();
    const { closeModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const isAllowToBuy = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    useEffect(() => {
      if (!isAuthenticated && isOpenBuyModal) {
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
        });
      }
      //eslint-disable-next-line
    }, [isAuthenticated, isOpenBuyModal]);

    const handleClose = (): void => {
      closeModal('buy');
    };

    return (
      <>
        <WrappedComponent {...props} />
        {isOpenBuyModal && isAuthenticated && isAllowToBuy && (
          <CoinPackagesModal
            open={true}
            titleSlot={(
              <Typography level='h2' fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
            onClose={handleClose}
          />
        )}
        {isOpenBuyModal && isAuthenticated && !isAllowToBuy && (
          <PleaseConfirmYourIdentityModal
            open={true}
            titleSlot={(
              <Typography level='h2' fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
            onClose={handleClose}
          />
        )}
        <CheckoutModal />
      </>
    );
  };
