import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FilterOperation } from '@shared/lib';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import {
  selectMetaTagTitle,
  selectMetaTagKeywords,
  selectMetaTagDescription,
  selectMetaTagCanonical,
  useDynamicPageSelector,
  selectDynamicPageContentType,
  DynamicPageContentEnum,
} from '../model';
import { useGetDynamicPageQuery } from '../api';
import { dynamicPagesMapper } from './dynamic-pages-mapper';

export const Dynamic: FC = () => {
  const { dynamicPageName } = useParams();
  const { isFetching } = useGetDynamicPageQuery({
    name: { [FilterOperation.Equals]: dynamicPageName }
  }, {
    refetchOnMountOrArgChange: true
  });

  const contentType = useDynamicPageSelector(selectDynamicPageContentType);
  const title = useDynamicPageSelector(selectMetaTagTitle);
  const keywords = useDynamicPageSelector(selectMetaTagKeywords);
  const description = useDynamicPageSelector(selectMetaTagDescription);
  const canonical = useDynamicPageSelector(selectMetaTagCanonical);

  const renderComponent = () => {
    const Component = dynamicPagesMapper[contentType ?? DynamicPageContentEnum.NotLoaded];
    return Component
      ? <Component />
      : <Navigate to='/' replace />;
  };

  return !isFetching && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      {renderComponent()}
    </>
  );
};