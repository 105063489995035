import { mapper } from '@shared/lib';
import { type GameCategory } from '../model';
import { mapGamesItem } from './map-games-item';

type MapGameCategory = (data: any) => GameCategory;
export const mapGameCategory: MapGameCategory = mapper([
  { name: 'id', from: 'id' },
  { name: 'name', from: 'attributes.name' },
  { name: 'iconSrc', from: 'attributes.iconSrc.data.attributes.url' },
  { name: 'games', from: 'attributes.games.data', transform: (games: Array<any>) => games?.map(mapGamesItem) || [] },
]);
