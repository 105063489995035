import { FC, useMemo } from 'react';
import { Sheet, Stack, Table } from '@mui/joy';
import { isEmpty, useMedia } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import { type Transaction } from '../model';
import { getLabelForTransactionType } from '../lib';
import { TransactionCard } from './transaction-card.component';
import { NoTransactionsLabel } from './no-transactions-label.component';

export type TransactionTableProps = {
  transactions: Array<Transaction>;
  currencyCode: CurrencyCodesEnum;
};

export const TransactionTable: FC<TransactionTableProps> = ({ transactions, currencyCode }) => {
  const isTablet = useMedia(`(max-width: ${1023}px)`);
  const hasNoTransactions = useMemo(() => isEmpty(transactions), [transactions]);
  return isTablet ? (
    <Stack
      direction='column'
      sx={{
        width: '100%',
        gap: 1.25,
        mb: 2
      }}>
      {transactions.map(({
        id,
        dateTime,
        type,
        gameName,
        paymentSubMethod,
        amount
      }, index) => (
        <TransactionCard
          key={index}
          id={id}
          dateTime={dateTime}
          labelForTransactionType={getLabelForTransactionType(type, currencyCode)}
          gameName={gameName}
          paymentSubMethod={paymentSubMethod}
          amount={amount}
        />
      ))}
      {isEmpty(transactions) && (
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={({ palette }) => ({
            width: '100%',
            height: 220,
            overflow: 'hidden',
            borderRadius: 8,
            background: palette.common[475],
            borderColor: palette.common[925],
            borderWidth: 1,
            borderStyle: 'solid',
          })}>
          <NoTransactionsLabel />
        </Stack>
      )}
    </Stack>
  ) : (
    <Sheet
      sx={({ palette }) => ({
        minHeight: 404,
        width: '100%',
        backgroundColor: palette.common[475],
        borderRadius: 8,
      })}>
      <Table
        size='ml'
        sx={{
          gap: 1.25,
          width: '100%',
          '& thead th:nth-child(1)': {
            width: '30%'
          },
          '& thead th:nth-child(2)': {
            width: '15%'
          },
          '& thead th:nth-child(3)': {
            width: '18%'
          },
          '& thead th:nth-child(4)': {
            width: '22%'
          },
          ...(hasNoTransactions && ({
            height: '100%'
          })),
        }}>
        <thead>
          <tr>
            <th>Transaction&nbsp;ID</th>
            <th>Date</th>
            <th>Type</th>
            <th>Product</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.map(({
            id,
            dateTime,
            type,
            gameName,
            paymentSubMethod,
            amount
          }, index) => (
            <tr key={index}>
              <td>{id}</td>
              <td>{dateTime}</td>
              <td>{getLabelForTransactionType(type, currencyCode)}</td>
              <td>{gameName ?? paymentSubMethod}</td>
              <td>$&nbsp;{amount}</td>
            </tr>
          ))}
          {hasNoTransactions && (
            <tr>
              <td colSpan={5}>
                <NoTransactionsLabel />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Sheet>
  );
};