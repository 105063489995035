import { CurrentVIPLevelEnum } from '../model'

export const mapLevelImage = (level: Maybe<CurrentVIPLevelEnum>): string => {
  const levelImageMap = {
    [CurrentVIPLevelEnum.Level1]: '/assets/png/level1.png',
    [CurrentVIPLevelEnum.Level2]: '/assets/png/level2.png',
    [CurrentVIPLevelEnum.Level3]: '/assets/png/level3.png',
    [CurrentVIPLevelEnum.Level4]: '/assets/png/level4.png',
    [CurrentVIPLevelEnum.Level5]: '/assets/png/level5.png',
    [CurrentVIPLevelEnum.Level6]: '/assets/png/level6.png',
    [CurrentVIPLevelEnum.Level7]: '/assets/png/level7.png',
    [CurrentVIPLevelEnum.Level8]: '/assets/png/level8.png',
  }
  if (!level) return levelImageMap[CurrentVIPLevelEnum.Level1];
  const src = levelImageMap[level]
  return src ? src : levelImageMap[CurrentVIPLevelEnum.Level1]
}