import { baseApi } from '@shared/api';
import { PromotionsPageState } from '../model';
import { mapPromotionsPageState } from '../lib';

export const promotionsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotionsPage: build.query<PromotionsPageState, void>({
      query: () => ({ url: 'api/promotions-page' }),
      extraOptions: { showLoader: true },
      transformResponse: mapPromotionsPageState
    })
  })
})

export const { useGetPromotionsPageQuery } = promotionsPageApiSlice;