import { FC, MouseEvent, ReactNode } from 'react';
import {
  Divider,
  Modal,
  ModalDialog,
  ModalDialogProps,
  Sheet,
  Stack,
  Theme,
} from '@mui/joy';
import { SystemStyleObject } from '@mui/system';
import { CloseIcon, CredentialTabs, IconButton } from '@shared/ui';
import { isDefined, useMedia } from '@shared/lib';

export type AuthModalProps = Pick<ModalDialogProps, 'sx'> & {
  bannerContentSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  formContentSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  open: boolean;
  titleSlot?: ReactNode;
  headingSlot?: ReactNode;
  bannerSlot?: ReactNode;
  emailForm: ReactNode;
  phoneForm: ReactNode;
  bottomSlot?: ReactNode;
  onClose(): void;
};

export const AuthModal: FC<AuthModalProps> = ({
  open,
  titleSlot,
  headingSlot,
  bannerSlot,
  emailForm,
  phoneForm,
  bottomSlot,
  onClose,
  bannerContentSx,
  formContentSx,
  ...props
}) => {
  const isMobile = useMedia(`(max-width: ${490}px)`);

  const handleClose = (
    event: MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (reason === 'closeClick') onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalDialog
        layout={
          isMobile
            ? 'fullscreen'
            : 'center'
        }
        sx={({ breakpoints }) => ({
          overflowY: 'hidden',
          [breakpoints.down(491)]: {
            borderRadius: 0,
            boxShadow: 'none',
            overflowY: 'auto',
          }
        })}
        {...props}>
        <Sheet
          sx={({ breakpoints }) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            minHeight: 588,
            width: 882,
            [breakpoints.down(929)]: {
              display: 'flex',
              width: 441,
            },
            [breakpoints.down(491)]: {
              display: 'flex',
              height: '100%',
              width: '100%',
            },
          })}>
          <Sheet
            sx={[
              ({ breakpoints }) => ({
                height: '100%',
                position: 'relative',
                [breakpoints.up(490)]: {
                  display: 'block'
                },
                [breakpoints.down(490)]: {
                  display: 'none',
                  background: 'red'
                },
              }),
              bannerContentSx ?? false
            ]}>
            {bannerSlot}
          </Sheet>
          <Stack
            direction='column'
            justifyContent='flex-start'
            alignItems='center'
            sx={[({ breakpoints }) => ({
              width: '100%',
              height: '100%',
              paddingInline: 5,
              paddingTop: 3,
              paddingBottom: 4,
              [breakpoints.down(490)]: {
                paddingInline: 2,
                paddingTop: 2,
                paddingBottom: 4,
              }
            }),
            formContentSx ?? false
            ]}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent={
                isDefined(titleSlot)
                  ? 'space-between'
                  : 'flex-end'}
              sx={{
                width: '100%',
                marginBottom: 3,
              }}>
              {titleSlot}
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              direction='column'
              justifyContent='space'
              alignItems='center'
              sx={{ width: '100%' }}>
              {bannerSlot && (
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  sx={bannerContentSx
                    ? bannerContentSx
                    : { display: 'none' }}>
                  {bannerSlot}
                </Stack>
              )}
              {headingSlot && (
                <>
                  {headingSlot}
                  <Divider sx={{
                    margin: '1rem auto',
                    width: '7.9375rem',
                    gap: '0.625rem',
                  }}>
                    or
                  </Divider>
                </>
              )}
              <Stack
                direction='column'
                gap={1}
                width='100%'>
                <CredentialTabs
                  phoneFormSlot={phoneForm}
                  emailFormSlot={emailForm}
                />
                {bottomSlot}
              </Stack>
            </Stack>
          </Stack>
        </Sheet>
      </ModalDialog>
    </Modal >
  );
};