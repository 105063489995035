import { FC } from 'react';
import { Box, Skeleton, Theme } from '@mui/joy';
import { SystemStyleObject } from '@mui/system';

type GameListItemSkeletonProps = {
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
};

export const GameListItemSkeleton: FC<GameListItemSkeletonProps> = ({ sx }) => {
  return (
    <Box
      sx={[
        (theme) => ({
          [theme.breakpoints.up(768)]: {
            width: '12.5rem',
            height: '9.5rem',
          },
          [theme.breakpoints.down(390)]: {
            width: '150px',
            height: '112.5px',
          },
          borderRadius: '0.5rem',
          height: '7.5rem',
          width: '10.8125rem',
          position: 'relative',
          overflow: 'hidden',
          zIndex: 1,
        }),
        sx ?? false,
      ]}>
      <Skeleton variant='overlay' />
    </Box>
  );
};
