import { baseApi, GAME_PAGE_TAG } from '@shared/api';
import { type GameItem } from '../model';
import { mapGameItem } from '../lib';

export const gamePageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGame: build.query<GameItem, number>({
      query: (id) => ({ url: `/api/games/${id}` }),
      extraOptions: { showLoader: true },
      providesTags: [GAME_PAGE_TAG],
      transformResponse: (response: any) => mapGameItem(response),
    }),
  }),
});

export const { useLazyGetGameQuery } = gamePageApiSlice;
