import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Stack, Box, Typography } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';
import { useGamesFilterByFavoriteSelector, selectIsFavorite, setIsFavorite } from '../model';
import { fetchGamesByFavorite } from '../api';

export type SelectFavoriteProps = {
  onSelect(): void;
};

export const SelectFavorite: FC<SelectFavoriteProps> = ({ onSelect }) => {
  const imgSrc = '/assets/png/star.png';
  const isMobileView = useMedia(`(max-width: ${768}px)`);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const isFavorite = useGamesFilterByFavoriteSelector(selectIsFavorite);

  const handleClick = async (): Promise<void> => {
    await dispatch(setIsFavorite(true));
    await dispatch(fetchGamesByFavorite({ page: 1, pageSize: isMobileView ? 12 : 34 })).unwrap();
    onSelect();
  };

  return (
    isAuthenticated && (
      <Stack
        onClick={handleClick}
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          paddingBlock: 0.25,
          paddingInline: 1,
          borderRadius: 10,
          cursor: 'pointer',
          color: isFavorite
            ? theme.colorSchemes.dark.palette.common.white
            : theme.colorSchemes.dark.palette.common[150],
          background: isFavorite ? theme.colorSchemes.dark.palette.common[300] : 'transparent',
          '&:hover': {
            background: isFavorite
              ? theme.colorSchemes.dark.palette.common[300]
              : theme.colorSchemes.dark.palette.common[500],
          },
        })}
      >
        <Box component='img' src={imgSrc} sx={{ width: '1rem', aspectRatio: '1/1' }} />
        <Typography>Favorite</Typography>
      </Stack>
    )
  );
};
