import { FC, ReactElement, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/joy';
import { IconButton } from '@shared/ui';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';
import { InfoToolTip } from './info-tooltip.component';

type DetailsProps = {
  name: string;
  features: string;
  volatility: string;
  rtp: string;
  favoriteSwitcherSlot: ReactElement;
  launchGameSlot: ReactElement;
};

export const GameListItemDetails: FC<DetailsProps> = ({
  name,
  features,
  rtp,
  volatility,
  favoriteSwitcherSlot,
  launchGameSlot,
}) => {
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const showTooltip = Boolean(features || rtp || volatility);

  const handleClickShowInfo = () => {
    setShowInfo((state) => !state);
  };


  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.90)',
        padding: '0.5rem 0.75rem',
        [theme.breakpoints.down(768)]: {
          padding: '0.5rem',
        },
      })}
    >
      <Stack direction='row' justifyContent={isAuthenticated ? 'space-between' : 'end'} zIndex='1' position='relative'>
        {favoriteSwitcherSlot}
        {
          showTooltip && (
            <InfoToolTip open={showInfo} features={features} rtp={rtp} volatility={volatility}>
              <IconButton
                sx={{
                  padding: 0,
                  minHeight: 'min-content',
                  minWidth: 'min-content',
                  fontSize: '14px',
                }}
                onClick={handleClickShowInfo}
              >
                <InfoOutlined
                  sx={(theme) => ({
                    color: theme.colorSchemes.dark.palette.common.white,
                    fontSize: '1.25rem',
                  })}
                />
              </IconButton>
            </InfoToolTip>
          )
        }
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          paddingInline: '0.25rem',
        }}
      >
        <Typography
          sx={(theme) => ({
            color: theme.colorSchemes.dark.palette.common.white,
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.5rem',
            textAlign: 'center',
          })}
        >
          {name}
        </Typography>

        {launchGameSlot}
      </Stack>
    </Box>
  );
};
