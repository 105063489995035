import { TypedUseSelectorHook, useSelector } from "react-redux";
import { EmailVerificationStatusEnum, SessionState, sessionSlice } from "@entities/session";

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectIsEmailVerified = (state: RootState): boolean => (
  state[sessionSlice.name]?.fullProfile?.emailVerificationStatus === EmailVerificationStatusEnum.Verified
)

export const useVerifyEmailSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;