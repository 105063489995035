import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@shared/ui';
import {
  selectToggledCurrencyCode,
  useSessionSelector
} from '@entities/session';
import {
  TransactionTypeEnum,
} from '@entities/transactions';
import {
  setCurrentTransactionType,
  resetTransactionList,
  selectCurrentTransactionType,
  useTransactionListSelector
} from '../model';
import { CurrencyCodesEnum } from '@shared/types';

export type TransactionFilterByTypeProps = {
  disabled?: boolean;
  onChange?: () => void;
};

export const TransactionTypeDropdown: FC<TransactionFilterByTypeProps> = ({ disabled, onChange }) => {
  const dispatch = useDispatch();

  const toggledCurrencyCode = useSessionSelector(selectToggledCurrencyCode);
  const currentTransactionType = useTransactionListSelector(selectCurrentTransactionType);
  const transactionTypes = useMemo<Array<{ label: string, value: TransactionTypeEnum }>>(
    () => {
      const labelPostfixMatrix: Record<CurrencyCodesEnum, string> = {
        GCO: 'GC',
        SCO: 'SC'
      };
      return [
        { label: `Bet with ${labelPostfixMatrix[toggledCurrencyCode]}`, value: TransactionTypeEnum.GameBet },
        { label: `Win with ${labelPostfixMatrix[toggledCurrencyCode]}`, value: TransactionTypeEnum.GameWin },
        { label: 'Redemption', value: TransactionTypeEnum.Withdrawal },
        { label: 'Purchase', value: TransactionTypeEnum.Deposit },
      ];
    },
    [toggledCurrencyCode]
  );

  useEffect(() => {
    return () => {
      dispatch(resetTransactionList());
    };
  }, [dispatch]);

  const handleSelectTransactionType = (value: unknown): void => {
    dispatch(setCurrentTransactionType(value as TransactionTypeEnum));
  };

  return (
    <Dropdown
      label='Transaction type'
      value={currentTransactionType}
      onChange={handleSelectTransactionType}
      disabled={disabled}
      options={transactionTypes}
      inputProps={{
        root: {
          sx: ({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          }),
        },
        label: {
          sx: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
        },
      }}
    />
  );
};