import compose from 'compose-function';
import { withJoyTheme, withMuiTheme } from '@shared/theme';
import { withStore } from './with-store';
import { withCheckLoggedIn } from './with-check-logged-in';
import { withLoaderRoot } from './with-loader-root';
import { withToast } from './with-toast';

export const withProviders = compose(
  withJoyTheme,
  withMuiTheme,
  withStore,
  withCheckLoggedIn,
  withLoaderRoot,
  withToast
);
