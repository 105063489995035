import { baseOmegaApi } from '@shared/api';
import { mapTournaments } from '../lib';
import { GetPublicTournamentsRequest } from './types';

export const tournamentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicTournaments: build.query<any, GetPublicTournamentsRequest>({
      query: (args) => ({
        url: '/ips/getTournaments',
        params: args,
      }),
      extraOptions: { showLoader: true },
      transformResponse: (response: any) => mapTournaments(response),
    }),
    getPrivateTournaments: build.query<any, GetPublicTournamentsRequest>({
      query: (args) => ({
        url: '/ips/getMyTournament',
        params: args,
      }),
      extraOptions: { auth: true, showLoader: true },
      transformResponse: (response: any) => mapTournaments(response),
    }),
  }),
});
