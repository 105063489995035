import { mapper } from '@shared/lib';
import { PromotionsPageState } from '../model';
import { mapPromotionCards } from './map-promotion-cards';

type MapPromotionsPageState = (data: any) => PromotionsPageState
export const mapPromotionsPageState: MapPromotionsPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  { name: 'heading', from: 'data.attributes.heading' },
  {
    name: 'promotionCards',
    from: 'data.attributes.promotionCards',
    transform: (data: Array<any>) => data.map(mapPromotionCards)
  },
])