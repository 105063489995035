import { FC } from 'react'
import { CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/joy';

type VerificationStatusProps = {
  isVerified: boolean;
}

export const VerificationStatus: FC<VerificationStatusProps> = ({ isVerified }) => {
  const theme = useTheme();

  return <Stack
    direction='row'
    alignItems='center'
    justifyContent='flex-start'
    gap={.5}>
    {isVerified ?
      <CheckCircleOutline
        sx={{
          fontSize: 16,
          color: theme.palette.common[825]
        }}
      />
      :
      <CancelOutlined
        sx={{
          fontSize: 16,
          color: theme.palette.common.error
        }}
      />
    }
    <Typography
      sx={{
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        color: isVerified ? theme.palette.common[825] : theme.palette.common.error
      }}>
      {isVerified ? 'Verified' : 'Not verified'}
    </Typography>
  </Stack>
};