import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectUserStatus } from '@entities/session';
import { dynamicPageSlice } from './slice';
import { selectDynamicPageContent } from './common-selectors';
import {
  type DynamicPageState,
  type PromotionContent
} from './types';

export const selectPromotionContent = createSelector(
  selectDynamicPageContent,
  (promotionContent) => promotionContent as Maybe<PromotionContent>
);

export const selectSlider = createSelector(
  selectUserStatus,
  selectPromotionContent,
  (userState, promotionContent) => {
    const sliderWithActionByUserStatus = promotionContent?.slider?.map(({ actions, ...props }) => {
      const actionByUserStatus = actions.find(actionItem => actionItem.userState === userState);
      return ({ ...props, action: actionByUserStatus });
    });
    return sliderWithActionByUserStatus;
  }
);

export const selectHowItWorksBlock = createSelector(
  selectPromotionContent,
  (promotionContent) => promotionContent?.howItWorksBlock
);

export const selectPromotionFaqBlock = createSelector(
  selectPromotionContent,
  (promotionContent) => promotionContent?.faqBlock
);

export const usePromotionPageContentSelector: TypedUseSelectorHook<{
  [dynamicPageSlice.name]: DynamicPageState;
}> = useSelector;