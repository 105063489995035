import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  type MessagesState,
  type SetMessagePayload,
  type ChannelMessage,
  type ClearMessagePayload,
} from './types';

const initialState: MessagesState = {
  channels: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessage: (state: MessagesState, action: PayloadAction<SetMessagePayload>) => {
      const { channelId, message } = action.payload;

      const newMessages: Array<ChannelMessage> = [...(state.channels[channelId] ?? []), message];
      state.channels[channelId] = newMessages;
    },
    clearMessage: (state: MessagesState, action: PayloadAction<ClearMessagePayload>) => {
      const { channelId } = action.payload;

      const newMessages: Array<ChannelMessage> = [...(state.channels[channelId] ?? []).slice(1)];
      state.channels[channelId] = newMessages;
    },
  },
});

export const messagesReducer = messagesSlice.reducer;
export const { setMessage, clearMessage } = messagesSlice.actions;
