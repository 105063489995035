import { FC } from 'react';
import { Box, Skeleton } from '@mui/joy';
import 'swiper/css/pagination';
import 'swiper/css';

export const GamesCategoryItemHeaderSkeleton: FC = () => {
  return (
    <Box
      sx={{
        borderRadius: 8,
        height: 40,
        width: 150,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Skeleton variant='overlay' />
    </Box>
  );
};
