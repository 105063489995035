import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, StackProps, Typography } from '@mui/joy';
import { TextInput, VerificationStatus } from '@shared/ui';
import { emailSchema } from '@shared/lib';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import {
  useSessionSelector,
  useLazyUpdatePlayerInfoQuery,
  selectFullProfile,
  useLazyGetPlayerInfoQuery,
  useLazyResendVerificationCodeQuery
} from '@entities/session';
import {
  selectIsEmailVerified,
  useVerifyEmailSelector
} from '../model';
import {
  ConfirmEmailChangeModal
} from './confirm-email-change-modal.component';

export type VerifyEmailCardProps = Maybe<{
  inputProps?: Maybe<{
    root?: StackProps
  }>
}>;

export const VerifyEmailCard: FC<VerifyEmailCardProps> = (props) => {
  const profile = useSessionSelector(selectFullProfile);
  const isEmailVerified = useVerifyEmailSelector(selectIsEmailVerified);

  const [updateProfile, { isFetching: isFetchingUpdateProfile }] = useLazyUpdatePlayerInfoQuery();
  const [resendVerificationCode, { isFetching: isFetchingSendOpt }] = useLazyResendVerificationCodeQuery();
  const [getProfile, { isFetching: isFetchingGetProfile }] = useLazyGetPlayerInfoQuery();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const { control, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(emailSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { email: profile?.email || "" }
  });

  const handleEditEmail = async (formData: { email: string }): Promise<void> => {
    if (!profile?.userId) return;

    const updateResponse = await updateProfile({ email: formData.email })
    if (updateResponse.data?.status !== OmegaApiResponseStatusEnum.Success) {
      return setError('email', { message: 'Invalid entry. Please try again.' });
    }

    const sendOptResponse = await resendVerificationCode({
      verificationTarget: 'email',
      username: profile?.userId
    })
    if (sendOptResponse.data?.status !== OmegaApiResponseStatusEnum.Success ||
      sendOptResponse.data?.errors?.length) {
      return setError('email', { message: 'Something went wrong!' });
    }

    const getProfileResponse = await getProfile();
    if (getProfileResponse.data?.status !== OmegaApiResponseStatusEnum.Success) {
      return setError('email', { message: 'Something went wrong!' });
    }

    setOpenConfirmModal(true);
  };

  const handleCloseModal = (): void => {
    setOpenConfirmModal(false);
  };

  return (
    <Stack
      direction='column'
      gap={1.25}
      sx={[
        ({ palette, breakpoints }) => ({
          paddingInline: 2,
          paddingBlockStart: 2,
          borderRadius: 8,
          maxWidth: 426.5,
          background: palette.common[475],
          [breakpoints.down(768)]: {
            width: '100%'
          }
        }),
        ...(props?.inputProps?.root && Array.isArray(props?.inputProps?.root?.sx)
          ? props?.inputProps?.root?.sx
          : [props?.inputProps?.root?.sx])
      ]}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'>
        <Typography
          sx={({ palette }) => ({
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '30px',
            color: palette.common.white
          })}>
          Email
        </Typography>
        <VerificationStatus isVerified={isEmailVerified} />
      </Stack>
      <Typography
        sx={({ palette }) => ({
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: palette.common[150]
        })}>
        Linked and verified to your account email can be used to login from multiple devices
      </Typography>
      <Controller
        name='email'
        control={control}
        render={({ field: { ref, onBlur, onChange, value }, fieldState }) =>
          <TextInput
            disabled={isFetchingUpdateProfile || isFetchingSendOpt || isFetchingGetProfile}
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={fieldState.error?.message}
            placeholder='Enter your email'
            slotProps={{
              root: {
                sx: { height: 40 }
              },
              input: {
                sx: { height: 24 }
              }
            }}
            endDecorator={
              <Button
                onClick={handleSubmit(handleEditEmail)}
                disabled={
                  !formState.isValid ||
                  isFetchingUpdateProfile ||
                  isFetchingSendOpt ||
                  isFetchingGetProfile
                }
                loading={
                  isFetchingUpdateProfile ||
                  isFetchingSendOpt ||
                  isFetchingGetProfile
                }
                variant='solid'
                color='primary'
                size='sm'>
                {isEmailVerified ? 'Edit' : 'Verify'}
              </Button>
            }
          />
        }
      />
      <ConfirmEmailChangeModal
        open={openConfirmModal}
        onClose={handleCloseModal}
      />
    </Stack>
  );
};