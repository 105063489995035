import { ComponentType } from 'react';
import { Typography } from '@mui/joy';
import { SingInPromoBanner } from '@entities/page-layout';
import { Link } from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import { useLazyGetMyFavoriteGamesFromOmegaQuery } from '@entities/games';
import {
  SignInByEmailForm,
  SignInByPhoneForm
} from '@features/sign-in';
import {
  SignUp,
  openConfirmationSignUpModal,
  setPropsToSignUp
} from '@features/sign-up';
import { SocialLogin } from '@features/social-auth';
import { ResetPasswordLink } from '@features/reset-password';
import { AuthModal } from '@widgets/auth-modal';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line operator-linebreak
export const withSignInModal =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      const isOpenSignInModal = useQueryParamModalVisibility('login');
      const { toggleModal, closeModal } = useModalQueryParam();
      const dispatch = useDispatch();
      const [getMyFavoriteGamesFromOmega] = useLazyGetMyFavoriteGamesFromOmegaQuery();

      const handleClose = (): void => {
        closeModal('login');
      };

      const handleSignUp = (): void => {
        toggleModal('login', 'sign-up');
      };

      const handleSignedIn = (): void => {
        getMyFavoriteGamesFromOmega();
      };

      const handleNotCompletedSignUpError = (props: unknown): void => {
        closeModal('login');
        dispatch(setPropsToSignUp(props as SignUp));
        dispatch(openConfirmationSignUpModal());
      };

      return (
        <>
          <WrappedComponent {...props} />
          <AuthModal
            open={isOpenSignInModal}
            onClose={handleClose}
            titleSlot={
              <Typography level='h3' fontSize='1.25rem'>
                Login
              </Typography>
            }
            headingSlot={<SocialLogin />}
            bannerSlot={<SingInPromoBanner />}
            emailForm={(
              <SignInByEmailForm
                onSignedIn={handleSignedIn}
                onNotCompletedSignUpError={handleNotCompletedSignUpError}
                resetPasswordSlot={<ResetPasswordLink />} 
              />
            )}
            phoneForm={(
              <SignInByPhoneForm
                onSignedIn={handleSignedIn}
                onNotCompletedSignUpError={handleNotCompletedSignUpError}
                resetPasswordSlot={<ResetPasswordLink />} 
              />
            )}
            bottomSlot={
              <Typography textAlign='center' color='neutral'>
                Don’t have an account?&nbsp;
                <Link
                  color='success'
                  underline='none'
                  onClick={handleSignUp}>
                  Sign up
                </Link>
              </Typography>
            }
          />
        </>
      );
    };
