import { FC, useMemo } from 'react'
import { Typography, LinearProgress, Stack, Skeleton } from '@mui/joy';
import {
  CurrentVIPLevelEnum,
  selectCurrentVIPLevel,
  useGetLoyaltyPointsQuery,
  useSessionSelector
} from '@entities/session';
import { mapNextLevel } from '../lib';
import {
  useLoyaltyProgressBarSelector,
  selectRemainingPointsForNextLevel,
  selectTotalPointsForNextLevel
} from '../model';

// TODO: refactoring needed
export const LoyaltyProgressBar: FC = () => {
  const { isFetching } = useGetLoyaltyPointsQuery();

  const currentVIPLevel = useSessionSelector(selectCurrentVIPLevel);
  const remainingPointsForNextLevel = useLoyaltyProgressBarSelector(selectRemainingPointsForNextLevel);
  const totalPointsForNextLevel = useLoyaltyProgressBarSelector(selectTotalPointsForNextLevel);

  const nextLevel = useMemo(() => mapNextLevel(currentVIPLevel), [currentVIPLevel]);
  const progressToNextLevel = useMemo(() => {
    if (typeof remainingPointsForNextLevel !== 'number' || typeof totalPointsForNextLevel !== 'number') return 0;
    return 100 - (remainingPointsForNextLevel / totalPointsForNextLevel) * 100;
  }, [remainingPointsForNextLevel, totalPointsForNextLevel])

  return currentVIPLevel !== CurrentVIPLevelEnum.Level8 && (
    <Stack
      sx={({ palette }) => ({
        backgroundColor: palette.common[475],
        padding: '1.25rem',
        width: '100%',
        borderRadius: '0.5rem',
        gap: '0.75rem'
      })}>
      <Typography sx={({ palette, breakpoints }) => ({
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: palette.common.white,
        [breakpoints.down(786)]: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        }
      })}>
        Upgrade Your {' '}
        <Typography
          sx={({ palette }) =>
          ({
            color: palette.common[300],
            position: 'relative'
          })}>
          {isFetching ? <Skeleton
            variant='inline'
            width='5rem'
            sx={{
              display: 'inline-block',
              height: '1rem',
              mb: '-0.125rem',
            }} />
            :
            currentVIPLevel}
        </Typography>{' '}
        and get exclusive access to rewards and personalized gifts
      </Typography>
      <LinearProgress determinate value={progressToNextLevel} size='lg' />
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          sx={({ palette, breakpoints }) => ({
            color: palette.common[150],
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            [breakpoints.down(768)]: {
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.25rem',
            }
          })}
        >
          {isFetching ? <Skeleton variant='text' width='5rem' /> : currentVIPLevel}
        </Typography>
        <Typography
          sx={({ palette, breakpoints }) => ({
            color: palette.common[150],
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            [breakpoints.down(768)]: {
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.25rem',
            }
          })}
        >
          {isFetching ? <Skeleton variant='text' width='5rem' /> : nextLevel}
        </Typography>
      </Stack>
    </Stack>
  );
};