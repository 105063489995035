import { baseApi, DEFAULT_PAGE_LAYOUT_TAG } from '@shared/api';
import { type LayoutState } from '../model';

export const pageLayoutApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPageLayout: build.query<LayoutState, void>({
      query: () => ({ url: 'api/default-page-layout' }),
      providesTags: [DEFAULT_PAGE_LAYOUT_TAG],
      extraOptions: { showLoader: true }
    })
  }),
});

export const {
  useGetPageLayoutQuery,
  useLazyGetPageLayoutQuery
} = pageLayoutApiSlice;
