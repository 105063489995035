import { FC } from 'react';
import {
  Stack,
  Typography,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from '@mui/joy';
import { Link } from '@shared/ui';
import { useMedia } from '@shared/lib';

type NavigationSectionDataItem = {
  title: string;
  items: Array<{ text: string; slug: string; }>;
};

export type NavigationSectionProps = {
  data: Maybe<Array<NavigationSectionDataItem>>;
};

export const NavigationSection: FC<NavigationSectionProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMedia(`(max-width: ${430}px)`);
  return isMobile ? (
    <AccordionGroup
      disableDivider
      sx={{
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 1
      }}>
      {data?.map(({ title, items }, index) => (
        <Accordion
          key={index}
          sx={{ paddingInline: 0 }}>
          <AccordionSummary
            variant='plain'
            sx={{
              '& .MuiAccordionSummary-button': {
                // refactoring needed
                backgroundColor: `${theme.colorSchemes.dark.palette.common[475]} !important`
              },
              '& .MuiAccordionSummary-indicator svg': {
                fontSize: 24
              },
              '& .MuiAccordionSummary-indicator.Mui-expanded svg': {
                color: theme.colorSchemes.dark.palette.common[300]
              }
            }}>
            <Typography sx={{
              fontSize: 16,
              color: theme.colorSchemes.dark.palette.common.white
            }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {items.map(({ text, slug }, index) => (
              <Link
                color='neutral'
                underline='none'
                key={index}
                href={slug}>
                {text}
              </Link>
            ))}
          </AccordionDetails>
        </Accordion >
      ))}
    </AccordionGroup >
  ) : (
    <Stack
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        columnGap: 4.5,
        rowGap: 2,
      }}>
      {data?.map(({ title, items }, index) => (
        <Stack
          key={index}
          direction='column'>
          <Typography sx={{
            fontSize: 16,
            color: theme.colorSchemes.dark.palette.common.white
          }}>
            {title}
          </Typography>
          <Stack direction='column'>
            {items.map(({ text, slug }, index) => (
              <Link
                color='neutral'
                underline='none'
                key={index}
                href={slug}>
                {text}
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}; 