import { FC, useState } from 'react';
import { Button, Stack, StackProps, Typography } from '@mui/joy';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useSessionSelector,
  selectFullProfile,
  useLazyUpdatePlayerInfoQuery,
  useLazyResendVerificationCodeQuery,
  useLazyGetPlayerInfoQuery
} from '@entities/session';
import { PhoneInput, VerificationStatus } from '@shared/ui';
import { phoneRequiredOnlySchema } from '@shared/lib';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { ConfirmPhoneChangeModal } from './confirm-phone-change-modal.component';

export type VerifyPhoneCardProps = Maybe<{
  inputProps?: Maybe<{
    root?: StackProps
  }>
}>;

export const VerifyPhoneCard: FC<VerifyPhoneCardProps> = (props) => {
  const profile = useSessionSelector(selectFullProfile)
  const isPhoneVerified = false; //TODO: problem with Omega, figure out how to check if phone is verified

  const [updateProfile, { isFetching: isFetchingUpdateProfile }] = useLazyUpdatePlayerInfoQuery();
  const [sendOpt, { isFetching: isFetchingSendOpt }] = useLazyResendVerificationCodeQuery();
  const [getProfile, { isFetching: isFetchingGetProfile }] = useLazyGetPlayerInfoQuery();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const { control, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(phoneRequiredOnlySchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { mobile: profile?.phone || '' }
  })

  const handleEditPhone = async (formData: { mobile: string }): Promise<void> => {
    if (!profile?.userId) {
      return;
    }

    const updateResponse = await updateProfile({ mobilePhone: formData.mobile })

    if (updateResponse.data?.status === OmegaApiResponseStatusEnum.ValidationFail) {
      setError('mobile', { message: 'Invalid entry. Please try again.' })
      return;
    } else if (updateResponse.data?.status !== OmegaApiResponseStatusEnum.Success) {
      setError('mobile', { message: 'Something went wrong!' })
      return;

    }

    const sendOptResponse = await sendOpt({ verificationTarget: 'mobile', username: profile?.userId })
    if (sendOptResponse?.data?.errors?.length) {
      setError('mobile', { message: sendOptResponse.data.errors[0].error })
      return;
    } else if (sendOptResponse?.data?.status !== OmegaApiResponseStatusEnum.Success) {
      setError('mobile', { message: 'Something went wrong' })
      return;
    }

    const getProfileResponse = await getProfile();
    if (getProfileResponse.data?.status !== OmegaApiResponseStatusEnum.Success) {
      setError('mobile', { message: 'Something went wrong!' })
      return;
    }

    setOpenConfirmModal(true)
  }

  const handleCloseModal = () => {
    setOpenConfirmModal(false)
  }

  return (
    <Stack
      direction='column'
      gap={1.25}
      sx={[
        ({ palette, breakpoints }) => ({
          paddingInline: 2,
          paddingBlockStart: 2,
          borderRadius: 8,
          maxWidth: 426.5,
          background: palette.common[475],
          [breakpoints.down(768)]: {
            width: '100%'
          }
        }),
        ...(props?.inputProps?.root && Array.isArray(props?.inputProps?.root?.sx)
          ? props?.inputProps?.root?.sx
          : [props?.inputProps?.root?.sx])
      ]}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'>
        <Typography
          sx={({ palette }) => ({
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '30px',
            color: palette.common.white
          })}>
          Phone number
        </Typography>
        <VerificationStatus isVerified={isPhoneVerified} />
      </Stack>
      <Typography
        sx={({ palette }) => ({
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: palette.common[150]
        })}>
        Linked and verified to your account email can be used to login from multiple devices
      </Typography>
      <Controller
        name='mobile'
        control={control}
        render={({ field: { ref, onBlur, onChange, value }, fieldState }) =>
          <PhoneInput
            disabled={isFetchingUpdateProfile || isFetchingSendOpt || isFetchingGetProfile}
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={fieldState.error?.message}
            slotProps={{
              root: {
                sx: { height: 40 }
              },
              input: {
                sx: { height: 24 }
              }
            }}
            endDecorator={
              <Button
                onClick={handleSubmit(handleEditPhone)}
                disabled={!formState.isValid || isFetchingUpdateProfile || isFetchingSendOpt || isFetchingGetProfile}
                loading={isFetchingUpdateProfile || isFetchingSendOpt || isFetchingGetProfile}
                variant='solid'
                color='primary'
                size='sm'>
                {isPhoneVerified ? 'Edit' : 'Verify'}
              </Button>
            }
          />}
      />
      <ConfirmPhoneChangeModal open={openConfirmModal} onClose={handleCloseModal} />
    </Stack>
  );
};