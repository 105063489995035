import { FC, useCallback } from 'react';
import { Star, StarBorderOutlined } from '@mui/icons-material';
import { styled } from '@mui/joy';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { IconButton } from '@shared/ui';
import {
  type GamesItem,
  addFavoriteGame,
  removeFavoriteGame,
  selectIsFavoriteLoadingFromOmega,
  selectIsGameFavorite,
  useGamesSelector,
} from '@entities/games';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';

const StyledIconButton = styled(IconButton)({
  padding: 0,
  minHeight: 'min-content',
  minWidth: 'min-content',
  fontSize: '14px',
});

type FavoriteGameSwitcherProps = {
  game: GamesItem;
};

export const FavoriteGameSwitcher: FC<FavoriteGameSwitcherProps> = ({ game }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isFavorite = useGamesSelector(selectIsGameFavorite(game.launchId, game.productCode));
  const isFetchingFavoriteGames = useGamesSelector(selectIsFavoriteLoadingFromOmega);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const handleFavoriteClick = useCallback(() => {
    if (isFetchingFavoriteGames) {
      return;
    }

    isFavorite
      ? dispatch(removeFavoriteGame({ gameId: game.gameId, platformCode: game.productCode }))
      : dispatch(addFavoriteGame({ gameId: game.gameId, platformCode: game.productCode }));
  }, [isFetchingFavoriteGames, isFavorite, game, dispatch]);

  return (
    isAuthenticated && (
      <StyledIconButton onClick={handleFavoriteClick} disabled={isFetchingFavoriteGames}>
        {isFetchingFavoriteGames && (
          <Star
            sx={(theme) => ({
              color: theme.colorSchemes.dark.palette.common[150],
              fontSize: '1.25rem',
              [theme.breakpoints.down(768)]: {
                fontSize: '1rem',
              },
            })}
          />
        )}

        {!isFetchingFavoriteGames && isFavorite && (
          <Star
            sx={(theme) => ({
              color: theme.colorSchemes.dark.palette.common[1075],
              fontSize: '1.25rem',
              [theme.breakpoints.down(768)]: {
                fontSize: '1rem',
              },
            })}
          />
        )}

        {!isFetchingFavoriteGames && !isFavorite && (
          <StarBorderOutlined
            sx={(theme) => ({
              color: theme.colorSchemes.dark.palette.common.white,
              fontSize: '1.25rem',
              [theme.breakpoints.down(768)]: {
                fontSize: '1rem',
              },
            })}
          />
        )}
      </StyledIconButton>
    )
  );
};
