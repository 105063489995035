import { FC } from 'react'
import { IconButton, Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {
  selectFullProfile,
  useLazyGetPlayerInfoQuery,
  useLazyResendVerificationCodeQuery,
  useLazySignUpConfirmationQuery,
  useSessionSelector
} from '@entities/session';
import { otpSchema } from '@shared/lib';
import { useToaster, OtpInput, CloseIcon, Button, Link } from '@shared/ui';
import { OmegaApiResponseStatusEnum, omegaErrorsMapper } from '@shared/api';

type ConfirmPhoneChangeModalProps = {
  open: boolean;
  onClose: () => void;
}

export const ConfirmPhoneChangeModal: FC<ConfirmPhoneChangeModalProps> = ({ open, onClose }) => {
  const toaster = useToaster()
  const profile = useSessionSelector(selectFullProfile)

  const [signUpConfirmation, { isFetching: isFetchingConfirm }] = useLazySignUpConfirmationQuery();
  const [resendVerificationCode, { isFetching: isFetchingOtp }] = useLazyResendVerificationCodeQuery();
  const [getPlayerInfo, { isFetching: isFetchingGetProfile }] = useLazyGetPlayerInfoQuery();

  const { control, handleSubmit, formState, reset, setError, setFocus } = useForm({
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { otp: '' },
  })

  const handleSendConfirmationCode = async (formData: { otp: string }): Promise<void> => {
    if (!profile?.phone) {
      return;
    }

    const { data } = await signUpConfirmation({
      confirmationCode: formData.otp,
      mobile: profile.phone,
    });

    if (data?.status === OmegaApiResponseStatusEnum.Fail) {
      const { field, error } = data.errors?.[0] as any;
      const fieldName = field === 'confirmationCode'
        ? 'otp'
        : 'root.serverError';
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
      return;
    }

    const { data: profileData } = await getPlayerInfo();
    if (profileData?.status !== OmegaApiResponseStatusEnum.Success) {
      toaster.error({ message: 'Something went wrong' })
    }

    onClose();
  }

  const handleSendNewOtpCode = async (): Promise<void> => {
    if (!profile?.userId) {
      return
    }

    reset({})
    const { data } = await resendVerificationCode({ verificationTarget: 'email', username: profile?.userId })
    if (data?.errors?.length) {
      toaster.error({ message: data.errors[0].error })
      return;
    } else if (data?.status !== OmegaApiResponseStatusEnum.Success) {
      toaster.error({ message: 'Something went wrong' })
      return;
    }
    toaster.success({ message: 'New code sent successfully' })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <ModalDialog
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: '358px',
          overflow: 'hidden',
          [breakpoints.down(490)]: {
            maxWidth: 'calc(100vw - 2rem)'
          },
        })}
      >
        <Stack sx={{
          padding: '1.5rem',
        }}>
          <Stack mb="1rem">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb="0.75rem">
              <Stack direction="row"
                gap="0.5rem"
                alignItems="center">

                <Typography sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                  lineHeight: 'normal',
                })}>
                  Confirm phone number
                </Typography>
              </Stack>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={({ palette }) => ({
              color: palette.common[150],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
            })}>
              Please enter the code received in order to confirm your phone number.
            </Typography>
          </Stack>
          <Stack component="form" gap="1rem">
            <Controller
              control={control}
              disabled={isFetchingConfirm || isFetchingOtp || isFetchingGetProfile}
              name="otp"
              render={({ field: { value, onBlur, onChange }, fieldState }) => (
                <OtpInput
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  error={fieldState.error?.message} />
              )}
            />
            <Stack alignItems="center" gap="0.625rem">
              <Typography sx={({ palette }) => ({ color: palette.common.white })}>
                Didn't receive a code?
              </Typography>
              <Link
                disabled={isFetchingConfirm || isFetchingOtp || isFetchingGetProfile}
                onClick={handleSendNewOtpCode}>Send new OTP</Link>
            </Stack>
            <Button
              fullWidth
              loading={isFetchingConfirm || isFetchingGetProfile}
              type='submit'
              disabled={!formState.isDirty || !formState.isValid || isFetchingGetProfile || isFetchingConfirm || isFetchingOtp}
              onClick={handleSubmit(handleSendConfirmationCode)}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  )
}