import { BaseSyntheticEvent, FC, ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/joy';
import { TextInput, PhoneInput, Button } from '@shared/ui';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { isDefined, useModalQueryParam } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import { useLazySignInQuery } from '@entities/session';
import { signInByPhoneSchema } from '../lib';

type SignInByPhoneFormProps = {
  onSignedIn(): void;
  onNotCompletedSignUpError(props: {
    currency: CurrencyCodesEnum.SCO;
    secondaryCurrencies: CurrencyCodesEnum.GCO;
    verificationTarget: 'phone';
    mobile: Phone;
    password: Password;
  }): void;
  resetPasswordSlot: ReactElement
};

export const SignInByPhoneForm: FC<SignInByPhoneFormProps> = ({ onSignedIn, onNotCompletedSignUpError, resetPasswordSlot }) => {
  const { closeModal } = useModalQueryParam();
  const [signIn, { isFetching, data }] = useLazySignInQuery();

  const [hasLostFocus, setHasLostFocus] = useState<boolean>(false);
  const {
    control,
    formState,
    setValue,
    getValues,
    trigger,
    handleSubmit
  } = useForm({
    resolver: yupResolver(signInByPhoneSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { mobile: '', password: '' },
  });

  const handleFormSubmit = async (
    formData: { mobile: Phone; password: Password; },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    const { data } = await signIn({ ...formData, username: formData.mobile });
    if (data?.status === OmegaApiResponseStatusEnum.FailQuickOpenStatus) {
      return onNotCompletedSignUpError({
        ...formData,
        currency: CurrencyCodesEnum.SCO,
        secondaryCurrencies: CurrencyCodesEnum.GCO,
        verificationTarget: 'phone',
      });
    }

    if (data?.status === OmegaApiResponseStatusEnum.Success) {
      onSignedIn();
      closeModal('login');
    }
  };

  const handlePhoneValueChange = async (phone: Phone): Promise<void> => {
    setValue('mobile', phone);
    if (hasLostFocus) await trigger('mobile');
  };

  const handlePhoneBlur = async (): Promise<void> => {
    setHasLostFocus(true);
    await trigger('mobile');
  };

  return (
    <Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
      {(isDefined(data?.message)) && (
        <Typography
          sx={({ palette }) => ({
            color: palette.common.error,
            fontWeight: 500,
            marginBottom: 2,
          })}>
          {data?.message}
        </Typography>
      )}
      <Stack
        direction='column'
        rowGap={0.5}>
        <PhoneInput
          name='mobile'
          label='Phone Number'
          defaultCountry='us'
          disabled={isFetching}
          value={getValues('mobile')}
          onBlur={handlePhoneBlur}
          onChange={handlePhoneValueChange}
          error={formState.errors?.mobile?.message}
        />
        <Controller
          name='password'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              type='password'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack
        direction='column'
        alignItems='flex-end'
        justifyContent='center'
        gap={1}
        mb={2}>
        {resetPasswordSlot}
      </Stack>
      <Button
        disabled={!formState.isValid || isFetching}
        loading={isFetching}
        fullWidth
        type='submit'>
        Log In
      </Button>
    </Box>
  );
};
