import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Skeleton, Stack, Typography } from '@mui/joy';
import { 
  selectTournaments,
  useTournamentsSelector,
  selectIsLoading as selectIsTournamentsLoading,
  Tournament,
  TournamentsListItem,
  fetchTournaments,
} from '@entities/tournaments';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import {
  selectIsLoading as selectIsPageLoading,
  selectMetaTagCanonical,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagTitle,
  selectHeaderText,
  useTournamentsPageSelector,
} from '../model';
import { useGetTournamentsPageQuery } from '../api';

export const Tournaments: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const isPageLoading = useTournamentsPageSelector(selectIsPageLoading);
  const keywords = useTournamentsPageSelector(selectMetaTagKeywords);
  const title = useTournamentsPageSelector(selectMetaTagTitle);
  const description = useTournamentsPageSelector(selectMetaTagDescription);
  const canonical = useTournamentsPageSelector(selectMetaTagCanonical);
  const headerText = useTournamentsPageSelector(selectHeaderText);

  const isTournamentsLoading = useTournamentsSelector(selectIsTournamentsLoading);
  const tournaments = useTournamentsSelector(selectTournaments);
  
  useGetTournamentsPageQuery();

  useEffect(() => {
    dispatch(fetchTournaments(isAuthenticated))
  }, [dispatch, isAuthenticated]); 

  return !isPageLoading && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          paddingBlock: '16px 32px',
          paddingInline: 2,
          maxWidth: 1312,
          width: '100%',
          height: '100%',
          [breakpoints.down(491)]: {
            paddingBlock: '8px 24px',
          },
        })}>
        <Typography
          sx={({palette, breakpoints}) => ({
            marginBlock: 4,
            fontSize: 28,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '36px',
            color: palette.common.white,
            [breakpoints.down(491)]: {
              marginBlock: 2,
              fontSize: 20,
              lineHeight: '30px',
            },
          })}>
          {headerText ?? 'Tournaments'}
        </Typography>

        <Stack
          sx={({ breakpoints }) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 3,
            [breakpoints.down(912)]: {
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
            },
            [breakpoints.down(491)]: {
              gridTemplateColumns: '1fr',
              gap: 2,
            },
          })}>
          {!isTournamentsLoading && tournaments.map((tournament: Tournament, index: number) => (
            <TournamentsListItem
              key={index}
              tournament={tournament}
              sx={{
                margin: 'auto',
                height: '100%',
                justifyContent: 'space-between',
              }}/>
          ))}

          {isTournamentsLoading && Array(6).fill(null).map((_, index: number) => (
            <Skeleton
              key={index}
              variant='rectangular'
              sx={{
                maxWidth: 410,
                width: '100%',
                height: 510,
                margin: 'auto',
                borderRadius: 8,
              }}/>
          ))}
        </Stack>
      </Stack>
    </>
  );
};
