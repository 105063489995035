import { FC } from 'react';
import { Stack, Typography, Box } from '@mui/joy';
import { BoxedContactBlockItem } from '@pages/dynamic/model';
import { TextWithInlineLink } from '@shared/ui';

export type ContactUsBlockProps = {
  title?: Maybe<string>;
  items?: Maybe<Array<BoxedContactBlockItem>>;
};

export const ContactUsBlock: FC<ContactUsBlockProps> = ({ title, items }) => {

  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='center'
      sx={{
        gap: 2,
        width: '100%',
      }}>
      {title && (
        <Typography
          sx={({ colorSchemes }) => ({
            width: '100%',
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '36x',
            letterSpacing: 0,
            textAlign: 'start',
            color: colorSchemes.dark.palette.common.white,
          })}>
          {title}
        </Typography>
      )}
      <Box
        sx={({ breakpoints }) => ({
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 2.5,
          [breakpoints.down(769)]: {
            gridTemplateColumns: '1fr',
            gap: 1,
          },
        })}>
        {items?.map(({ iconSrc, shortSummary, details }, index) => (
          <Stack
            key={index}
            direction='row'
            alignItems='flex-start'
            justifyContent='center'
            position='relative'
            sx={({ colorSchemes }) => ({
              borderRadius: 8,
              background: colorSchemes.dark.palette.common[475],
            })}>
            {iconSrc && (
              <Stack 
                  alignItems='center'
                  justifyContent='center'
                  borderRadius={50}
                  position='absolute'
                  sx={({ palette, breakpoints }) => ({
                    width: 50,
                    height: 50,
                    top: 16,
                    left: 16,
                    background: palette.common[900],
                    [breakpoints.down(490)]: {
                      width: 26,
                      height: 26,
                      top: 8,
                      left: 8,
                    },
                  })}>

                <Box
                  component='img'
                  src={iconSrc}
                  sx={({ breakpoints }) => ({
                    width: 26,
                    height: 26,
                    [breakpoints.down(490)]: {
                      width: 12,
                      height: 12,
                    },
                  })}
                />
              </Stack>
            )}
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap={1.5}
              sx={({ breakpoints }) => ({
                paddingInline: 3,
                paddingBlock: 3,
                [breakpoints.down(490)]: {
                  paddingInline: 1.5,
                  paddingBlock: 1.5,
                },
              })}>
              <Typography
                sx={({ breakpoints }) => ({
                  fontSize: 28,
                  fontWeight: 600,
                  lineHeight: '36px',
                  letterSpacing: 0,
                  textAlign: 'center',
                  [breakpoints.down(490)]: {
                    fontSize: 20,
                    lineHeight: '30px',
                  },
                })}>
                {shortSummary}
              </Typography>
              {details && (
                <TextWithInlineLink
                  data={details}
                  typographyProps={{
                    sx: ({ breakpoints }) => ({
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: 0,
                      textAlign: 'center',
                      [breakpoints.down(769)]: {
                        fontSize: 16,
                      },
                    }),
                  }}
                />
              )}
            </Stack>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
