import { FC, useState } from 'react';
import { Box, Skeleton } from '@mui/joy';

export type SlideImageProps = {
  src: Url;
};

export const SlideImage: FC<SlideImageProps> = ({ src }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const handleLoad = () => {
    setImageLoading(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>
      <Skeleton loading={imageLoading} />
      <Box
        onLoad={handleLoad}
        component='img'
        src={src}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};
