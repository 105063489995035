import { FC, useMemo } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/joy';
import { type Package } from '../model';
import { extractColorAndPackageTitle } from '../lib';
import { PackageCoins } from './package-coins.component';

type LabelColorMatrix = {
  default: string;
  '{{\'red\'}}:': string;
  '{{\'yellow\'}}:': string;
  [key: string]: string | undefined;
};

export type PackageCardProps = Package & {
  onBuyPackage?: (selectedPackage: Partial<Package>) => void;
};

export const PackageCard: FC<PackageCardProps> = ({ onBuyPackage, ...props }) => {
  const {
    description,
    nonRedeemableCoinAmount,
    redeemableCoinAmount,
    purchasePrice,
    imageUrl
  } = props;
  const theme = useTheme();

  const labelColorMatrix: LabelColorMatrix = useMemo(() => ({
    default: theme.palette.common[700],
    '{{\'red\'}}:': theme.palette.common.error,
    '{{\'yellow\'}}:': theme.palette.common[1060]
  }),
    [theme]
  );

  const { hasValue, firstPart, secondPart } = useMemo(
    () => extractColorAndPackageTitle(description),
    [description]
  );

  const handleBuyPackage = (): void => {
    onBuyPackage?.(props);
  };

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      sx={({ palette }) => ({
        borderRadius: '8px',
        border: 'none',
        height: '100%',
        width: '100%',
        padding: 0,
        backgroundColor: palette.common[900],
      })}>
      <Box
        sx={{
          display: 'inline-flex',
          paddingBlockEnd: 1,
        }}>
        {hasValue && (
          <Typography
            sx={({ palette }) => ({
              color: palette.common.white,
              paddingX: 2.5,
              paddingY: 0.5,
              fontSize: 11,
              fontWeight: 500,
              borderTopLeftRadius: 8,
              borderBottomRightRadius: 8,
              backgroundColor: labelColorMatrix[firstPart!] || labelColorMatrix.default
            })}>
            {secondPart}
          </Typography>
        )}
      </Box>
      <Stack
        sx={{
          paddingInline: 2,
          paddingBottom: 2,
          paddingTop: description ? 0 : 3
        }}>
        <Stack
          direction='column'
          spacing={1}
          sx={{
            width: '100%',
            marginBottom: 1.5,
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}>
            <PackageCoins
              coinVariant='gold'
              amount={nonRedeemableCoinAmount}
            />
            <Avatar
              src='/assets/png/gold.png'
              sx={{
                background: 'none',
                width: 24,
                height: 14.25,
                borderRadius: 0,
              }}
            />
          </Stack>
          <PackageCoins
            coinVariant='silver'
            amount={redeemableCoinAmount}
          />
        </Stack>
        <Divider
          orientation='horizontal'
          inset='none'
          sx={{ marginBottom: 1.5 }}
        />
        <Button
          color='primary'
          size='md'
          onClick={handleBuyPackage}>
          <Typography
            sx={({ palette }) => ({
              fontSize: 14,
              fontWeight: 500,
              color: palette.common.white,
            })}>
            $ {purchasePrice}
          </Typography>
        </Button>
        <Stack
          direction='column'
          sx={{ height: 28 }}>
          {imageUrl && (
            <Link
              href={imageUrl}
              fontSize={12}
              underline='always'
              justifyContent='center'
              color='neutral'
              marginTop={0.5}>
              Promo T&Cs Apply
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
