import { FC } from 'react';
import { Layout } from '@shared/ui';
import { useGetPageLayoutQuery } from '@entities/page-layout';
import { LayoutHeader } from '@widgets/layout-header';
import { SidebarMenu, SidebarMenuButton } from '@widgets/sidebar-menu';
import { ToggleCoinsWithoutBalance } from '@features/toggle-coins';
import { withMessagesModal } from './with-messages-modal';

export const GameLayout: FC = withMessagesModal(() => {
  const { isFetching } = useGetPageLayoutQuery();
  return !isFetching && (
    <Layout
      disableScroll
      headerSlot={
        <LayoutHeader
          sidebarMenuSlot={<SidebarMenu />}
          sidebarMenuButtonSlot={<SidebarMenuButton />}
          gameToggleCoinsSlot={<ToggleCoinsWithoutBalance />}
        />
      }
    />
  );
});
