import { baseOmegaApi, SESSION_TAG } from '@shared/api';
import {
  type GetTransactions,
  type GetTransactionsResponse
} from './types';

export const transactionsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<GetTransactionsResponse, GetTransactions>({
      query: (arg: GetTransactions) => ({ url: 'ips/getTransactionHistoryByCurrency', params: arg }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true }
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery
} = transactionsApiSlicer;
