import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsAuthenticated } from '@entities/session';

export const AuthenticatedOnlyRoute: FC<PropsWithChildren> = ({ children }) => {
  const isAuth = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      const searchParams = new URLSearchParams({ login: 'true' });
      navigate(`/?${searchParams.toString()}`);
    }
  }, [navigate, isAuth]);

  return children;
};
