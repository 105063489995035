import { FC, ReactNode } from 'react';
import { Divider, Stack } from '@mui/joy';
import { AppLogo, IconButton } from '@shared/ui';
import { useMedia } from '@shared/lib';
import {
  UserAvatarWithNickname,
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  useLayoutSelector,
  selectPageHeaderVariant1LogoImgSrc,
  selectPageHeaderVariant2LogoImgSrc,
} from '@entities/page-layout';
import { SignInButton } from '@features/sign-in';
import { SignUpButton } from '@features/sign-up';
import { BuyCoinsButton } from '@features/buy-coins';
import {
  Chat as ChatIcon,
} from '@mui/icons-material';
import { ViewNotificationButton } from '@features/view-notification';

export type LayoutHeaderProps = {
  sidebarMenuSlot?: ReactNode;
  sidebarMenuButtonSlot?: ReactNode;
  gameToggleCoinsSlot?: ReactNode;
  toggleCoinsSlot?: ReactNode;
};

export const LayoutHeader: FC<LayoutHeaderProps> = ({
  sidebarMenuSlot,
  sidebarMenuButtonSlot,
  gameToggleCoinsSlot,
  toggleCoinsSlot,
}) => {
  const isMobile = useMedia(`(max-width: ${912}px)`);
  const isTablet = useMedia(`(max-width: ${1024}px)`);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const variant1LogoImgSrc = useLayoutSelector(selectPageHeaderVariant1LogoImgSrc);
  const variant2LogoImgSrc = useLayoutSelector(selectPageHeaderVariant2LogoImgSrc);
  return (
    <Stack
      component='header'
      justifyContent='center'
      alignItems='center'
      sx={({ palette }) => ({
        zIndex: 5,
        width: '100%',
        paddingInline: isMobile ? 2 : 10,
        backgroundColor: palette.common[475],
      })}>
      {sidebarMenuSlot}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          maxWidth: 1280,
          height: 68,
          gap: 0.5
        }}>
        <Stack
          direction='row'
          alignItems='center'
          gap={1.25}>
          {isMobile ? (
            variant1LogoImgSrc && (
              <AppLogo
                src={variant1LogoImgSrc}
                sx={{
                  width: 60,
                  height: 30,
                }}
              />
            )) : (
            <>
              {sidebarMenuButtonSlot}
              {variant2LogoImgSrc && <AppLogo src={variant2LogoImgSrc} />}
            </>
          )}
        </Stack>
        {gameToggleCoinsSlot}
        {isAuthenticated ? (
          <Stack
            direction='row'
            alignItems='center'
            gap={2}>
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}>
              {toggleCoinsSlot}
              <BuyCoinsButton>
                Buy
              </BuyCoinsButton>
            </Stack>
            {!isTablet && (
              <>
                <Divider orientation='vertical' sx={{ marginBlock: 1 }} />
                <UserAvatarWithNickname />
                <Divider orientation='vertical' sx={{ marginBlock: 1 }} />
                <ViewNotificationButton />
                <Divider orientation='vertical' sx={{ marginBlock: 1 }} />
                <IconButton
                  size='sm'
                  sx={{
                    height: 'min-content',
                  }}>
                  <ChatIcon
                    sx={({ palette }) => ({
                      fontSize: 20,
                      color: palette.common.white,
                    })}
                  />
                </IconButton>
              </>
            )}
          </Stack>
        ) : (
          <Stack
            direction='row'
            alignItems='center'
            gap={1.5}>
            <Stack
              direction='row'
              alignItems='center'
              gap={1}>
              <SignInButton>
                Log in
              </SignInButton>
              <SignUpButton>
                Sign up
              </SignUpButton>
            </Stack>
            <Divider orientation='vertical' sx={{ marginBlock: 1 }} />
            <IconButton
              size='sm'
              sx={{
                height: 'min-content'
              }}>
              <ChatIcon
                sx={({ palette }) => ({
                  fontSize: 16,
                  color: palette.common.white,
                })}
              />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
