export enum TournamentStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
}

export type Tournament = {
  name: string;
  description: string;
  imageSrc: string;
  prizeGC: number;
  prizeSC: number;
  startDate: string;
  endDate: string;
  status: TournamentStatus;
  buttonText: string;
  buttonUrl: string;
};

export type TournsmentsState = {
  isLoading: boolean;
  publicTournaments: Array<Tournament>;
};
