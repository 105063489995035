import { FC, useEffect, useId, useRef, useState } from 'react';
import ReactOtpInput, { OTPInputProps } from 'react-otp-input';
import { FormHelperText, Stack, styled } from '@mui/joy';

export type OtpInputProps = Omit<OTPInputProps, 'error' | 'renderInput'> & {
  error?: string;
  disabled?: boolean;
  onBlur?: () => void;
  numInputs?: number;
};

export const OtpInput: FC<OtpInputProps> = ({
  error,
  disabled,
  value,
  onChange,
  onBlur,
  numInputs = 5,
  ...props
}) => {
  const otpInputId = useId();
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasFocused, setHasFocused] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`${otpInputId}:0`)?.focus();
    }, 300);
  }, [otpInputId]);

  useEffect(() => {
    const handleFocusIn = (e: FocusEvent) => {
      if (containerRef.current && containerRef.current.contains(e.target as Node)) {
        setHasFocused(true);
      }
    };

    const checkIfClickedOutside = (e: FocusEvent) => {
      if (hasFocused && !containerRef?.current?.contains(e.relatedTarget as Node)) {
        onBlur?.();
        setHasFocused(false);
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', checkIfClickedOutside);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', checkIfClickedOutside);
    };
  }, [hasFocused, onBlur]);

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      gap={1}
      ref={containerRef}>
      <Stack
        direction='row'
        sx={{
          '& > div': {
            flexWrap: 'wrap',
            justifyContent: 'center'
          }
        }}>
        <ReactOtpInput
          {...props}
          numInputs={numInputs}
          value={value}
          onChange={onChange}
          renderInput={({ ref, ...inputProps }, index) => {
            return (
              <StyledOtpFormInput
                {...inputProps}
                id={`${otpInputId}:${index}`}
                ref={ref}
                placeholder='#'
                key={index}
                className={Boolean(error) ? 'error' : ''}
              />
            )
          }}
          containerStyle={{ gap: 14 }}
          inputStyle={{
            height: 40,
            width: 40,
            borderRadius: 8
          }}
        />
      </Stack>
      {error && (
        <FormHelperText
          sx={({ palette }) => ({
            borderColor: `${palette.common.error} !important`,
            color: palette.common.error,
          })}>
          {error}
        </FormHelperText>
      )}
    </Stack>
  );
};

export const StyledOtpFormInput = styled('input')(({ theme }) => ({
  padding: '0.4375rem 0.75rem',
  borderRadius: '0.5rem',
  border: '0.0625rem',
  lineHeight: '1.5rem',
  boxShadow: 'none',
  borderStyle: 'solid',
  borderColor: theme.colorSchemes.dark.palette.common[925],
  color: theme.colorSchemes.dark.palette.common.white,
  backgroundColor: theme.colorSchemes.dark.palette.common[475],
  '&:hover': {
    borderColor: theme.colorSchemes.dark.palette.common[925],
    boxShadow: `0px 0px 4px 0px ${theme.colorSchemes.dark.palette.common[225]}`,
  },
  '&:focus': {
    borderColor: theme.colorSchemes.dark.palette.common[150],
    boxShadow: 'none',
  },
  '::placeholder': {
    color: theme.colorSchemes.dark.palette.common[150],
  },
  '&:disabled': {
    borderColor: theme.colorSchemes.dark.palette.common[925],
    backgroundColor: theme.colorSchemes.dark.palette.common[505],
    color: theme.colorSchemes.dark.palette.common[515],
  },
  '&.error': {
    borderColor: `${theme.colorSchemes.dark.palette.common.error} !important`,
    color: theme.colorSchemes.dark.palette.common.error,
    boxShadow: 'none',
    '&:hover': {
      borderWidth: 2,
    },
    '&:focus': {
      borderWidth: 2,
      borderColor: `${theme.colorSchemes.dark.palette.common.error} !important`,
    },
  },
}));
