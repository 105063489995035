import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { SessionState, sessionSlice } from '@entities/session';

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectRemainingPointsForNextLevel = (state: RootState): Maybe<number> => (
  state[sessionSlice.name]?.loyaltyStatus?.remainingPointsForNextLevel
);

export const selectTotalPointsForNextLevel = (state: RootState): Maybe<number> => (
  state[sessionSlice.name]?.loyaltyStatus?.totalPointsForNextLevel
);

export const useLoyaltyProgressBarSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;
