import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { type GameCategory, type GamesItem, type GamesState, type Pagination, gamesSlice } from '@entities/games';
import { gamesFilteredByCategoriesSlice } from './slice';
import { type GamesFilterByCategoryState } from './types';

type RootState = {
  [gamesFilteredByCategoriesSlice.name]: GamesFilterByCategoryState;
  [gamesSlice.name]: GamesState;
};

export const selectPaginationByCategoryId =
  (categoryId: Maybe<number>) =>
  (state: RootState): Maybe<Pagination> =>
    categoryId ? state[gamesFilteredByCategoriesSlice.name].paginationsByCategories[categoryId] : null;

export const innerSelectGamesCategoryByCategoryId =
  (categoryId: Maybe<number>) =>
  (state: RootState): Maybe<GameCategory> => {
    const selectedCategory: Maybe<GameCategory> = state[gamesSlice.name].categories.find(
      (category: GameCategory) => category.id === categoryId
    );

    return selectedCategory;
  };

export const selectGamesByCategoryId = (categoryId: Maybe<number>) =>
  createSelector(
    [innerSelectGamesCategoryByCategoryId(categoryId), selectPaginationByCategoryId(categoryId)],
    (gameCategory: Maybe<GameCategory>, pagination: Maybe<Pagination>): Maybe<Array<GamesItem>> => {
      if (!gameCategory || !pagination) {
        return null;
      }

      return gameCategory.games.slice(0, pagination.page * pagination.pageSize);
    }
  );

export const selectIsLoading = (state: RootState): boolean => state[gamesFilteredByCategoriesSlice.name].isLoading;

export const selectSelectedCategoryId = (state: RootState): Maybe<number> =>
  state[gamesFilteredByCategoriesSlice.name].selectedCategoryId;

export const useGamesFilterByCategorySelector: TypedUseSelectorHook<{
  [gamesFilteredByCategoriesSlice.name]: GamesFilterByCategoryState;
  [gamesSlice.name]: GamesState;
}> = useSelector;
