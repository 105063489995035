import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalDialog, Stack, Typography, styled } from '@mui/joy';
import { useMedia, useResizeObserver } from '@shared/lib';
import { CloseIcon, IconButton } from '@shared/ui';
import {
  selectShortProfile,
  selectSessionKey,
  useSessionSelector
} from '@entities/session';
import {
  closeCheckoutModal,
  selectOpenCheckoutModal,
  selectCheckoutModalProps,
  useCoinPackagesSelector,
} from '@entities/coin-packages';
import { mapCheckoutUrl } from '../lib';

export const CheckoutIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export const CheckoutModal: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useMedia(`(max-width: ${490}px)`);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const iframeContainer = useResizeObserver();

  const shortProfile = useSessionSelector(selectShortProfile);
  const sessionKey = useSessionSelector(selectSessionKey);
  const open = useCoinPackagesSelector(selectOpenCheckoutModal);
  const props = useCoinPackagesSelector(selectCheckoutModalProps);

  const checkoutIframeSrc = useMemo(() => {
    const iframeSrc = mapCheckoutUrl({
      environment: `${process.env.REACT_APP_PAYMENT_ENVIRONMENT}`,
      merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
      userId: `${shortProfile?.partyId}`,
      packageId: `${props?.packageId}`,
      sessionId: `${sessionKey}`,
      method: `${process.env.REACT_APP_PAYMENT_METHOD}`,
      containerHeight: `${iframeContainer.height}px`,
      containerWidth: `${iframeContainer.width}px`,
      amount: `${props?.purchasePrice}`,
    });
    return iframeSrc;
  }, [props, sessionKey, shortProfile, iframeContainer]);

  const handleClose = (): void => {
    dispatch(closeCheckoutModal());
  };

  const handleIframeLoaded = (): void => {
    setIsLoaded(true);
  };

  return (
    <Modal open={open ?? false}>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 878,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}
        {...props}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            ref={iframeContainer.ref}
            direction='column'
            width='100%'
            height='100%'>
            {isLoaded ? (
              <CheckoutIframe
                key={checkoutIframeSrc}
                src={checkoutIframeSrc}
                onLoad={handleIframeLoaded}
              />
            ) : (
              <>Loading...</>
            )}
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};