import { FC } from 'react';
import { GameItemRibbonEnum } from '../model';
import { Box } from '@mui/joy';

type GameRibbonProps = {
  ribbon: GameItemRibbonEnum;
};

export const GameRibbon: FC<GameRibbonProps> = ({ ribbon }) => {
  const newImage = '/assets/png/new_ribbon.png';
  const luxeImage = '/assets/png/luxe_ribbon.png';
  return (
    <Box
      component='img'
      width={35}
      height={35}
      src={ribbon === GameItemRibbonEnum.New ? newImage : luxeImage}
      alt={'ribbon'}
      sx={{
        position: 'absolute',
        top: 0,
        left: 12,
      }}
    />
  );
};
