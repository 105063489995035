import { styled } from '@mui/joy';
import { Swiper } from 'swiper/react';

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down(912)]: {
    '.swiper-slide': {
      width: 'auto',
    },
  },
}));
