import { ChangeEvent, FC } from 'react';
import { Switch, Typography } from '@mui/joy';
import { useToaster } from '@shared/ui';
import {
  useGetEmailSubscriptionStateQuery,
  useLazyUpdateEmailSubscriptionQuery,
} from '../api';
import {
  selectEmailPromotionSubscription,
  useEmailPromotionSubscriptionSelector
} from '../model';

export const MailSubscriptionSwitcher: FC = () => {
  const toaster = useToaster();
  const { isFetching: isFetchingState } = useGetEmailSubscriptionStateQuery();
  const [updateSubscription, { isFetching: isUpdatingSubscription }] = useLazyUpdateEmailSubscriptionQuery();
  const emailPromotionSubscription = useEmailPromotionSubscriptionSelector(selectEmailPromotionSubscription);

  const handleUpdateSubscription = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    const { isSuccess } = await updateSubscription({ subscribe: value });
    if (isSuccess) {
      toaster.success({ message: 'Mail subscription was updated' });
    }
  };

  return (
    <Switch
      onChange={handleUpdateSubscription}
      disabled={isFetchingState || isUpdatingSubscription}
      checked={emailPromotionSubscription}
      slotProps={{
        root: {
          sx: {
            alignItems: 'flex-start',
            alignSelf: 'flex-start'
          }
        }
      }}
      endDecorator={(
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common[150]
          })}>
          Receive promotional mails
        </Typography>
      )}
    />
  );
};