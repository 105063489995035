import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { baseApi, baseOmegaApi } from '@shared/api';
import { circularLoaderSlice } from '@shared/ui/circular-loader';
import { sessionSlice } from '@entities/session';
import { messagesSlice } from '@entities/messages';
import { pageLayoutSlice } from '@entities/page-layout';
import { gamesSlice } from '@entities/games';
import { coinPackagesSlice } from '@entities/coin-packages';
import { tournamentsSlice } from '@entities/tournaments';
import { transactionsSlice } from '@entities/transactions';
import { signUpSlice } from '@features/sign-up';
import {
  managePromotionalSubscriptionsSlice
} from '@features/manage-promotional-subscriptions';
import { documentsSlice } from '@features/upload-documents';
import { resetPasswordSlice } from '@features/reset-password';
import { sidebarMenuSlice } from '@widgets/sidebar-menu';
import { transactionListSlice } from '@widgets/transaction-list';
import { homePageSlice } from '@pages/home';
import { gamesPageSlice } from '@pages/games';
import { tournamentsPageSlice } from '@pages/tournaments';
import { dynamicPageSlice } from '@pages/dynamic';
import { promotionsPageSlice } from '@pages/promotions';
import { accountPageSlice } from '@pages/account';
import { notLoggedInErrorHandler } from '@app/middlewares';
import { rootReducer } from './root-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    circularLoaderSlice.name,
    pageLayoutSlice.name,
    sidebarMenuSlice.name,
    homePageSlice.name,
    sessionSlice.name,
    signUpSlice.name,
    gamesSlice.name,
    tournamentsSlice.name,
    gamesPageSlice.name,
    tournamentsPageSlice.name,
    dynamicPageSlice.name,
    promotionsPageSlice.name,
    messagesSlice.name,
    accountPageSlice.name,
    managePromotionalSubscriptionsSlice.name,
    documentsSlice.name,
    resetPasswordSlice.name,
    coinPackagesSlice.name,
    transactionsSlice.name,
    transactionListSlice.name
  ],
};

const reducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: [/omegaApi.queries.uploadUserDocument/],
        },
      })
        .concat(baseApi.middleware)
        .concat(baseOmegaApi.middleware)
        .concat(notLoggedInErrorHandler)
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

export const persistor = persistStore(store);

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
