import { FC, RefObject, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { Stack } from '@mui/joy';
import { SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import { useMedia } from '@shared/lib';
import { type GameCategory, GameListItem, type GamesItem, useGetGamesCategoriesQuery } from '@entities/games';
import {
  selectGamesByCategoryId,
  useGamesFilterByCategorySelector,
  selectIsLoading,
  fetchGamesByCategory,
} from '@features/games-filter-by-category';
import { LaunchGame } from '@features/launch-game';
import { FavoriteGameSwitcher } from '@features/favorite-game-switcher';
import { StyledSwiper } from './styled';
import { LoadingList } from './loading-list.component';
import { GamesCategoryItemHeader } from './games-category-item-header.component';
import { GamesCategoryItemHeaderSkeleton } from './games-category-item-header-skeleton.component';

type GamesCategoryItemProps = {
  category: GameCategory;
};

export const GamesCategoryItem: FC<GamesCategoryItemProps> = ({ category }) => {
  const isMobileView = useMedia(`(max-width: ${912}px)`);
  const isSmallDesktopView = useMedia(`(max-width: ${1024}px) and (min-width: ${913}px)`);
  const isDesktopView = useMedia(`(min-width: ${1349}px)`);
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const games = useGamesFilterByCategorySelector(selectGamesByCategoryId(category?.id));
  const isGamesLoading = useGamesFilterByCategorySelector(selectIsLoading);
  const { isFetching: isGamesCategoriesLoading } = useGetGamesCategoriesQuery();

  const [showSlider, setShowSlider] = useState<boolean>(false);

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  const slidesPerView: number = useMemo(() => {
    return isDesktopView ? 6 : isSmallDesktopView ? 4 : 5;
  }, [isDesktopView, isSmallDesktopView]);

  const fetchGamesForCategories = useCallback(() => {
    if (category?.id) {
      dispatch(fetchGamesByCategory({ page: 1, pageSize: 12, categoryId: category?.id }));
    }
  }, [category, dispatch]);

  useEffect(() => {
    fetchGamesForCategories();
  }, [fetchGamesForCategories]);

  // TODO: refactor !!!!
  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    }, 0);
  }, []);

  return (
    games &&
    !!games.length && (
      <Stack direction='column' gap={isMobileView ? 1.25 : 2.5}>
        {!isGamesCategoriesLoading && (
          <GamesCategoryItemHeader
            category={category}
            navigationNextRef={navigationNextRef}
            navigationPrevRef={navigationPrevRef}
          />
        )}

        {isGamesCategoriesLoading && <GamesCategoryItemHeaderSkeleton />}

        <Stack direction='column'>
          {(isGamesCategoriesLoading || isGamesLoading) && <LoadingList length={isMobileView ? 6 : slidesPerView} />}

          {!isGamesCategoriesLoading && !isGamesLoading && showSlider && (
            <StyledSwiper
              slidesPerView={isMobileView ? 'auto' : slidesPerView}
              spaceBetween={isMobileView ? 8 : 16}
              modules={[Navigation]}
              navigation={{
                enabled: true,
                nextEl: navigationNextRef.current,
                prevEl: navigationPrevRef.current,
              }}>
              {games?.map((game: GamesItem) => (
                <SwiperSlide key={game.id}>
                  <GameListItem
                    key={game.id}
                    game={game}
                    favoriteSwitcherSlot={<FavoriteGameSwitcher game={game} />}
                    launchGameSlot={<LaunchGame id={game.id} />}
                    bonusFeature
                    volatility='Hight'
                    rtp='98%'
                    sx={({ breakpoints }) => ({
                      width: '160px',
                      height: '120px',
                      [breakpoints.up(911)]: {
                        width: '100%',
                      },
                    })} />
                </SwiperSlide>
              ))}
            </StyledSwiper>
          )}
        </Stack>
      </Stack>
    )
  );
};
