import { baseOmegaApi, OmegaApiResponse, SESSION_TAG } from '@shared/api';
import { type FullProfile } from '@entities/session';

export type EditFullProfile = {
  mobile?: Maybe<Phone>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  nickname: Maybe<string>;
  email?: Maybe<string>;
  birthDate: Maybe<string>;
  address: Maybe<string>;
  country: Maybe<string>;
  province: Maybe<string>;
  city: Maybe<string>;
  postalCode: Maybe<string>;
};

export const editFullProfileApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    quickSignupCompletion: build.query<OmegaApiResponse, EditFullProfile>({
      query: (arg: FullProfile) => ({ url: 'ips/quickSignupCompletion', params: arg, }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },

    }),
  }),
});

export const {
  useLazyQuickSignupCompletionQuery,
  useQuickSignupCompletionQuery,
} = editFullProfileApiSlicer;
