import { type ChannelMessage } from '../model';

type MapMessage = (data: any) => ChannelMessage;
export const mapMessage: MapMessage = (data: any) => {
  const dataObject = JSON.parse(data);
  const domParser = new DOMParser();
  const doc = domParser.parseFromString(dataObject.content, 'text/html');

  const title: Maybe<string> = doc.body.getElementsByTagName('h1')[0]?.textContent;
  let imgUrl: Maybe<string> = null;
  let text: Array<string> = [];
  let buttonText: Maybe<string> = '';
  let buttonLink: Maybe<string> = '';
  let isButtonGreen: Maybe<boolean> = null;

  let isButtonFound: boolean = false;
  const paragraphs = doc.body.getElementsByTagName('p');
  for (let i = 0; i < paragraphs.length; i++) {
    const p = paragraphs[i];

    if (isButtonFound) {
      isButtonGreen = p.innerText.toLocaleLowerCase().trim() === 'green';
      break;
    }

    if (p.firstElementChild) {
      const img = p.getElementsByTagName('img')[0];
      if (img) {
        imgUrl = img.src;
        text = [];
        continue;
      }

      const br = p.getElementsByTagName('br')[0];
      if (br) {
        text.push('');
        continue;
      }

      const button = p.getElementsByTagName('a')[0];
      if (button) {
        buttonText = button.innerText;
        buttonLink =
          button.host === window.location.host ? button.pathname : button.href;
        isButtonFound = true;
        continue;
      }
    } else {
      text.push(p.innerText);
    }
  }

  return {
    title,
    imgUrl,
    text,
    buttonText,
    buttonLink,
    isButtonGreen,
  };
};
