import { FC } from 'react';
import { Layout } from '@shared/ui';
import { useGetPageLayoutQuery } from '@entities/page-layout';
import { ToggleCoins } from '@features/toggle-coins';
import { LayoutHeader } from '@widgets/layout-header';
import { LayoutFooter } from '@widgets/layout-footer';
import {
  SidebarMenu,
  SidebarMenuButton,
  StickyNavigation
} from '@widgets/sidebar-menu';
import { withModals } from './with-modals';

export const BaseLayout: FC = withModals(() => {
  const { isFetching } = useGetPageLayoutQuery();
  return !isFetching && (
    <Layout
      headerSlot={
        <LayoutHeader
          toggleCoinsSlot={<ToggleCoins />}
          sidebarMenuSlot={<SidebarMenu />}
          sidebarMenuButtonSlot={<SidebarMenuButton />}
        />
      }
      footerSlot={<LayoutFooter />}
      stickyNavigationSlot={<StickyNavigation />}
    />
  );
});
