import { Stack } from '@mui/joy';
import { FacebookAuthButton, } from './facebook-auth-button.component';
import { GoogleAuthButton } from './google-auth-button.component';

export const SocialLogin = () => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={({ breakpoints }) => ({
        width: '100%',
        [breakpoints.down(490)]: {
          columnGap: 1.5
        },
        [breakpoints.down(389)]: {
          rowGap: 1,
          flexDirection: 'column',
        }
      })}>
      <GoogleAuthButton />
      <FacebookAuthButton />
    </Stack>
  );
};
