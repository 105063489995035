import { mapper } from '@shared/lib';
import { type GameItem } from '../model';

type MapGameItem = (data: any) => GameItem;
export const mapGameItem: MapGameItem = mapper([
  { name: 'id', from: 'data.id' },
  { name: 'name', from: 'data.attributes.name' },
  { name: 'launchId', from: 'data.attributes.launchId' },
  { name: 'segmentId', from: 'data.attributes.segmentId' },
  { name: 'segmentName', from: 'data.attributes.segmentName' },
  { name: 'productId', from: 'data.attributes.productId' },
  { name: 'productCode', from: 'data.attributes.productCode' },
  { name: 'supportsMobile', from: 'data.attributes.supportsMobile' },
  { name: 'supportsDesktop', from: 'data.attributes.supportsDesktop' },
  { name: 'omegaId', from: 'data.attributes.omegaId' },
  { name: 'gameId', from: 'data.attributes.gameId' },
]);
