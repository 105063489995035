import { PAYMENT_GATEWAY_URL } from '@shared/config';
import { toQueryString } from '@shared/lib';

type MapCheckoutUrl = {
  merchantId: string;
  packageId: string;
  sessionId: string;
  environment: string;
  method: string;
  userId: string;
  containerHeight: string;
  containerWidth: string;
  amount: string;
};

export const mapCheckoutUrl = ({ packageId, ...props }: MapCheckoutUrl): string => {
  const searchParams = toQueryString({
    globalSubmit: 'true',
    showTransactionOverview: 'true',
    showReceipt: 'true',
    mode: 'ecommerce',
    saveAccountOption: 'true',
    scrollToOffset: '8',
    showAccounts: 'inline',
    showFooter: 'false',
    showListHeaders: 'true',
    fetchConfig: 'true',
    'attributes.packageid': packageId,
    ...props
  });
  return `${PAYMENT_GATEWAY_URL}cashier/master/${searchParams}`;
};
