import { FC } from 'react';
import { Button } from '@mui/joy';
import { FacebookIcon } from '@shared/ui';

export const FacebookAuthButton: FC = () => (
  <Button
    startDecorator={<FacebookIcon />}
    variant='plain'
    size='sm'
    sx={({ breakpoints, palette }) => ({
      borderRadius: 8,
      paddingBlock: 1.25,
      fontSize: 16,
      fontWeight: 600,
      color: palette.common.white,
      backgroundColor: palette.common[275],
      ':hover': { backgroundColor: palette.common[325] },
      ':active': { backgroundColor: palette.common[375] },
      [breakpoints.down(490)]: {
        fontSize: 14,
        width: '100%'
      },
    })}>
    With Facebook
  </Button>
);
