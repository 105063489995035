import { Component, ComponentType } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

export const withStore = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return class StoreProvider extends Component<P> {
    render() {
      return (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <WrappedComponent {...this.props} />
          </PersistGate>
        </Provider>
      );
    }
  };
};
