import { FC, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/joy';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  IconButton,
  Button
} from '@shared/ui';
import { useMedia } from '@shared/lib';

type TournamentsListHeaderProps = {
  title: Maybe<string>;
  navigationNextRef: RefObject<HTMLButtonElement>;
  navigationPrevRef: RefObject<HTMLButtonElement>;
};

export const TournamentsListHeader: FC<TournamentsListHeaderProps> = ({
  title,
  navigationNextRef,
  navigationPrevRef
}) => {
  const isMobileView = useMedia(`(max-width: ${912}px)`);
  const navigate = useNavigate();

  const handleViewAllClick = (): void => {
    navigate('/races');
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'>
      <Stack
        direction='row'
        alignItems='center'
        gap={1.5}>
        <Typography
          sx={({ colorSchemes, breakpoints }) => ({
            fontSize: 28,
            fontWeight: 600,
            fontStyle: 'normal',
            lineHeight: '36px',
            color: colorSchemes.dark.palette.common.white,
            [breakpoints.down(912)]: {
              fontSize: 24,
              lineHeight: '30px',
            },
          })}>
          {title ?? 'Tournaments'}
        </Typography>

        {!isMobileView &&
          <Stack
            direction='row'
            alignItems='center'
            gap={2}>
            <IconButton
              ref={navigationPrevRef}
              sx={(theme) => ({
                background: 'transparent',
                color: theme.colorSchemes.dark.palette.common.white,
                minWidth: 20,
                minHeight: 20,
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowLeftIcon />
            </IconButton>

            <IconButton
              ref={navigationNextRef}
              sx={(theme) => ({
                background: 'transparent',
                color: theme.colorSchemes.dark.palette.common.white,
                minWidth: 20,
                minHeight: 20,
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        }
      </Stack>

      <Button
        type='button'
        variant='plain'
        sx={({ colorSchemes }) => ({
          color: colorSchemes.dark.palette.common[150],
          fontSize: 16,
          fontWeight: 500,
        })}
        onClick={handleViewAllClick}>
        View All
      </Button>
    </Stack>
  );
};
