import { FC, useMemo } from 'react';
import { Box, Stack } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { GameListItem, type GamesItem } from '@entities/games';
import {
  selectSelectedCategoryId,
  selectGamesByCategoryId,
  selectIsLoading as selectIsLoadingByCategory,
  useGamesFilterByCategorySelector,
} from '@features/games-filter-by-category';
import {
  selectGames as selectGamesBySearch,
  selectIsLoading as selectIsLoadingBySearch,
  useGamesFilterBySearchSelector,
} from '@features/games-filter-by-search';
import {
  useGamesFilterByFavoriteSelector,
  selectGames as selectGamesByFavorite,
  selectIsLoading as selectIsLoadingByFavorite,
} from '@features/games-filter-by-favorite';
import { FavoriteGameSwitcher } from '@features/favorite-game-switcher';
import { LaunchGame } from '@features/launch-game';
import { FilterTypeEnum, selectActiveFilterType, useGamesListWidgetSelector } from '../model';
import { LoadingList } from './loading-list.component';
import { NoSearchResultsFound } from './no-search-results-found.component';
import { GamesFilter } from './games-filter.component';
import { LoadMoreButton } from './load-more-button.component';

export const GameList: FC = () => {
  const isMobileView = useMedia(`(max-width: ${768}px)`);

  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);

  const selectedCategoryId = useGamesFilterByCategorySelector(selectSelectedCategoryId);
  const gamesByCategory = useGamesFilterByCategorySelector(selectGamesByCategoryId(selectedCategoryId));
  const gamesBySearch = useGamesFilterBySearchSelector(selectGamesBySearch);
  const gamesByFavorite = useGamesFilterByFavoriteSelector(selectGamesByFavorite);
  const isLoadingByCategory = useGamesFilterByCategorySelector(selectIsLoadingByCategory);
  const isLoadingBySearch = useGamesFilterBySearchSelector(selectIsLoadingBySearch);
  const isLoadingByFavorite = useGamesFilterByFavoriteSelector(selectIsLoadingByFavorite);

  const isLoading: boolean = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category:
        return isLoadingByCategory;
      case FilterTypeEnum.Search:
        return isLoadingBySearch;
      case FilterTypeEnum.Favorite:
        return isLoadingByFavorite;
    }
  }, [activeFilterType, isLoadingByCategory, isLoadingBySearch, isLoadingByFavorite]);

  const games: GamesItem[] = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category:
        return gamesByCategory ?? [];
      case FilterTypeEnum.Search:
        return gamesBySearch;
      case FilterTypeEnum.Favorite:
        return gamesByFavorite;
    }
  }, [activeFilterType, gamesByCategory, gamesBySearch, gamesByFavorite]);

  return (
    <Stack width='100%' gap={'2rem'} alignItems='center'>
      <GamesFilter />

      {!games.length && !isLoading && <NoSearchResultsFound />}

      <Box
        sx={(theme) => ({
          display: 'grid',
          width: '100%',
          gap: '1rem',
          gridTemplateColumns: 'repeat(auto-fit, 200px)',
          [theme.breakpoints.down(1024)]: {
            justifyContent: 'center',
          },
          [theme.breakpoints.down(768)]: {
            gap: '0.75rem',
            gridTemplateColumns: 'repeat(auto-fit, 173px)',
          },
          [theme.breakpoints.down(390)]: {
            gridTemplateColumns: 'repeat(auto-fit, 150px)',
          },
        })}
      >
        {games.map((game) => (
          <GameListItem
            key={game.id}
            game={game}
            favoriteSwitcherSlot={<FavoriteGameSwitcher game={game} />}
            launchGameSlot={<LaunchGame id={game.id} />}
            bonusFeature
            volatility='Hight'
            rtp='98%'
          />
        ))}

        {isLoading && <LoadingList length={isMobileView ? 6 : 12} />}
      </Box>

      {!!games.length && <LoadMoreButton isLoading={isLoading} />}
    </Stack>
  );
};
