import { BaseSyntheticEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/joy';
import {
  TextInput,
  Button,
  Checkbox,
  PhoneInput
} from '@shared/ui';
import { useModalQueryParam } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import {
  type OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper,
} from '@shared/api';
import { signUpByPhoneSchema } from '../lib';
import { useLazyQuickSignUpQuery } from '../api';
import {
  type SignUp,
  openConfirmationSignUpModal,
  setPropsToSignUp
} from '../model';
import { CheckboxFormLabel } from './checkbox-from-label.component';

export const SignUpByPhoneForm: FC = () => {
  const dispatch = useDispatch();
  const { closeModal } = useModalQueryParam();
  const [quickSignUp, { isFetching, data }] = useLazyQuickSignUpQuery();

  const [hasLostFocus, setHasLostFocus] = useState<boolean>(false);
  const {
    control,
    formState,
    setValue,
    trigger,
    setError,
    setFocus,
    handleSubmit
  } = useForm({
    resolver: yupResolver(signUpByPhoneSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      mobile: '',
      password: '',
      ageAndStateConfirmation: false,
      termsAndPrivacyConfirmation: false
    },
  });

  const handleSingUp = async (
    formData: { mobile: Phone; password: Password; },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const propsToSignUp: SignUp = {
      ...formData,
      currency: CurrencyCodesEnum.SCO,
      secondaryCurrencies: CurrencyCodesEnum.GCO,
      verificationTarget: 'mobile',
    };

    const { data } = await quickSignUp(propsToSignUp)
    const { status, errors } = data as OmegaApiResponse;

    if (status === OmegaApiResponseStatusEnum.ValidationFail) {
      const { field, error } = errors?.[0] as any;
      const fieldName: any = field === 'mobile' ? field : 'root.serverError';
      setError(
        fieldName,
        { type: error, message: omegaErrorsMapper['SIGN-UP'][error] });
      setFocus(fieldName);
    } else {
      dispatch(setPropsToSignUp(propsToSignUp));
      dispatch(openConfirmationSignUpModal());
      closeModal('sign-up');
    }
  };

  const handlePhoneValueChange = async (phone: Phone): Promise<void> => {
    setValue('mobile', phone);
    if (hasLostFocus) await trigger('mobile');
  };

  const handlePhoneBlur = async (): Promise<void> => {
    setHasLostFocus(true);
    await trigger('mobile');
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(handleSingUp)}>
      {formState?.errors?.root?.serverError && (
        <Typography
          sx={({ colorSchemes }) => ({
            color: colorSchemes.dark.palette.common.error,
            fontWeight: 500,
            marginBottom: 2,
          })}>
          {data?.message}
        </Typography>
      )}
      <Stack
        direction='column'
        rowGap={0.5}
        mb={1.5}>
        <PhoneInput
          name='mobile'
          label='Phone Number'
          defaultCountry='us'
          disabled={isFetching}
          onBlur={handlePhoneBlur}
          onChange={handlePhoneValueChange}
          error={formState.errors?.mobile?.message}
        />
        <Controller
          disabled={isFetching}
          name='password'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              type='password'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack
        direction='column'
        gap={1}
        mb={2}>
        <Controller
          disabled={isFetching}
          name='ageAndStateConfirmation'
          control={control}
          render={({ field: { value, onBlur, onChange } }) => (
            <Checkbox
              disabled={isFetching}
              checked={value}
              onBlur={onBlur}
              onChange={onChange}
              sx={{
                textAlign: 'justify',
                gap: 1,
                height: 36
              }}
              label={
                <CheckboxFormLabel>
                  I am at least 18 years old and I am not a resident of the Restricted areas
                </CheckboxFormLabel>
              }
            />
          )}
        />
        <Controller
          disabled={isFetching}
          name='termsAndPrivacyConfirmation'
          control={control}
          render={({ field: { value, onBlur, onChange } }) => (
            <Checkbox
              disabled={isFetching}
              checked={value}
              onBlur={onBlur}
              onChange={onChange}
              sx={{
                textAlign: 'justify',
                gap: 1,
                height: 36
              }}
              label={
                <CheckboxFormLabel>
                  I accept the SweepLuxe Terms of use and the Privacy Policy
                </CheckboxFormLabel>
              }
            />
          )}
        />
      </Stack>
      <Button
        disabled={!formState.isValid || isFetching}
        loading={isFetching}
        fullWidth
        type='submit'>
        Sign up
      </Button>
    </Box>
  );
};
