import { mapper } from '@shared/lib';
import { DynamicPageContentEnum, type DynamicPageState } from '../model';
import { mapFaqContent } from './map-faq-content';
import { mapPromotionContent } from './map-promotions-content';
import { mapHtmlContent } from './map-html-content';
import { mapContactUsContent } from './map-contact-us-content';

export type MapDynamicPageState = (data: any) => DynamicPageState;
export const mapDynamicPageState: MapDynamicPageState = mapper([
  { name: 'title', from: 'attributes.title' },
  { name: 'description', from: 'attributes.description' },
  { name: 'keywords', from: 'attributes.keywords' },
  { name: 'canonical', from: 'attributes.canonical' },
  { name: 'name', from: 'attributes.name' },
  {
    name: 'contentType',
    from: 'attributes.content',
    transform: (contentData: Array<{ __component: DynamicPageContentEnum }>) => {
      return contentData?.[0].__component as DynamicPageContentEnum;
    },
  },
  {
    name: 'content',
    from: 'attributes',
    transform: (attributesData: { content: Array<{ __component: DynamicPageContentEnum }> }) => {
      const pageContent = attributesData?.content?.[0].__component;
      if (pageContent === DynamicPageContentEnum.Faq) return mapFaqContent(attributesData);
      if (pageContent === DynamicPageContentEnum.ContactUs) return mapContactUsContent(attributesData);
      if (pageContent === DynamicPageContentEnum.Promotion) return mapPromotionContent(attributesData);
      if (pageContent === DynamicPageContentEnum.Html) return mapHtmlContent(attributesData);
      return null;
    },
  }
]);