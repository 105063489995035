import { FC } from 'react';
import { ButtonProps } from '../button';
import { Button as JoyButton } from '@mui/joy';
import { Link } from 'react-router-dom';

export interface LinkButtonProps extends Omit<ButtonProps, 'onClick'> {
  href: string;
  target?: string;
}

export const LinkButton: FC<LinkButtonProps> = ({ children, ...props }) => (
  <JoyButton component={Link} to={props.href} {...props}>
    {children}
  </JoyButton>
);
