import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CurrencyCodesEnum, UserStateEnum } from '@shared/types';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { sessionApiSlicer } from '../api';
import { mapLogin, mapFullProfile, mapLoyaltyStatus } from '../lib';
import {
  type SessionState,
  type FullProfile
} from './types';

const initialState: SessionState = {
  shortProfile: null,
  fullProfile: null,
  currencyBalances: null,
  currencySessionKeys: null,
  sessionKey: '',
  toggledCurrencyCode: CurrencyCodesEnum.SCO,
  userState: UserStateEnum.guestUser,
  emailPromotionSubscription: false,
  loyaltyStatus: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    initializeState: (state: SessionState, payload: PayloadAction<SessionState>) => {
      state = { ...payload.payload };
    },
    toggleCurrencyCode: (state: SessionState, action: PayloadAction<CurrencyCodesEnum>) => {
      state.toggledCurrencyCode = action.payload;
    },
    signOut: (state: SessionState) => {
      Object.assign(state, initialState, {
        userState: UserStateEnum.userIsLoggedOut,
      });
    },
    updateFullProfile: (state: SessionState, action: PayloadAction<FullProfile>) => {
      state.fullProfile = { ...state.fullProfile, ...action.payload };
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<SessionState>) => {
    builder.addMatcher(
      sessionApiSlicer.endpoints.signIn.matchFulfilled,
      (state: SessionState, action: PayloadAction<any>) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          const { currencySessionKeys, sessionKey, shortProfile } = mapLogin(action.payload);
          state.shortProfile = shortProfile;
          state.sessionKey = sessionKey;
          state.currencySessionKeys = currencySessionKeys;
          state.userState = UserStateEnum.userIsLogged;
        }
      }
    );
    builder.addMatcher(
      sessionApiSlicer.endpoints.balance.matchFulfilled,
      (state: SessionState, action: PayloadAction<any>) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.currencyBalances = action.payload.currencyBalances.reduce(
            (acc: any, el: any) => ({
              ...acc,
              [el.currency]: {
                totalBalance: el.totalBalance,
                totalBalanceNumber: el.totalBalanceNumber,
              },
            }), {}
          );
        }
      }
    );
    builder.addMatcher(
      sessionApiSlicer.endpoints.getPlayerInfo.matchFulfilled,
      (state: SessionState, action: PayloadAction<any>) => {
        state.fullProfile = mapFullProfile(action.payload);
      }
    );
    builder.addMatcher(
      sessionApiSlicer.endpoints.signOut.matchFulfilled,
      (state: SessionState) => {
        Object.assign(state, initialState, {
          userState: UserStateEnum.userIsLoggedOut,
        });
      }
    );
    builder.addMatcher(
      sessionApiSlicer.endpoints.getLoyaltyPoints.matchFulfilled,
      (state, action) => {
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.loyaltyStatus = mapLoyaltyStatus(action.payload)
        }
      })
  },
});

export const {
  signOut,
  toggleCurrencyCode,
  updateFullProfile
} = sessionSlice.actions;

export const sessionSliceReducer = sessionSlice.reducer;
