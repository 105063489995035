import { createAsyncThunk } from '@reduxjs/toolkit';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { favoriteGamesApiSlice } from './slice';
import { type FavoriteGame } from '../model';

export const removeFavoriteGame = createAsyncThunk<void, FavoriteGame>(
  'remove-favorite-game',
  async (favoriteGameToAdd: FavoriteGame, { dispatch, rejectWithValue }) => {
    try {
      const responseFromOmega = await dispatch(
        favoriteGamesApiSlice.endpoints.removeMyFavoriteGameFromOmega.initiate(favoriteGameToAdd, {
          forceRefetch: true,
        })
      ).unwrap();

      const { status } = responseFromOmega as OmegaApiResponse;
      if (status === OmegaApiResponseStatusEnum.Success) {
        await dispatch(
          favoriteGamesApiSlice.endpoints.getMyFavoriteGamesFromOmega.initiate(null, { forceRefetch: true })
        ).unwrap();
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
