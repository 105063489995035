import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { tournamentsSlice } from './slice';
import { Tournament, TournsmentsState } from './types';

type RootState = {
  [tournamentsSlice.name]: TournsmentsState;
};

export const selectIsLoading = (state: RootState): boolean => {
  return state[tournamentsSlice.name].isLoading;
};

export const selectTournaments = (state: RootState): Array<Tournament> => {
  return state[tournamentsSlice.name].publicTournaments;
};

export const useTournamentsSelector: TypedUseSelectorHook<RootState> =
  useSelector;
