export const isMobileDevice = (): boolean => {
  try {
    if (window.navigator && (window as any).navigator['standalone']) {
      return true;
    }
    if (
      navigator.userAgent.match('CriOS') ||
      navigator.userAgent.match(/mobile/i)
    ) {
      return true;
    }
  } catch (ex) {
    // continue regardless of error
  }

  return false;
};
