import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Avatar, Typography } from '@mui/joy';
import { Button } from '@shared/ui';
import { isDefined } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import {
  toggleCurrencyCode,
  selectBalance,
  useBalanceQuery,
  useSessionSelector,
  selectToggledCurrencyCode,
  selectIsAuthenticated
} from '@entities/session';

export const ToggleCoins: FC = () => {
  const dispatch = useDispatch();
  const { isFetching, refetch } = useBalanceQuery();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const balance = useSessionSelector(selectBalance);
  const toggledCurrencyCode = useSessionSelector(selectToggledCurrencyCode);

  const handleToggle = (newValue: CurrencyCodesEnum) => () => {
    dispatch(toggleCurrencyCode(newValue));
  };

  useEffect(() => {
    if (isAuthenticated) refetch();
  }, [isAuthenticated, refetch])

  return isFetching && isDefined(balance) ? null : (
    <Stack
      direction='row'
      sx={({ palette }) => ({
        display: 'grid',
        gridTemplateColumns: 'minmax(84px, 107px) minmax(84px, 107px)',
        borderWidth: 1,
        borderRadius: 8,
        borderStyle: 'solid',
        maxHeight: 40,
        borderColor: palette.common[925],
        backgroundColor: palette.common[475],
      })}>
      <Button
        {...{
          variant: 'outlined',
          color: 'primary',
          ...(toggledCurrencyCode === CurrencyCodesEnum.SCO && {
            variant: 'plain',
            color: 'neutral',
          }),
        }}
        size='md'
        sx={{
          height: 36,
          width: '100%'
        }}
        onClick={handleToggle(CurrencyCodesEnum.GCO)}>
        <Stack
          direction='column'
          alignItems='center'>
          <Stack
            direction='row'
            alignItems='center'
            gap={0.5}
            maxHeight={11}>
            <Avatar
              src='/assets/png/cold-coin.png'
              sx={{
                width: 11,
                height: 'auto'
              }}
            />
            <Typography
              sx={({ palette }) => ({
                fontSize: 10,
                fontWeight: 400,
                lineWeight: '9px',
                color: palette.common.white,
              })}>
              GC
            </Typography>
          </Stack>
          <Typography
            sx={({ palette }) => ({
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '17px',
              color: palette.common.white,
            })}>
            {balance && balance[CurrencyCodesEnum.GCO].totalBalance}
          </Typography>
        </Stack>
      </Button>
      <Button
        {...{
          variant: 'outlined',
          color: 'success',
          ...(toggledCurrencyCode === CurrencyCodesEnum.GCO && {
            variant: 'plain',
            color: 'neutral',
          }),
        }}
        size='md'
        sx={{
          height: 36,
          width: '100%',
        }}
        onClick={handleToggle(CurrencyCodesEnum.SCO)}>
        <Stack direction='column' alignItems='center'>
          <Stack
            direction='row'
            alignItems='center'
            gap={0.5}
            maxHeight={11}>
            <Avatar
              src='/assets/png/silver-coin.png'
              sx={{
                width: 11,
                height: 'auto'
              }}
            />
            <Typography
              sx={({ palette }) => ({
                fontSize: 10,
                fontWeight: 400,
                lineWeight: '9px',
                color: palette.common.white,
              })}>
              SC
            </Typography>
          </Stack>
          <Typography
            sx={({ palette }) => ({
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '17px',
              color: palette.common.white,
            })}>
            {balance && balance[CurrencyCodesEnum.SCO].totalBalance}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};
