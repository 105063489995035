import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/joy';
import { Link, LinkProps } from '@shared/ui/link';
import {
  type TextWithInlineLink as TextWithInlineLinkType
} from '@shared/lib';

export type TextWithInlineLinkProps = Pick<
  LinkProps,
  'target' | 'color' | 'href' | 'underline' | 'size'
> & {
  data: TextWithInlineLinkType;
  linkProps?: LinkProps;
  typographyProps?: TypographyProps;
};

export const TextWithInlineLink: FC<TextWithInlineLinkProps> = ({
  data,
  linkProps = {},
  typographyProps = {},
  ...props
}) => {
  const {
    combinedText,
    linkKey,
    anchorText,
    href,
    underline
  } = data;
  const mergedLinkProps = { ...linkProps, ...props };
  const [beforeLink, afterLink] = combinedText.split(new RegExp(linkKey, 'g'));
  const underlineStyle = underline === 'always' || underline === 'hover'
    ? underline
    : 'none';
  return (
    <Typography
      component='span'
      sx={({ colorSchemes }) => ({
        color: colorSchemes.dark.palette.common.white
      })}
      {...typographyProps}>
      {beforeLink}
      <Link
        {...mergedLinkProps}
        href={href}
        underline={underlineStyle}>
        {anchorText}
      </Link>
      {afterLink}
    </Typography>
  );
};