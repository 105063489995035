import { UserStateEnum } from '@entities/session';
import { TextWithInlineLink } from '@shared/lib';

export enum DynamicPageContentEnum {
  Faq = 'widgets.faq-scoped',
  Promotion = 'widgets.promotion-content',
  Html = 'widgets.html-content',
  ContactUs = 'widgets.contact-us-content',
  NotLoaded = 'NotLoaded'
};

export type BoxedContactBlockItem = {
  iconSrc: Maybe<Url>;
  shortSummary: string;
  details: Maybe<TextWithInlineLink>;
};

export type BoxedContactBlock = {
  title?: string;
  items?: Maybe<Array<BoxedContactBlockItem>>;
};

export type BoxedFaqBlockItem = {
  iconSrc: Maybe<Url>;
  shortSummary: string;
  shortSummaryStyle: Maybe<Record<string, string>>;
  details: string;
};

export type BoxedFaqBlock = {
  title?: Maybe<TextWithInlineLink>;
  items?: Maybe<Array<BoxedFaqBlockItem>>;
};

export type FaqBlockItem = {
  summary: string;
  details: string;
};

export type FaqBlock = {
  title: Maybe<string>;
  items: Maybe<Array<FaqBlockItem>>;
};

export type ContactBlock = {
  title: Maybe<string>;
  html: Maybe<any>;
};

export type ContactUsContent = {
  boxedContactBlock?: Maybe<BoxedContactBlock>;
  contactBlock?: Maybe<ContactBlock>;
};

export type FaqContent = {
  boxedFaqBlock?: Maybe<BoxedFaqBlock>;
  faqBlock?: Maybe<FaqBlock>;
};

export type HowItWorksBlockItem = {
  iconSrc: Maybe<Url>;
  details: string;
};

export type HowItWorksBlock = {
  title?: Maybe<string>;
  items?: Maybe<Array<HowItWorksBlockItem>>
};

export type CarouselSliderActionItem = {
  text: string;
  url: Url;
  userState: UserStateEnum;
};

export type DynamicPageCarouselSliderItem = {
  id: number;
  actions: Array<CarouselSliderActionItem>;
  variant1ImgSrc: Url;
  variant2ImgSrc: Url;
  bannerUrl: Url;
};

export type DynamicPageUserCarouselSliderItem = Prettify<Omit<
  DynamicPageCarouselSliderItem,
  'actions'
> & {
  action: Maybe<CarouselSliderActionItem>;
}>;

export type PromotionContent = {
  slider: Maybe<Array<DynamicPageCarouselSliderItem>>;
  faqBlock?: Maybe<Exclude<FaqBlock, 'title'>>;
  howItWorksBlock?: Maybe<HowItWorksBlock>;
};

export type HtmlBlock = {
  title?: Maybe<string>;
  html?: Maybe<any>;
};

export type HtmlContent = {
  htmlBlock?: Maybe<HtmlBlock>;
};

export type DynamicPageContent = FaqContent | PromotionContent | HtmlContent | ContactUsContent;

export type DynamicPageState = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
  name: Maybe<string>;
  contentType: Maybe<DynamicPageContentEnum>;
  content: Maybe<DynamicPageContent>;
};