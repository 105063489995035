export enum OmegaApiResponseStatusEnum {
  ValidationFail = 'VALIDATION_FAIL',
  FailQuickOpenStatus = 'FAIL_QUICK_OPEN_STATUS',
  FailUnPw = 'FAIL_UN_PW',
  Fail = 'FAIL',
  Success = 'SUCCESS',
  NotLoggedIn = 'NOT_LOGGED_IN_ERROR',
  NotFound = 'NOT_FOUND',
  InvalidPassword = 'INVALID_PASSWORD'
};

export type OmegaApiResponse = {
  additionalInfo?: Array<any>;
  advices?: Array<any>;
  errors?: Array<{ field: string, error: string }>;
  message?: Maybe<string>;
  status: OmegaApiResponseStatusEnum
};
