import { FC } from 'react';
import { Stack, Avatar, Typography, useTheme } from '@mui/joy';

type PackageCoinsProps = {
  coinVariant: 'gold' | 'silver';
  amount: number;
};

export const PackageCoins: FC<PackageCoinsProps> = ({ coinVariant, amount }) => {
  const theme = useTheme();

  function addCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <Stack direction='row' alignItems='center' spacing={0.5} maxHeight={20}>
      {coinVariant === 'gold' ? (
        <Avatar src='/assets/png/cold-coin.png' sx={{ width: 16, height: 16 }} />
      ) : (
        <Avatar src='/assets/png/silver-coin.png' sx={{ width: 16, height: 16 }} />
      )}

      <Typography
        sx={{
          fontSize: 14,
          color: theme.colorSchemes.dark.palette.common[150],
        }}>
        {addCommas(amount)}
      </Typography>
    </Stack>
  );
};
