import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchGamesPage } from '../api';
import { type GamesPageState } from './types';

const initialState: GamesPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  isLoading: false
};

export const gamesPageSlice = createSlice({
  name: 'games-page',
  initialState,
  reducers: {
    initializeState: (state: GamesPageState, payload: PayloadAction<GamesPageState>) => {
      state = { ...payload.payload };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<GamesPageState>) => {
    builder.addCase(fetchGamesPage.pending, (state: GamesPageState) => {
      state.isLoading = true;
    })
    builder.addCase(fetchGamesPage.fulfilled, (state: GamesPageState, action: PayloadAction<GamesPageState>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.isLoading = false;
      })
    builder.addCase(fetchGamesPage.rejected, (state: GamesPageState) => {
      state.isLoading = true;
    })
  },
});

export const { initializeState } = gamesPageSlice.actions;

export const gamesPageReducer = gamesPageSlice.reducer;
