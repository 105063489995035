import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Person as PersonIcon,
  Chat as ChatIcon,
  Info as InfoIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  Divider,
  Stack,
  Typography
} from '@mui/joy';
import { List, ListItemButton, ListItemDecorator } from '@shared/ui';
import { isEmpty } from '@shared/lib';
import {
  useLayoutSelector,
  selectSidebarMenuBanner,
  selectSidebarMenuLogo,
  SidebarMenuBanner,
} from '@entities/page-layout';
import {
  useLazySignOutQuery,
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  useSidebarMenuSelector,
  selectIsOpenSidebarMenu,
  selectMenuItems,
  closeSidebarMenu,
} from '../model';
import { SidebarMenuHeader } from './sidebar-menu-header.component';

export const SidebarMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signOut, { isFetching }] = useLazySignOutQuery();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const isOpen = useSidebarMenuSelector(selectIsOpenSidebarMenu);
  const sidebarMenuBannerSrc = useLayoutSelector(selectSidebarMenuBanner);
  const sidebarMenuLogoSrc = useLayoutSelector(selectSidebarMenuLogo);
  const sidebarMenuItems = useSelector(selectMenuItems);

  const navigateTo = (slug: string): void => {
    dispatch(closeSidebarMenu());
    navigate(slug);
  };

  const handleClose = (): void => {
    dispatch(closeSidebarMenu());
  };

  const handleNavigateTo = (slug: Url) => (): void => {
    navigateTo(slug)
  };

  const handleSignOut = async (): Promise<void> => {
    navigateTo('/')
    await signOut();
  };

  return (
    <Drawer
      open={isOpen}
      sx={{ zIndex: 15 }}
      slotProps={{
        content: {
          sx: ({ breakpoints, palette }) => ({
            width: 390,
            backgroundColor: palette.common[475],
            [breakpoints.down(491)]: {
              width: '100%',
            }
          }),
        },
      }}
      onClose={handleClose}>
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          gap: 3,
          paddingBlock: 3,
          paddingInline: 2,
          [breakpoints.down(491)]: {
            gap: 2
          }
        })}>
        <SidebarMenuHeader
          isAuthenticated={isAuthenticated}
          sidebarMenuLogoSrc={sidebarMenuLogoSrc}
          onClose={handleClose}
        />
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            gap: 3,
            [breakpoints.down(491)]: {
              gap: 2,
            }
          })}>
          {sidebarMenuBannerSrc && (
            <SidebarMenuBanner imgSrc={sidebarMenuBannerSrc} />
          )}
          <List
            size='lg'
            sx={{
              gap: 0.25,
              paddingBlock: 0,
            }}>
            {sidebarMenuItems.map(({ id, text, slug, iconSrc }) => (
              <ListItemButton
                disabled={isFetching}
                key={id}
                variant='soft'
                onClick={handleNavigateTo(slug)}
                sx={{ gap: 1 }}>
                <ListItemDecorator>
                  <Box
                    component='img'
                    src={iconSrc}
                    sx={{ width: 22 }}
                  />
                </ListItemDecorator>
                <Typography sx={({ palette }) => ({
                  fontSize: 16,
                  color: palette.common.white,
                })}>
                  {text}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Stack>
        {!isEmpty(sidebarMenuItems) && <Divider />}
        <List
          size='lg'
          sx={{
            gap: 0.25,
            paddingBlock: 0,
          }}>
          {isAuthenticated && (
            <ListItemButton
              disabled={isFetching}
              onClick={handleNavigateTo('/account')}
              sx={{ gap: 1 }}>
              <ListItemDecorator>
                <PersonIcon sx={{ fontSize: 22 }} />
              </ListItemDecorator>
              <Typography sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common[150],
              })}>
                Account
              </Typography>
            </ListItemButton>
          )}
          <ListItemButton
            disabled={isFetching}
            sx={{ gap: 1 }}>
            <ListItemDecorator>
              <InfoIcon sx={{ fontSize: 22 }} />
            </ListItemDecorator>
            <Typography sx={({ palette }) => ({
              fontSize: 16,
              color: palette.common[150],
            })}>
              How it works
            </Typography>
          </ListItemButton>
          <ListItemButton
            disabled={isFetching}
            sx={{ gap: 1 }}>
            <ListItemDecorator>
              <ChatIcon sx={{ fontSize: 22 }} />
            </ListItemDecorator>
            <Typography sx={({ palette }) => ({
              fontSize: 16,
              color: palette.common[150],
            })}>
              Live Support
            </Typography>
          </ListItemButton>
          {isAuthenticated && (
            <ListItemButton
              disabled={isFetching}
              variant='plain'
              sx={{ gap: 1 }}
              onClick={handleSignOut}>
              <ListItemDecorator>
                <LogoutIcon sx={{ fontSize: 22 }} />
              </ListItemDecorator>
              <Typography sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common[150],
              })}>
                Log out
              </Typography>
            </ListItemButton>
          )}
        </List>
      </Stack>
    </Drawer >
  );
};
