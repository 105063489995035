import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Stack, Typography } from '@mui/joy';
import { PromotionCard } from '@entities/promotions/ui';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import {
  selectPromotionCards,
  selectPromotionsPageHeading,
  selectPromotionsPageMetaCanonical,
  selectPromotionsPageMetaDescription,
  selectPromotionsPageMetaKeywords,
  selectPromotionsPageMetaTitle,
  usePromotionsPageSelector
} from '../model';
import { useGetPromotionsPageQuery } from '../api';
import { SkeletonList } from './skeleton-list.component';

export const Promotions: FC = () => {
  const { isFetching } = useGetPromotionsPageQuery()

  const title = usePromotionsPageSelector(selectPromotionsPageMetaTitle)
  const description = usePromotionsPageSelector(selectPromotionsPageMetaDescription)
  const keywords = usePromotionsPageSelector(selectPromotionsPageMetaKeywords)
  const canonical = usePromotionsPageSelector(selectPromotionsPageMetaCanonical)

  const heading = usePromotionsPageSelector(selectPromotionsPageHeading)
  const promotionCards = useSelector(selectPromotionCards)

  return (
    <>
      {isFetching && (
        <MetaTagsBlock
          title={title}
          description={description}
          keywords={keywords}
          canonical={canonical}
        />
      )}
      <Stack
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 'calc(80rem + 10rem)',
          paddingInline: '5rem',
          paddingBlock: '3rem 4rem',
          [breakpoints.down(768)]: {
            paddingInline: '1rem',
            paddingBlock: '1.5rem',
          }
        })}>
        <Box
          sx={{ width: '100%' }}>
          <Typography sx={({ colorSchemes, breakpoints }) => ({
            fontSize: '1.75rem',
            fontWeight: 600,
            lineHeight: '2.25rem',
            color: colorSchemes.dark.palette.common.white,
            marginBottom: '2rem',
            [breakpoints.down(768)]: {
              fontSize: '1.25rem',
              lineHeight: '1.875rem',
              marginBottom: '1rem',
            }
          })}>
            {isFetching ? <Skeleton variant='text' width='5rem' /> : heading}
          </Typography>
        </Box>
        <Box
          sx={({ breakpoints }) => ({
            display: 'grid',
            width: '100%',
            gap: '1.5rem',
            gridTemplateColumns: '1fr 1fr 1fr',
            [breakpoints.down(1024)]: {
              gridTemplateColumns: '1fr 1fr',
            },
            [breakpoints.down(768)]: {
              gridTemplateColumns: 'minmax(0, 25.625rem)',
              justifyContent: 'center',
            }
          })}>
          {isFetching && <SkeletonList length={3} />}
          {!isFetching && promotionCards?.map((item) => <PromotionCard key={item.id} item={item} />)}
        </Box>
      </Stack>
    </>
  );
};