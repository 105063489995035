import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { managePromotionalSubscriptionsApiSlicer } from '../api';

export type EmailPromotionSubscriptionState = {
  emailPromotionSubscription: boolean;
};

const initialState: EmailPromotionSubscriptionState = {
  emailPromotionSubscription: false,
};

export const managePromotionalSubscriptionsSlice = createSlice({
  name: 'manage-promotional-subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<EmailPromotionSubscriptionState>) => {
    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.getEmailSubscriptionState.matchFulfilled,
      (state, action) => {
        if (action?.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.emailPromotionSubscription = action.payload.subscribeToEmail === 'TRUE'
        }
      });
    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.updateEmailSubscription.matchFulfilled,
      (state) => {
        state.emailPromotionSubscription = !state.emailPromotionSubscription
      });
  }
});

export const managePromotionalSubscriptionsReducer = managePromotionalSubscriptionsSlice.reducer;
