import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { transactionListSlice } from './slice';
import {
  TransactionListState
} from './types';
import { TransactionTypeEnum } from 'entities/transactions';

type RootState = {
  [transactionListSlice.name]: TransactionListState;
};

export const selectCurrentTransactionType = (state: RootState): TransactionTypeEnum => (
  state[transactionListSlice.name].currentTransactionType
);

export const selectStartDate = (state: RootState): string => (
  state[transactionListSlice.name].startDate
);

export const selectEndDate = (state: RootState): string => (
  state[transactionListSlice.name].endDate
);

export const useTransactionListSelector: TypedUseSelectorHook<{
  [transactionListSlice.name]: TransactionListState;
}> = useSelector;