import { baseOmegaApi, OmegaApiResponse, SESSION_TAG } from '@shared/api';
import {
  type SignIn,
  type CheckLoggedInSuccessResponse,
  type SignUpConfirmation,
  type ResendVerificationCode,
  type UpdatePlayerInfo,
} from './types';

export const sessionApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.query<OmegaApiResponse, SignIn>({
      query: (arg: SignIn) => ({ url: 'ips/login', params: arg }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: false }
    }),
    signOut: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/logout' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    balance: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/getBalance' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    checkLoggedIn: build.query<CheckLoggedInSuccessResponse, void>({
      query: () => ({ url: 'ips/checkLoggedIn' }),
      extraOptions: { auth: true, showLoader: false },
    }),
    getPlayerInfo: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/getPlayerInfo' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    updatePlayerInfo: build.query<OmegaApiResponse, Partial<UpdatePlayerInfo>>({
      query: (params) => ({ url: 'ips/updatePlayerInfo', params }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
    signUpConfirmation: build.query<OmegaApiResponse, SignUpConfirmation>({
      query: (params) => ({ url: 'ips/signupConfirmation', params }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
    resendVerificationCode: build.query<OmegaApiResponse, ResendVerificationCode>({
      query: (params) => ({ url: 'ips/resendVerificationCode', params }),
      extraOptions: { auth: true, showLoader: true },
    }),
    getLoyaltyPoints: build.query<OmegaApiResponse, void>({
      query: () => ({ url: '/ips/loyaltyPointsStatus' }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});

export const {
  useLazyBalanceQuery,
  useLazySignInQuery,
  useLazySignOutQuery,
  useLazyCheckLoggedInQuery,
  useLazyGetPlayerInfoQuery,
  useBalanceQuery,
  useSignInQuery,
  useSignOutQuery,
  useCheckLoggedInQuery,
  useGetPlayerInfoQuery,
  useLazyUpdatePlayerInfoQuery,
  useLazySignUpConfirmationQuery,
  useLazyResendVerificationCodeQuery,
  useGetLoyaltyPointsQuery,
} = sessionApiSlicer;
