import { Context, createContext, useContext } from 'react';

export interface UseSocket {
  isConnected: boolean;
  connectSocket: (url: string) => void;
  disconnectSocket: () => void;
  subscribe: (handler: (data: any) => void) => () => void;
}

export const SocketContext: Context<Maybe<UseSocket>> = createContext<Maybe<UseSocket>>(null);

export const useSocket = (): UseSocket => {
  const context = useContext(SocketContext);
  if (!context) throw new Error('useSocket must be used within a SocketProvider');
  return context;
};
