import { FC } from 'react';
import { Divider, Typography, Stack, useTheme } from '@mui/joy';
import { AppLogo, TextWithInlineLink } from '@shared/ui';
import { useMedia } from '@shared/lib';
import {
  useLayoutSelector,
  selectPageFooterVariant1LogoImgSrc,
  selectPageFooterNavigationItems,
  selectPageFooterPromotionLegalNote,
  selectPageFooterTextWithInlineLink,
} from '@entities/page-layout';
import { NavigationSection } from './navigation-section.component';

export const LayoutFooter: FC = () => {
  const theme = useTheme();
  const isMobile = useMedia(`(max-width: ${912}px)`);
  const variant1LogoImgSrc = useLayoutSelector(selectPageFooterVariant1LogoImgSrc);
  const navigationItems = useLayoutSelector(selectPageFooterNavigationItems);
  const textWithInlineLink = useLayoutSelector(selectPageFooterTextWithInlineLink);
  const promotionLegalNote = useLayoutSelector(selectPageFooterPromotionLegalNote);
  return (
    <Stack
      component='footer'
      gap={3}
      sx={({ breakpoints, colorSchemes }) => ({
        width: '100%',
        maxWidth: 1280,
        paddingBlock: 6,
        backgroundColor: colorSchemes.dark.palette.common[475],
        [breakpoints.down(913)]: {
          paddingBlock: 3,
        },
      })}>
      <Stack
        direction='column'
        gap={2}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%',
            ...(isMobile && ({
              flexDirection: 'column',
              gap: 1,
            }))
          }}>
          {variant1LogoImgSrc && (
            <AppLogo src={variant1LogoImgSrc} />
          )}
          {textWithInlineLink && (
            <TextWithInlineLink
              target='_blank'
              underline='none'
              size='md'
              data={textWithInlineLink}
            />
          )}
        </Stack>
        {isMobile ? (
          <>
            <Stack direction='column' gap={2}>
              <NavigationSection data={navigationItems} />
              {promotionLegalNote && (
                <Typography sx={{
                  maxWidth: 677,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: theme.colorSchemes.dark.palette.common[150]
                }}>
                  {promotionLegalNote}
                </Typography>
              )}
            </Stack>
            <Stack
              direction='column'
              gap={2}
              width='100%'>
              <Typography sx={{
                width: 'fit-content',
                borderRadius: 8,
                padding: .5,
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                color: theme.colorSchemes.dark.palette.common[300],
                backgroundColor: theme.colorSchemes.dark.palette.common[900],
              }}>
                +18
              </Typography>
              <Divider />
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'>
                <Typography sx={{
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: theme.colorSchemes.dark.palette.common[150]
                }}>
                  &copy; {new Date().getFullYear()} X casino. All rights reserved.
                </Typography>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction='column' gap={2}>
              {promotionLegalNote && (
                <Typography sx={{
                  maxWidth: 677,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: theme.colorSchemes.dark.palette.common[150]
                }}>
                  {promotionLegalNote}
                </Typography>
              )}
              <NavigationSection data={navigationItems} />
            </Stack>
            <Stack
              direction='column'
              gap={4}
              width='100%'>
              <Divider />
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'>
                <Typography sx={{
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: theme.colorSchemes.dark.palette.common[150]
                }}>
                  &copy; {new Date().getFullYear()} X casino. All rights reserved.
                </Typography>
                <Typography sx={{
                  borderRadius: 8,
                  padding: .5,
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  color: theme.colorSchemes.dark.palette.common[300],
                  backgroundColor: theme.colorSchemes.dark.palette.common[900],
                }}>
                  +18
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack >
  );
};