import { FC, ReactNode, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { Box, Skeleton, Tab, TabList, Tabs } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { selectGameCategories, useGamesSelector, useGetGamesCategoriesQuery, selectGamesPageCategoryIds, GameCategory } from '@entities/games';
import {
  selectSelectedCategoryId,
  setSelectedCategoryId,
  useGamesFilterByCategorySelector,
  fetchGamesByCategory,
} from '../model';

type CategoriesSelectProps = {
  preloadByFirstCategory: boolean;
  selectFavoriteSlot: ReactNode;
  onChange: () => void;
};

export const CategoriesSelect: FC<CategoriesSelectProps> = ({
  preloadByFirstCategory,
  selectFavoriteSlot,
  onChange,
}) => {
  const isMobile = useMedia(`(max-width: ${768}px)`);
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const categories = useGamesSelector(selectGameCategories);
  const gamesPageCategoryIds = useGamesSelector(selectGamesPageCategoryIds);
  const selectedCategoryId = useGamesFilterByCategorySelector(selectSelectedCategoryId);
  const { isFetching } = useGetGamesCategoriesQuery();

  const categoriesToShow = useMemo(() => {
    if (!gamesPageCategoryIds) {
      return [];
    }
    return gamesPageCategoryIds?.map(
      (categoryId: number) =>
        categories?.find((categoryInStore: GameCategory) => categoryInStore?.id === categoryId)!
    );
  }, [categories, gamesPageCategoryIds]);

  useLayoutEffect(() => {
    if (preloadByFirstCategory && categoriesToShow.length && !selectedCategoryId) {
      const categoryId = categoriesToShow[0].id;
      dispatch(setSelectedCategoryId(categoryId));
    }
  }, [preloadByFirstCategory, categories, selectedCategoryId, dispatch, categoriesToShow]);

  useEffect(() => {
    if (selectedCategoryId) {
      dispatch(fetchGamesByCategory({ categoryId: selectedCategoryId, page: 1, pageSize: isMobile ? 12 : 34 }));
    }
  }, [selectedCategoryId, isMobile, dispatch]);

  const handleChange = (_: any, tab: Maybe<number | string>) => {
    if (typeof tab === 'number') {
      dispatch(setSelectedCategoryId(tab));
      onChange();
    }
  };

  return (
    <Tabs
      onChange={handleChange}
      value={selectedCategoryId}
      sx={(theme) => ({
        [theme.breakpoints.down(912)]: {
          position: 'absolute',
          top: 0,
          flexWrap: 'wrap',
          width: '100vw',
          maxWidth: 'fit-content',
          overflowX: 'auto',
          paddingInline: '1rem',
          marginInline: 'auto',
        },
      })}
    >
      <TabList
        disableUnderline
        sx={(theme) => ({
          p: '0.1875rem',
          gap: '0.1875rem',
          background: theme.colorSchemes.dark.palette.common[475],
        })}
      >
        {!isFetching &&
          categoriesToShow.map(({ id, name, iconSrc }) => (
            <Tab value={id} disableIndicator key={id} sx={{ textWrap: 'nowrap' }}>
              <Box component='img' src={iconSrc} sx={{ width: '1rem', aspectRatio: '1/1' }} />
              {name}
            </Tab>
          ))}

        {isFetching &&
          Array(6)
            .fill(null)
            .map((_, i) => (
              <Tab disableIndicator key={i}>
                <Skeleton variant='circular' sx={{ width: '1rem', height: '1rem' }} />
                <Skeleton variant='text' sx={{ width: '3.125rem' }} />
              </Tab>
            ))}

        {selectFavoriteSlot}
      </TabList>
    </Tabs>
  );
};
