import {
  BaseQueryApi,
  type BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  incrementActiveRequests,
  decrementActiveRequests
} from '@shared/ui/circular-loader';
import { CMS_API_URL } from '../config';

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = fetchBaseQuery({
  baseUrl: CMS_API_URL
});

export const baseStrapiQuery = async (
  arg: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: { showLoader?: boolean; } = { showLoader: true }
) => {
  const shouldShowLoader = extraOptions?.showLoader ?? true;
  if (shouldShowLoader) api.dispatch(incrementActiveRequests());
  try {
    const result = await baseQuery(arg, api, {});
    return result;
  } catch (error) {
    return { error };
  } finally {
    if (shouldShowLoader) api.dispatch(decrementActiveRequests());
  }
};