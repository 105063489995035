import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { GamesItem, Pagination } from '@entities/games';
import { gamesFilteredByFavoriteSlice } from './slice';
import { type GamesFilterByFavoriteState } from './types';

type RootState = {
  [gamesFilteredByFavoriteSlice.name]: GamesFilterByFavoriteState;
};

export const selectPagination = (state: RootState): Maybe<Pagination> =>
  state[gamesFilteredByFavoriteSlice.name].pagination;

export const selectGames = (state: RootState): Array<GamesItem> => state[gamesFilteredByFavoriteSlice.name].games;

export const selectIsFavorite = (state: RootState): boolean => state[gamesFilteredByFavoriteSlice.name].isFavorite;

export const selectIsLoading = (state: RootState): boolean => state[gamesFilteredByFavoriteSlice.name].isLoading;

export const useGamesFilterByFavoriteSelector: TypedUseSelectorHook<{
  [gamesFilteredByFavoriteSlice.name]: GamesFilterByFavoriteState;
}> = useSelector;
