import { FC } from 'react'
import { Skeleton, Stack, Typography } from '@mui/joy';
import { LoyaltyProgressBar } from '@features/loyalty-progress-bar';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import {
  selectLuxeClubPageHeading,
  selectLuxeClubPageMetaCanonical,
  selectLuxeClubPageMetaDescription,
  selectLuxeClubPageMetaKeywords,
  selectLuxeClubPageMetaTitle,
  useLuxeClubPageSelector
} from '../model';
import { useGetLuxeClubPageQuery } from '../api';

export const LuxeClubPage: FC = () => {
  const { isFetching } = useGetLuxeClubPageQuery()

  const title = useLuxeClubPageSelector(selectLuxeClubPageMetaTitle)
  const description = useLuxeClubPageSelector(selectLuxeClubPageMetaDescription)
  const keywords = useLuxeClubPageSelector(selectLuxeClubPageMetaKeywords)
  const canonical = useLuxeClubPageSelector(selectLuxeClubPageMetaCanonical)
  const heading = useLuxeClubPageSelector(selectLuxeClubPageHeading)

  return (
    <>
      {isFetching && (
        <MetaTagsBlock
          title={title}
          description={description}
          keywords={keywords}
          canonical={canonical}
        />
      )}
      <Stack
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 'calc(80rem + 10rem)',
          paddingInline: '5rem',
          paddingBlock: '3rem 4rem',
          [breakpoints.down(768)]: {
            paddingInline: '1rem',
            paddingBlock: '1.5rem',
          }
        })}>
        <Typography sx={({ palette, breakpoints }) => ({
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '2.25rem',
          color: palette.common.white,
          mb: '2rem',
          [breakpoints.down(768)]: {
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
            mb: '1rem',
          }
        })}>
          {isFetching ? <Skeleton variant='text' width='5rem' /> : heading}
        </Typography>
        <LoyaltyProgressBar />
      </Stack>
    </>
  );
};