import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Skeleton, Stack, Typography, Box } from '@mui/joy'
import { Button } from '@shared/ui'
import { PromotionCardWithAction } from '../model'

export type PromotionCardProps = {
  item: PromotionCardWithAction
}

export const PromotionCard: FC<PromotionCardProps> = ({ item }) => {
  const [isImageLoading, setImageLoading] = useState(true)

  const handleLoaded = () => {
    setImageLoading(false)
  }

  return (
    <Stack sx={({ colorSchemes }) => ({
      padding: '1.5rem 1rem',
      position: 'relative',
      borderRadius: '0.5rem',
      background: colorSchemes.dark.palette.common[475],
      gap: '1rem',
    })}>
      <Stack gap='0.5rem'>
        <Typography sx={({ colorSchemes }) => ({
          color: colorSchemes.dark.palette.common.white,
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '1.75rem',
        })}>{item.title}</Typography>
        <Typography sx={({ colorSchemes }) => ({
          color: colorSchemes.dark.palette.common[150],
          fontFamily: 'Poppins',
          fontSize: '0.875rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.25rem',
        })}>{item.description}</Typography>
      </Stack>
      <Box sx={{
        aspectRatio: '3/2',
        width: '100%',
        borderRadius: '0.5rem',
        position: 'relative',
        overflow: 'hidden',
      }}>
        <Skeleton loading={isImageLoading} />
        <Box component={item.bannerUrl ? Link : 'div'} to={item.bannerUrl}>
          <Box
            component='img'
            width='100%'
            src={item.imageSrc}
            onLoad={handleLoaded} />
        </Box>
        {item.action && (
          <Button
            component={Link}
            to={item.action.url}
            size='md'
            sx={{
              position: 'absolute',
              top: '54%',
              left: '7%'
            }}>
            {item.action.text}
          </Button>
        )}
      </Box>
    </Stack>
  )
}
