import { FC } from 'react';
import { Stack } from '@mui/joy';
import { GameListItemSkeleton } from '@entities/games';

type LoadingListProps = {
  length: number;
};

export const LoadingList: FC<LoadingListProps> = ({ length }) => {
  return (
    <Stack 
      direction='row' 
      sx={({ breakpoints }) => ({
        gap: '16px',
        [breakpoints.down(912)]: {
          display: 'grid',
          gridTemplateColumns: `repeat(${length}, auto)`,
          justifyContent: 'start',
          gap: '8px',
          overflow: 'hidden',
        },
      })}>
      {Array(length)
        .fill(null)
        .map((_, i) => (
          <GameListItemSkeleton
            key={i}
            sx={({ breakpoints }) => ({
              width: '160px',
              height: '120px',
              [breakpoints.up(911)]: {
                width: '100%',
              }
            })}/>
        ))}
    </Stack>
  );
};
