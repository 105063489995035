import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Stack, Skeleton, styled } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';
import {
  Tournament,
  TournamentsListItem,
  fetchTournaments,
  selectIsLoading,
  selectTournaments,
  useTournamentsSelector,
} from '@entities/tournaments';
import { TournamentsListHeader } from './tournaments-list-header.component';

type TournamentsListProps = {
  title: Maybe<string>;
};

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down(912)]: {
    width: 'calc(100% + 32px)',
    marginLeft: -16,
    paddingInline: 16,

    '.swiper-slide': {
      width: '100%',
      maxWidth: 410,
    },
  },
}));

export const TournamentsList: FC<TournamentsListProps> = ({ title }) => {
  const isMobile = useMedia(`(max-width: ${912}px)`);
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const isLoading = useTournamentsSelector(selectIsLoading);
  const tournaments = useTournamentsSelector(selectTournaments);

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  const [showSlider, setShowSlider] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchTournaments(isAuthenticated))
  }, [dispatch, isAuthenticated]); 

  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    });
  }, []);

  return (
    <Stack gap={2}>
      <TournamentsListHeader
        title={title}
        navigationNextRef={navigationNextRef}
        navigationPrevRef={navigationPrevRef}/>

      {!isLoading && showSlider && (
        <StyledSwiper
          slidesPerView={isMobile ? 'auto' : 3}
          spaceBetween={isMobile ? 8 : 24}
          modules={[Navigation]}
          navigation={{
            enabled: true,
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}>
          {tournaments.slice(0, 4).map((tournament: Tournament, index: number) => (
            <SwiperSlide key={index}>
              <TournamentsListItem
                tournament={tournament}
                sx={{
                  height: '100%',
                  justifyContent: 'space-between',
                }}/>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      )}

      {isLoading && (
        <Stack
          direction='row'
          gap={2.5}
          sx={({breakpoints}) =>({
            [breakpoints.down(912)]: {
              width: '2000px',
              marginLeft: '-16px',
              paddingInline: '16px',
              gap: 1,
              'div': {
                width: 410,
              },
            },
            [breakpoints.down(442)]: {
              width: 'calc(100% * 3)',          
              'div': {
                width: '100%',
              },
            },
          })}>
          {Array(3).fill(null).map((_, index: number) => (
            <Skeleton
              key={index}
              variant='rectangular'
              sx={{
                width: '100%',
                maxWidth: 410,
                height: 510,
                borderRadius: 8,
              }}/>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
