import { FC, useState } from 'react';
import { Box, Skeleton } from '@mui/joy';
import { useMedia } from '@shared/lib';

export const SingInPromoBanner: FC = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const isMobile = useMedia(`(max-width: ${490}px)`);
  const imgSrc = isMobile
    ? '/assets/png/login_banner_mobile.png'
    : '/assets/png/login_banner.png';

  const handleImageLoad = (): void => {
    setImageLoaded(true);
  };

  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    }}>
      <Box
        component='img'
        src={imgSrc}
        onLoad={handleImageLoad}
        alt='Sign up Banner'
        sx={({ breakpoints }) => ({
          height: '100%',
          width: '100%',
          display: imageLoaded ? 'block' : 'none',
          [breakpoints.down(491)]: {
            objectFit: 'contain'
          }
        })}
      />
      {!imageLoaded && <Skeleton animation='wave' />}
    </Box>
  );
};