import { mapper } from './mapper';

export type ImageSources = {
  variant1SizeImgSrc: Url;
  variant2SizeImgSrc: Url;
};
type MapImageSources = (data: any) => ImageSources;
export const mapImageSources: MapImageSources = mapper([
  { name: 'variant1SizeImgSrc', from: 'url' },
  { name: 'variant2SizeImgSrc', from: 'url' },
]);

export type TextWithInlineLink = {
  combinedText: string;
  linkKey: string;
  anchorText: string;
  href?: Url;
  underline: 'hover' | 'always' | 'none';
};
type MapTextWithInlineLink = (data: any) => TextWithInlineLink;
export const mapTextWithInlineLink: MapTextWithInlineLink = mapper([
  { name: 'combinedText', from: 'combinedText' },
  { name: 'linkKey', from: 'linkKey' },
  { name: 'anchorText', from: 'anchorText' },
  { name: 'href', from: 'href' },
]);