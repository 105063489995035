import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum,
  UserStateEnum
} from '@shared/types';

export enum UserKycStatusEnum {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Pass = 'PASS',
  Suspended = 'SUSPENDED',
  Requested = 'REQUESTED',
  Unknown = 'N/A'
};

export enum EmailVerificationStatusEnum {
  UnVerified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export enum CurrentVIPLevelEnum {
  Level1 = 'Bronze',
  Level2 = 'Silver',
  Level3 = 'Gold',
  Level4 = 'Ruby',
  Level5 = 'Emerald',
  Level6 = 'Sapphire',
  Level7 = 'Diamond',
  Level8 = 'Platinum',
}

export type ShortProfile = {
  partyId: string;
  username: string;
  currency: CurrencyCodesEnum;
  email: Email;
  parentId: string;
  level: string;
  userType: string;
  registrationStatus: UserRegistrationStatusEnum;
  pendingLimitConfirmation: string;
  country: null;
  kycStatus: UserKycStatusEnum;
  securityVerificationRequiredFields: null;
};

export type FullProfile = {
  userId: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  nickname: Maybe<string>;
  email: Maybe<string>;
  phone: Maybe<string>;
  birthDate: Maybe<string>;
  address: Maybe<string>;
  country: Maybe<string>;
  province: Maybe<string>;
  city: Maybe<string>;
  postalCode: Maybe<string>;
  registrationStatus: UserRegistrationStatusEnum;
  kycStatus: UserKycStatusEnum;
  emailVerificationStatus: EmailVerificationStatusEnum
};

export type CurrencyBalances = {
  [CurrencyCodesEnum.SCO]: {
    totalBalance: string;
    totalBalanceNumber: number;
    currency: string;
  };
  [CurrencyCodesEnum.GCO]: {
    totalBalance: string;
    totalBalanceNumber: number;
    currency: string;
  };
};

export type CurrencySessionKeys = {
  [CurrencyCodesEnum.SCO]: {
    sessionKey: string;
  };
  [CurrencyCodesEnum.GCO]: {
    sessionKey: string;
  };
};

export type LoyaltyStatus = {
  accumulatedLoyaltyPoint: Maybe<number>
  currentLoyaltyPoint: Maybe<number>
  currentVIPLevel: Maybe<CurrentVIPLevelEnum>
  remainingPointsForNextLevel: Maybe<number>
  totalPointsForNextLevel: Maybe<number>
}

export type SessionState = {
  shortProfile: Maybe<ShortProfile>;
  fullProfile: Maybe<FullProfile>;
  currencyBalances: Maybe<CurrencyBalances>;
  currencySessionKeys: Maybe<CurrencySessionKeys>;
  sessionKey: string;
  toggledCurrencyCode: CurrencyCodesEnum;
  userState: UserStateEnum;
  emailPromotionSubscription: boolean;
  loyaltyStatus: Maybe<LoyaltyStatus>
};
