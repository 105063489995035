import { FC } from 'react';
import { Stack } from '@mui/joy';
import { isDefined } from '@shared/lib';
import { ContactBlock, ContactUsBlock } from '@widgets/contact-us-block';
import { selectContactUsContent, useContactUsPageContentSelector } from '../model';

export const ContactUs: FC = () => {
  const contactUsContent = useContactUsPageContentSelector(selectContactUsContent);

  return isDefined(contactUsContent) && (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 5,
        paddingBlockEnd: 8,
        gap: 8,
        [breakpoints.down(1350)]: {
          paddingInline: 2,
        },
        [breakpoints.down(769)]: {
          paddingBlockStart: 0,
          paddingBlockEnd: 3,
          gap: 6,
        },
      })}>
      <ContactUsBlock title={contactUsContent?.boxedContactBlock?.title} items={contactUsContent?.boxedContactBlock?.items} />
      <ContactBlock title={contactUsContent?.contactBlock?.title} html={contactUsContent?.contactBlock?.html} />
    </Stack>
  );
};
