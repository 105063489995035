import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Button } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { Pagination } from '@entities/games';
import {
  selectSelectedCategoryId,
  selectPaginationByCategoryId,
  useGamesFilterByCategorySelector,
  fetchGamesByCategory,
} from '@features/games-filter-by-category';
import {
  selectSearch,
  selectPagination,
  useGamesFilterBySearchSelector,
  fetchGamesBySearch,
} from '@features/games-filter-by-search';
import {
  useGamesFilterByFavoriteSelector,
  selectPagination as selectPaginationByFavorite,
  fetchGamesByFavorite,
} from '@features/games-filter-by-favorite';
import { FilterTypeEnum, selectActiveFilterType, useGamesListWidgetSelector } from '../model';

export type LoadMoreButtonProps = {
  isLoading: boolean;
};

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({ isLoading }) => {
  const isMobileView = useMedia(`(max-width: ${768}px)`);
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);

  const selectedCategoryId = useGamesFilterByCategorySelector(selectSelectedCategoryId);
  const search = useGamesFilterBySearchSelector(selectSearch);
  const paginationByCategory = useGamesFilterByCategorySelector(selectPaginationByCategoryId(selectedCategoryId));
  const paginationBySearch = useGamesFilterBySearchSelector(selectPagination);
  const paginationByFavorite = useGamesFilterByFavoriteSelector(selectPaginationByFavorite);

  const pagination: Maybe<Pagination> = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category:
        return paginationByCategory;
      case FilterTypeEnum.Search:
        return paginationBySearch;
      case FilterTypeEnum.Favorite:
        return paginationByFavorite;
    }
  }, [activeFilterType, paginationByCategory, paginationBySearch, paginationByFavorite]);

  const loadMoreByCategory = useCallback(async () => {
    await dispatch(
      fetchGamesByCategory({
        categoryId: selectedCategoryId!,
        page: pagination!.page + 1,
        pageSize: isMobileView ? 12 : 34,
      })
    );
  }, [pagination, selectedCategoryId, isMobileView, dispatch]);

  const loadMoreBySearch = useCallback(async () => {
    await dispatch(
      fetchGamesBySearch({
        search,
        page: pagination!.page + 1,
        pageSize: isMobileView ? 12 : 34,
      })
    );
  }, [pagination, search, isMobileView, dispatch]);

  const loadMoreByFavorite = useCallback(async () => {
    await dispatch(
      fetchGamesByFavorite({
        page: pagination!.page + 1,
        pageSize: isMobileView ? 12 : 34,
      })
    );
  }, [pagination, isMobileView, dispatch]);

  const handleLoadMore = useCallback(async (): Promise<void> => {
    if (!pagination) return;

    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category:
        await loadMoreByCategory();
        break;
      case FilterTypeEnum.Search:
        await loadMoreBySearch();
        break;
      case FilterTypeEnum.Favorite:
        await loadMoreByFavorite();
        break;
    }
  }, [activeFilterType, pagination, loadMoreByCategory, loadMoreBySearch, loadMoreByFavorite]);

  const hideLoadMore = !!pagination && pagination.page >= pagination.pageCount;

  return (
    !hideLoadMore && (
      <Button
        disabled={isLoading}
        onClick={handleLoadMore}
        variant='solid'
        color='primary'
        sx={(theme) => ({
          [theme.breakpoints.down(768)]: {
            maxWidth: '22.375rem',
            width: '100%',
          },
        })}
      >
        Load more
      </Button>
    )
  );
};
