import { FC } from 'react';
import { Badge, IconButton } from '@mui/joy';
import { Notifications } from '@mui/icons-material';

export const ViewNotificationButton: FC = () => {
  return (
    <Badge
      size='sm'
      badgeInset='35%'
      sx={{
        '--Badge-minHeight': '6px',
        '--Badge-paddingX': 0,
        '--Badge-ringSize': '1px',
        '--Badge-ringColor': '#2A3648',
      }}>
      <IconButton
        size='sm'
        sx={{
          height: 'min-content',
        }}>
        <Notifications
          sx={({ palette }) => ({
            fontSize: 20,
            color: palette.common.white,
          })}
        />
      </IconButton>
    </Badge>
  );
};
