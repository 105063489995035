import { FC } from 'react';
import {
  Link as JoyLink,
  LinkProps as JoyLinkProps
} from '@mui/joy';

export interface LinkProps extends Pick<
  JoyLinkProps,
  'color'
  | 'children'
  | 'underline'
  | 'href'
  | 'target'
  | 'onClick'
  | 'sx'
> {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'neutral' | 'success';
  disabled?: boolean;
};

export const Link: FC<LinkProps> = ({ children, ...props }: LinkProps) => (
  <JoyLink {...props}>{children}</JoyLink>
);