import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/joy';
import { isEmpty, useMedia } from '@shared/lib';
import { Pagination } from '@mui/material';
import { DatePicker, useToaster } from '@shared/ui';
import { formatDate } from '@shared/lib';
import {
  selectToggledCurrencyCode,
  useSessionSelector
} from '@entities/session';
import {
  useGetTransactionsQuery,
  useTransactionsSelector,
  selectTransactions,
  selectPagination,
  moveToPage,
  resetPagination,
  TransactionTable
} from '@entities/transactions';
import {
  selectStartDate,
  selectEndDate,
  selectCurrentTransactionType,
  useTransactionListSelector,
  setStartDate,
  setEndDate,
} from '../model';
import {
  TransactionTypeDropdown
} from './transaction-type-dropdown.component';
import dayjs, { Dayjs } from 'dayjs';

type DateType = 'start' | 'end';

export const TransactionList: FC = () => {
  const isMobile = useMedia(`(max-width: ${912}px)`);
  const toaster = useToaster();
  const dispatch = useDispatch();

  const currencyCode = useSessionSelector(selectToggledCurrencyCode);
  const pagination = useTransactionsSelector(selectPagination);
  const transactions = useTransactionsSelector(selectTransactions);
  const currentTransactionType = useTransactionListSelector(selectCurrentTransactionType);

  const startDate = useTransactionListSelector(selectStartDate);
  const endDate = useTransactionListSelector(selectEndDate);

  const { isFetching, refetch } = useGetTransactionsQuery({
    tranType: currentTransactionType,
    startDateTime: `${formatDate(dayjs(startDate), 'YYYY-MM-DD')}T12:00:00Z`,
    endDateTime: `${formatDate(dayjs(endDate), 'YYYY-MM-DD')}T12:00:00Z`,
    pageNum: pagination.page,
    pageSize: pagination.pageSize,
  });

  useEffect(() => {
    refetch();
  }, [
    pagination,
    currentTransactionType,
    currencyCode,
    refetch
  ]);

  useEffect(() => {
    dispatch(resetPagination());
  }, [
    dispatch
  ]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<any>, page: number) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(moveToPage(page));
    }, [
    dispatch
  ]);

  const handleTransactionTypeChange = (): void => {
    refetch();
  };

  const handleDateChange = (type: DateType, date: Maybe<Dayjs>): void => {
    if (!date) return;

    if (type === 'start' && date.isAfter(dayjs(endDate), 'day')) {
      return toaster.error({
        message: 'Start date must be before the end date',
        autoHideDuration: 2000
      });
    } else if (type === 'end' && date.isBefore(dayjs(startDate), 'day')) {
      return toaster.error({
        message: 'End date must be after the start date',
        autoHideDuration: 2000
      });
    }

    if (type === 'start') {
      dispatch(setStartDate(date.toDate()));
    } else if (type === 'end') {
      dispatch(setEndDate(date.toDate()));
    }
  };

  return (
    <Stack
      direction='column'
      width='100%'>
      <Stack
        direction='row'
        gap={1.5}
        sx={({ breakpoints }) => ({
          mb: 1.5,
          [breakpoints.down(1024)]: {
            flexDirection: 'column',
            mb: 3,
          },
          '& .MuiFormControl-root.MuiFormControl-sizeMd': {
            height: 66,
            minHeight: 'unset',
          }
        })}>
        <TransactionTypeDropdown
          disabled={isFetching}
          onChange={handleTransactionTypeChange}
        />
        <DatePicker
          disabled={isFetching}
          label='Start Date'
          views={['year', 'month', 'day']}
          value={dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss[Z]')}
          onChange={newValue => handleDateChange('start', dayjs(newValue, 'YYYY-MM-DDTHH:mm:ss[Z]'))}
          sx={({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          })}
        />
        <DatePicker
          label='End Date'
          disabled={isFetching}
          views={['year', 'month', 'day']}
          value={dayjs(endDate, 'YYYY-MM-DDTHH:mm:ss[Z]')}
          onChange={newValue => handleDateChange('end', dayjs(newValue, 'YYYY-MM-DDTHH:mm:ss[Z]'))}
          sx={({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          })}
        />
      </Stack>
      <TransactionTable
        transactions={transactions}
        currencyCode={currencyCode}
      />
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '100%',
          marginBlockStart: 3,
        }}>
        {!isEmpty(pagination.total) && (
          <Pagination
            size={isMobile ? 'small' : 'medium'}
            count={pagination?.pageCount}
            page={pagination?.page}
            defaultPage={1}
            onChange={handlePageChange}
          />
        )}
      </Stack>
    </Stack>
  );
};