import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/joy';
import { CarouselSlider } from '@shared/ui';
import { useMedia } from '@shared/lib';
import { SignUpButton } from '@features/sign-up';
import { GamesByCategoriesBlock } from '@widgets/games-by-categories-block';
import { PlayInThreeSteps } from '@widgets/play-in-three-steps';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import { TournamentsList } from '@widgets/tournaments-list';
import {
  useHomePageSelector,
  selectTitlePlayInThreeSteps,
  selectStepsPlayInThreeSteps,
  selectMetaTagKeywords,
  selectMetaTagDescription,
  selectMetaTagCanonical,
  selectMetaTagTitle,
  selectSlider,
  selectHomePageGamesByCategoriesBlockTop,
  selectHomePageGamesByCategoriesBlockBottom,
  selectTournamentWidgetTitle,
} from '../model';
import { useGetHomePageQuery } from '../api';

export const Home: FC = () => {
  const isMobileView = useMedia(`(max-width: ${414}px)`);
  const { isFetching } = useGetHomePageQuery();

  const title = useHomePageSelector(selectMetaTagTitle);
  const keywords = useHomePageSelector(selectMetaTagKeywords);
  const description = useHomePageSelector(selectMetaTagDescription);
  const canonical = useHomePageSelector(selectMetaTagCanonical);
  const titlePlayInThreeSteps = useHomePageSelector(selectTitlePlayInThreeSteps);
  const stepsPlayInThreeSteps = useHomePageSelector(selectStepsPlayInThreeSteps);
  const gamesByCategoriesBlockTop = useHomePageSelector(selectHomePageGamesByCategoriesBlockTop);
  const gamesByCategoriesBlockBottom = useHomePageSelector(selectHomePageGamesByCategoriesBlockBottom);
  const tournamentWidgetTitle = useHomePageSelector(selectTournamentWidgetTitle);
  const slider = useSelector(selectSlider);

  return (
    <>
      {isFetching && (
        <MetaTagsBlock
          title={title}
          description={description}
          keywords={keywords}
          canonical={canonical}
        />
      )}
      <Stack
        direction='column'
        gap={isMobileView ? 6 : 8}
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockEnd: 8,
          [breakpoints.down(1350)]: {
            paddingInline: 2,
          },
          [breakpoints.down(414)]: {
            paddingBlockEnd: 3,
          },
        })}>
        {slider && (
          <CarouselSlider items={slider} />
        )}
        {gamesByCategoriesBlockTop && (
          <GamesByCategoriesBlock
            categoriesIds={gamesByCategoriesBlockTop}
          />
        )}
        <TournamentsList title={tournamentWidgetTitle} />
        {titlePlayInThreeSteps && stepsPlayInThreeSteps && (
          <PlayInThreeSteps
            title={titlePlayInThreeSteps}
            steps={stepsPlayInThreeSteps}
            actionSlot={(
              <SignUpButton
                sx={({ breakpoints }) => ({
                  [breakpoints.down(491)]: {
                    width: '100%'
                  }
                })}>
                Sign up
              </SignUpButton>
            )}
            loading={isFetching}
          />
        )}
        {gamesByCategoriesBlockBottom && (
          <GamesByCategoriesBlock
            categoriesIds={gamesByCategoriesBlockBottom}
          />
        )}
      </Stack>
    </>
  );
};
