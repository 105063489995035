import { isEmpty, mapper } from '@shared/lib';
import {
  type DynamicPageCarouselSliderItem,
  type PromotionContent,
  type HowItWorksBlockItem,
  type FaqBlockItem
} from '../model';

type MapPromotionsSliderItemAction = (data: any) => {
  text: string;
  slug: Url;
};
const mapPromotionSliderItemAction: MapPromotionsSliderItemAction =
  mapper([
    { name: 'text', from: 'text' },
    { name: 'url', from: 'url' },
    { name: 'userState', from: 'userState' },
  ]);

type MapPromotionCarouselSliderItem = (data: any) => DynamicPageCarouselSliderItem;
const mapPromotionCarouselSliderItem: MapPromotionCarouselSliderItem = mapper([
  { name: 'id', from: 'id' },
  {
    name: 'actions',
    from: 'actions',
    transform: (actionData: Array<any>) =>
      actionData?.map(mapPromotionSliderItemAction),
  },
  {
    name: 'variant1ImgSrc',
    from: 'variant1ImgSrc.data.attributes.url',
  },
  {
    name: 'variant2ImgSrc',
    from: 'variant2ImgSrc.data.attributes.url',
  },
  { name: 'bannerUrl', from: 'bannerUrl' },
]);

type MapHowItWorksBlockItem = (data: any) => HowItWorksBlockItem;
const mapHowItWorksBlockItem: MapHowItWorksBlockItem = mapper([
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
  { name: 'details', from: 'details' },
]);

type MapFaqBlockItem = (data: any) => FaqBlockItem;
const mapFaqBlockItem: MapFaqBlockItem = mapper([
  { name: 'summary', from: 'summary' },
  { name: 'details', from: 'details' },
]);

export type MapPromotionContent = (data: any) => PromotionContent;
export const mapPromotionContent: MapPromotionContent = mapper([
  {
    name: 'slider',
    from: 'content',
    transform: (contentVariantsData: Array<{ carouselSlider: Array<any> }>) => {
      const content = contentVariantsData?.[0];
      if (isEmpty(content)) return null;
      const carouselSlider = content?.carouselSlider?.map(
        (carouselSliderItem: any) => mapPromotionCarouselSliderItem(carouselSliderItem)
      );
      return carouselSlider;
    }
  },
  {
    name: 'howItWorksBlock',
    from: 'content',
    transform: (contentVariantsData: Array<{ howItWorksBlockTitle: any, howItWorksBlock: Array<any> }>) => {
      const content = contentVariantsData?.[0];
      if (isEmpty(content)) return null;
      const title = content?.howItWorksBlockTitle;
      const items = content?.howItWorksBlock?.map(
        (boxedFaqBlockItemData: any) => mapHowItWorksBlockItem(boxedFaqBlockItemData)
      );
      return { title, items };
    }
  },
  {
    name: 'faqBlock',
    from: 'content',
    transform: (contentVariantsData: Array<{ faqBlock: Array<any> }>) => {
      const content = contentVariantsData?.[0];
      if (isEmpty(content)) return null;
      const items = content?.faqBlock?.map(
        (faqBlockItemData) => mapFaqBlockItem(faqBlockItemData)
      );
      return { items };
    }
  },
]);