import { FC } from 'react';
import { Box, Skeleton, Stack } from '@mui/joy';

export const StepLoader: FC = () => {
  return (
    <Box
      sx={({ breakpoints }) => ({
        width: '33.33%',
        maxWidth: '326px',
        position: 'relative',
        [breakpoints.down(912)]: {
          width: '100%',
        },
      })}
    >
      <Stack direction='row' gap='8px' mb='4px' alignItems='center' justifyContent='center'>
        <Skeleton height={40} width={40} variant='circular' />
        <Skeleton variant='text' width={30} />
      </Stack>
      <Stack
        sx={({ breakpoints }) => ({
          alignItems: 'center',
          [breakpoints.down(490)]: {
            alignItems: 'flex-start',
          },
        })}
      >
        <Skeleton variant='text' width='95%' />
        <Skeleton variant='text' width='75%' />
        <Skeleton variant='text' width='90%' />
        <Skeleton variant='text' width='80%' />
      </Stack>
    </Box>
  );
};
