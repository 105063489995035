import {
  ReactNode,
  Ref,
  RefAttributes,
  forwardRef,
  FocusEvent
} from 'react';
import { Dayjs } from 'dayjs';
import { InputProps, useTheme } from '@mui/joy';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DesktopDatePicker as MuiDatePicker,
  DesktopDatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers/DesktopDatePicker';
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from '@mui/x-date-pickers/DateField';
import {
  DateFieldSlotsComponent,
  DateFieldSlotsComponentsProps,
} from '@mui/x-date-pickers/DateField/DateField.types';
import { useClearableField } from '@mui/x-date-pickers/hooks';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';

import { TextInput, TextInputProps } from '@shared/ui';

type JoyFieldProps = TextInputProps & {
  label?: ReactNode;
  errorMessage?: Maybe<string>;
  InputProps?: {
    ref?: Ref<any>;
    endAdornment?: ReactNode;
    startAdornment?: ReactNode;
  };
  formControlSx?: InputProps['sx'];
}

type JoyFieldComponent = ((
  props: JoyFieldProps & RefAttributes<HTMLDivElement>,
) => JSX.Element) & { propTypes?: any };

const JoyField = forwardRef(
  (props: JoyFieldProps, ref: Ref<HTMLDivElement>) => {
    const {
      disabled,
      id,
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      formControlSx,
      endDecorator,
      startDecorator,
      slotProps,
      errorMessage,
      error,
      onBlur,
      ...other
    } = props;
    return (
      <TextInput
        id={id}
        ref={ref}
        label={label}
        formControlSx={props?.formControlSx}
        disabled={disabled}
        error={errorMessage ?? ''}
        startDecorator={startDecorator && (
          <>
            {startAdornment}
            {startDecorator}
          </>
        )}
        endDecorator={
          <>
            {endAdornment}
            {endDecorator}
          </>
        }
        onBlur={onBlur}
        slotProps={{
          ...slotProps,
          root: { ...slotProps?.root, ref: containerRef },
        }}
        {...other}
      />
    );
  },
) as JoyFieldComponent;

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > { }

const JoyDateField = forwardRef(
  (props: JoyDateFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      inputRef: externalInputRef,
      slots,
      slotProps,
      ...textFieldProps
    } = props;

    const {
      onClear,
      clearable,
      ref: inputRef,
      ...fieldProps
    } = useDateField<Dayjs, typeof textFieldProps>({
      props: textFieldProps,
      inputRef: externalInputRef,
    });

    const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
      useClearableField<
        {},
        typeof textFieldProps.InputProps,
        DateFieldSlotsComponent,
        DateFieldSlotsComponentsProps<Dayjs>
      >({
        onClear,
        clearable,
        fieldProps,
        InputProps: fieldProps.InputProps,
        slots,
        slotProps,
      });

    return (
      <JoyField
        ref={ref}
        slotProps={{
          input: {
            ref: inputRef,
          },
        }}
        {...processedFieldProps}
        InputProps={ProcessedInputProps}
      />
    );
  },
);

interface DatePickerProps extends MuiDatePickerProps<Dayjs> {
  errorMessage?: string;
  onBlur?: (event: React.FocusEvent<Element>) => void;
}

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  errorMessage?: string;
  onBlur?: (event: FocusEvent<Element>) => void;
}

export const DatePicker = forwardRef(
  (props: DatePickerProps, ref: React.Ref<HTMLDivElement>) => {
    const { errorMessage, onBlur, onChange, ...datePickerProps } = props;
    const { palette } = useTheme()

    const handleChange = (newValue: any, pickerChangeContext: any = {}) => {
      const isValidDate = newValue && !isNaN(newValue);
      if (isValidDate) {
        onChange?.(newValue?.toISOString(), pickerChangeContext as any);
      } else {
        onChange?.('' as any, pickerChangeContext as any);
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          ref={ref}
          {...datePickerProps}
          onChange={handleChange}
          slots={{
            field: JoyDateField,
            ...datePickerProps.slots,
          }}
          slotProps={{
            desktopPaper: {
              sx: {
                borderRadius: 3,
                border: 'none',
                background: palette.background.body,
                boxShadow: `0px 0px 16px 0px ${palette.common[300]}`,
                borderColor: palette.common[475],
                borderWidth: 1,
              }
            },
            layout: {
              sx: {
                '& .MuiPickersCalendarHeader-label': {
                  color: palette.common.white,
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  color: palette.common.white,
                },
                'button': {
                  color: palette.common[150],
                  '&.Mui-disabled': {
                    color: `${palette.common[1150]} !important`
                  },
                  '&:hover': {
                    color: palette.common.white,
                    backgroundColor: palette.common[485],
                  },
                  '&.Mui-selected': {
                    color: palette.common.white,
                    backgroundColor: palette.common[495],
                  },
                  '&.Mui-selected.Mui-disabled': {
                    color: `${palette.common.white} !important`,
                    backgroundColor: `${palette.common[495]} !important`,
                  },
                  '&.Mui-selected:focus': {
                    color: palette.common.white,
                    backgroundColor: palette.common[495],
                  },
                }
              }
            },
            calendarHeader: {
              sx: {
                color: palette.common.white,
              }
            },
            rightArrowIcon: {
              sx: {
                color: palette.common[150]
              }
            },
            leftArrowIcon: {
              sx: {
                color: palette.common[150]
              }
            },
            switchViewButton: {
              sx: {
                color: palette.common[150]
              }
            },
            openPickerButton: {
              sx: {
                color: palette.common[150],
              }
            },
            clearButton: {
              sx: {
                color: palette.common[150]
              }
            },
            ...datePickerProps.slotProps,
            field: {
              ...datePickerProps.slotProps?.field,
              errorMessage,
              onBlur,
            } as any,
          }}
        />
      </LocalizationProvider>
    );
  },
);
