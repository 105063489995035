import { FC, useMemo } from 'react';
import { Stack } from '@mui/joy';
import { useMedia } from '@shared/lib';
import { selectGameCategories, useGamesSelector, type GameCategory } from '@entities/games';
import { GamesCategoryItem } from './games-category-item.component';

type GamesByCategoriesBlockProps = {
  categoriesIds: Maybe<Array<number>>;
};

export const GamesByCategoriesBlock: FC<GamesByCategoriesBlockProps> = ({ categoriesIds }) => {
  const isMobile = useMedia(`(max-width: ${490}px)`);
  const categoriesInStore = useGamesSelector(selectGameCategories);

  const categoriesToShow = useMemo(() => {
    if (!categoriesIds) {
      return [];
    }
    return categoriesIds?.map(
      (categoryId: number) =>
        categoriesInStore?.find((categoryInStore: GameCategory) => categoryInStore?.id === categoryId)!
    );
  }, [categoriesInStore, categoriesIds]);

  return (
    <Stack direction='column' gap={isMobile ? 4 : 5.25}>
      {categoriesToShow.map((category: GameCategory, index: number) => (
        <GamesCategoryItem key={index} category={category}></GamesCategoryItem>
      ))}
    </Stack>
  );
};
