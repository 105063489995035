import { Tournament } from '../model';

type MapTournament = (data: any) => Tournament;

export const mapTournament: MapTournament = (data: any): Tournament => {
  let description: any = {};
  try {
    description = JSON.parse(data.description);
  } catch (e: any) {
    console.error(`Error parsing description: ${e.message}`);
  }

  return {
    name: data.name,
    endDate: data.endDate,
    startDate: data.startDate,
    status: data.status,
    description:
      description?.description ?? 'Join the thrilling SweepLuxe race.',
    imageSrc: description?.imageSrc ?? '',
    prizeGC: description?.prizeGC,
    prizeSC: description?.prizeSC,
    buttonText: description?.buttonText ?? 'Play now',
    buttonUrl: description?.buttonUrl ?? '/games/',
  };
};
