import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import { Box } from '@mui/joy';
import { isEmpty, useMedia } from '@shared/lib';
import { ActionButton, StyledSwiper } from './styled';
import { SlideImage } from './slide-image.component';

export type Banner = {
  id: number;
  variant1ImgSrc: Url;
  variant2ImgSrc: Url;
  action: Maybe<{
    url: Url;
    text: string;
  }>;
  bannerUrl?: Url;
};

export type CarouselSlideProps = {
  items: Banner[];
};

const pagination = {
  clickable: true,
  renderBullet: (_: number, className: string) => `<span class='${className}'></span>`,
};

export const CarouselSlider: FC<CarouselSlideProps> = ({ items }) => {
  const isMobile = useMedia(`(max-width: ${768}px)`);
  return (
    !isEmpty(items) && (
      <StyledSwiper
        pagination={pagination}
        modules={[Pagination]}>
        {items?.map((item) => (
          <SwiperSlide key={item.id}>
            <Box
              to={item.bannerUrl}
              component={
                item.bannerUrl
                  ? Link
                  : 'div'
              }>
              <SlideImage
                src={
                  isMobile
                    ? item.variant1ImgSrc
                    : item.variant2ImgSrc
                }
              />
            </Box>
            {item?.action && (
              <ActionButton
                size='lg'
                variant='solid'
                color='success'
                href={item.action.url}>
                {item.action.text}
              </ActionButton>
            )}
          </SwiperSlide>
        ))}
      </StyledSwiper>
    )
  );
};
