import { mapper, mapTextWithInlineLink } from '@shared/lib';
import { type PageFooter } from '../model';
import { mapPageFooterNavigationItem } from './map-page-footer-navigation-item';
import { mapLogo } from './map-logo';

type MapPageFooter = (data: any) => PageFooter;
export const mapPageFooter: MapPageFooter = mapper([
  { name: 'promotionLegalNote', from: 'promotionLegalNote' },
  {
    name: 'logo',
    from: 'logo',
    transform: (logoData: any) => mapLogo(logoData),
  },
  {
    name: 'textWithInlineLink',
    from: 'textWithInlineLink',
    transform: (textWithInlineLinkData: any) =>
      textWithInlineLinkData && mapTextWithInlineLink(textWithInlineLinkData),
  },
  {
    name: 'navigationItems',
    from: 'navigationItems',
    transform: (navigationItemsData: any[]) => navigationItemsData?.map(mapPageFooterNavigationItem),
  }
]);