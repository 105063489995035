import { mapper } from '@shared/lib';
import dayjs from 'dayjs';

type FullProfileToUpdateInternal = {
  firstName: string;
  lastName: string;
  nickname: string;
  email: Email;
  phone: Phone;
  birthDate: string;
  address: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  registrationStatus: string;
  kycStatus: string;
};

type MapFullProfileToUpdateInternal = (data: any) => FullProfileToUpdateInternal;
export const mapFullProfileToUpdateInternal: MapFullProfileToUpdateInternal = mapper([
  { name: 'firstName', from: 'firstName' },
  { name: 'lastName', from: 'lastName' },
  { name: 'nickname', from: 'nickname' },
  { name: 'email', from: 'email' },
  { name: 'phone', from: 'phone' },
  {
    name: 'birthDate',
    from: 'birthDate',
    transform: (birthDate: Maybe<Date>) => {
      return dayjs(birthDate).format('YYYY-MM-DD');
    },
  },
  { name: 'address', from: 'address' },
  { name: 'country', from: 'country' },
  { name: 'province', from: 'province' },
  { name: 'city', from: 'city' },
  { name: 'postalCode', from: 'postalCode' },
  { name: 'kycStatus', from: 'kycStatus' },
  { name: 'registrationStatus', from: 'registrationStatus' },
]);

export type FullProfileToUpdate = {
  firstName: string;
  lastName: string;
  nickname: string;
  email?: Email;
  mobile?: Phone;
  birthDate: string;
  address: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  registrationStatus: string;
  kycStatus: string;
};

type MapFullProfileToUpdate = (data: any) => FullProfileToUpdate;
export const mapFullProfileToUpdate: MapFullProfileToUpdate = (data: any): FullProfileToUpdate => {
  const { email, phone, ...otherProps } = mapFullProfileToUpdateInternal(data);
  if (email) return ({ ...otherProps, mobile: phone });
  return ({ ...otherProps, email });
};