import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { Stack } from '@mui/joy';
import { GameList } from '@widgets/game-list';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import {
  selectIsLoading,
  selectMetaTagCanonical,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagTitle,
  useGamesPageSelector,
} from '../model';
import { fetchGamesPage } from '../api';

export const Games: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isLoading = useGamesPageSelector(selectIsLoading);
  const keywords = useGamesPageSelector(selectMetaTagKeywords);
  const title = useGamesPageSelector(selectMetaTagTitle);
  const description = useGamesPageSelector(selectMetaTagDescription);
  const canonical = useGamesPageSelector(selectMetaTagCanonical);

  const getPageData = useCallback(async () => {
    await dispatch(fetchGamesPage())
  }, [dispatch])

  useEffect(() => {
    getPageData()
  }, [getPageData])


  return !isLoading && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          paddingBlock: '3rem 4rem',
          maxWidth: '82rem',
          width: '100%',
          height: '100%',
          paddingInline: '1rem',
          alignItems: 'center',
          [breakpoints.down(912)]: {
            paddingBlock: '1.5rem',
          },
          [breakpoints.down(768)]: {
            paddingInline: '1rem',
          },
        })}>
        <GameList />
      </Stack>
    </>
  );
};
