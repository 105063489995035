import { FC } from 'react';
import { sanitize } from 'dompurify';
import { Box, Stack, Typography } from '@mui/joy';

export type ContactBlockProps = {
  title: Maybe<string>;
  html: any;
}

export const ContactBlock: FC<ContactBlockProps> = ({title, html}) => {
  return (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 5,
        paddingBlockEnd: 8,
        gap: 8,
        [breakpoints.down(1350)]: {
          paddingInline: 2,
        },
        [breakpoints.down(769)]: {
          paddingBlockStart: 0,
          paddingBlockEnd: 3,
          gap: 6,
        },
      })}>
      <Stack
        direction='column'
        gap={1.5}>
        {title && (
          <Typography sx={({ colorSchemes,breakpoints}) => ({
            width: '100%',
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '36x',
            letterSpacing: 0,
            textAlign: 'start',
            color: colorSchemes.dark.palette.common.white,
            [breakpoints.down(490)]: {
              fontSize: 20,
              lineHeight: '30px',
            }
          })}>
            {title}
          </Typography>
        )}
        {html && (
          <Box
            component='div'
            sx={{
              '& strong': {
                fontWeight: 'bold'
              }
            }}
            dangerouslySetInnerHTML={{
              __html: sanitize(html)
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};