import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemStyleObject } from '@mui/system';
import { Box, Button, Stack, Typography, Divider } from '@mui/joy';
import { useCountdown } from '@shared/lib';
import { Tournament, TournamentStatus } from '../model';

type TournamentsListItemProps = {
  tournament: Tournament;
  sx?: SystemStyleObject;
};

export const TournamentsListItem: FC<TournamentsListItemProps> = ({
  tournament,
  sx,
}) => {
  const navigate = useNavigate();

  const timeArray = useCountdown(tournament.status === TournamentStatus.Active
    ? tournament.endDate
    : tournament.startDate);

  const prizeGCString: string = useMemo(() => {
    return tournament.prizeGC
      ? `${tournament.prizeGC} GC ${tournament.prizeSC ? ' + ' : ''}`
      : '';
  }, [tournament]);

  const prizeSCString: string = useMemo(() => {
    return tournament.prizeSC ? `${tournament.prizeSC} SC` : '';
  }, [tournament]);

  const handleButtonClick = (): void => {
    navigate(tournament.buttonUrl);
  }

  return (
    <Stack
      sx={({palette}) => ({
        maxWidth: 410,
        width: '100%',
        gap: 2,
        paddingBlock: 3,
        paddingInline: 2,
        background: palette.common[475],
        borderRadius: 8,
        position: 'relative',
        boxSizing: 'border-box',
        ...sx,
      })}>
      <Box
        sx={({palette}) => ({
          background: tournament.status === TournamentStatus.Active
            ? palette.common[825]
            : palette.common[1060],
          position: 'absolute',
          top: 0,
          left: 0,
          paddingBlock: 0.5,
          paddingInline: 2.5,
          borderEndEndRadius: 8,
          borderTopLeftRadius: 8,
        })}>
        <Typography
          sx={({palette}) => ({
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '18px',
            color: palette.common.white,
          })}>
          {tournament.status === TournamentStatus.Active ? 'Live' : 'Upcoming'}
        </Typography>
      </Box>

      <Stack gap={1}>
        <Typography
          sx={({palette}) => ({
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
            color: palette.common.white,
          })}>
          {tournament.name}
        </Typography>

        <Typography
          sx={({palette, breakpoints}) => ({
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: palette.common[150],
            [breakpoints.down(912)]: {
              fontSize: 14,
              lineHeight: '20px',  
            }
          })}>
          {tournament.description}
        </Typography>
      </Stack>

      <Stack gap={1}>
        <Box
          component='img'
          src={tournament.imageSrc}
          sx={{
            width: '100%',
            aspectRatio: 1.5,
            borderRadius: 8,
          }}/>

        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={({palette}) => ({
            paddingInline: 2,
            paddingBlock: 1,
            gap: 1,
            border: '1px solid',
            borderColor: palette.common[925],
            borderRadius: 8,
            background: palette.common[900],
          })}>
          <Box
            component='img'
            src='/assets/png/giftBlueBoxRedStripe.png'
            sx={{
              width: 22,
              height: 28,
            }}
          />

          {(prizeGCString || prizeSCString) && (
            <Typography sx={({palette}) => ({
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              color: palette.common.white,
              'span': {
                color: palette.common[1075],
              }
            })}>
              {prizeGCString}<span>{prizeSCString}</span>
            </Typography>)}
        </Stack>

        <Stack
          gap={0.5}
          alignItems='center'>
          <Typography
            sx={({palette}) => ({
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '18px',
              color: palette.common.white,
            })}>
            {tournament.status === TournamentStatus.Active ? 'Ends in' : 'Starts in'}
          </Typography>

          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr auto 1fr auto 1fr',
              gap: 1.5,
            }}>
            <Stack alignItems='center'>
              <Typography
                sx={({palette}) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[0] : 0}
              </Typography>

              <Typography
                sx={({palette}) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Day
              </Typography>
            </Stack>

            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}/>

            <Stack alignItems='center'>
              <Typography
                sx={({palette}) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[1] : 0}
              </Typography>

              <Typography
                sx={({palette}) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Hour
              </Typography>
            </Stack>

            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}/>

            <Stack alignItems='center'>
              <Typography
                sx={({palette}) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[2] : 0}
              </Typography>

              <Typography
                sx={({palette}) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Min
              </Typography>
            </Stack>

            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}/>

            <Stack alignItems='center'>
              <Typography
                sx={({palette}) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[3] : 0}
              </Typography>

              <Typography
                sx={({palette}) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Sec
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Button onClick={handleButtonClick}>
          {tournament.buttonText}
          </Button>
      </Stack>
    </Stack>
  );
};
