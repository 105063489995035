import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatDate } from '@shared/lib';
import { TransactionTypeEnum } from '@entities/transactions';
import { type TransactionListState } from './types';

const initialState: TransactionListState = {
  currentTransactionType: TransactionTypeEnum.Deposit,
  startDate: formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss[Z]'),
  endDate: formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss[Z]'),
};

export const transactionListSlice = createSlice({
  name: 'transaction-list',
  initialState,
  reducers: {
    resetTransactionList: (state: TransactionListState) => {
      Object.assign(state, initialState);
    },
    setCurrentTransactionType: (state: TransactionListState, action: PayloadAction<TransactionTypeEnum>) => {
      state.currentTransactionType = action.payload;
    },
    setStartDate: (state: TransactionListState, action: PayloadAction<Date>) => {
      state.startDate = formatDate(action.payload, 'YYYY-MM-DDTHH:mm:ss[Z]');
    },
    setEndDate: (state: TransactionListState, action: PayloadAction<Date>) => {
      state.endDate = formatDate(action.payload, 'YYYY-MM-DDTHH:mm:ss[Z]');
    },
  }
});

export const {
  setStartDate,
  setEndDate,
  setCurrentTransactionType,
  resetTransactionList,
} = transactionListSlice.actions;
export const transactionListReducer = transactionListSlice.reducer;
