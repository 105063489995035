import { ComponentType } from 'react';
import { SocketProvider } from '@shared/api';

export const withSocket =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) => {
    return (
      <SocketProvider>
        <WrappedComponent {...props} />
      </SocketProvider>
    );
  };
