import { Swiper } from 'swiper/react';
import { alpha } from '@mui/system';
import { styled } from '@mui/joy';

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(768)]: {
    aspectRatio: '36/13',
    '.swiper-pagination-bullet': {
      height: '1rem',
      width: '1rem',
      backgroundColor: alpha(theme.colorSchemes.dark.palette.common.white, 0.4),
    },
  },

  [theme.breakpoints.down(768)]: {
    aspectRatio: '179/130',
    '.swiper-pagination-bullet': {
      height: '0.625rem',
      width: '0.625rem',
    },
    borderRadius: '0.5rem',
  },
  '.swiper-pagination-bullet': {
    backgroundColor: alpha(theme.colorSchemes.dark.palette.common.white, 0.4),
  },

  '.swiper-pagination-bullet-active': {
    backgroundColor: theme.colorSchemes.dark.palette.common.white,
  },
}));
