import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { TournamentStatus, type Tournament } from '../model';
import { tournamentsApiSlice } from './slice';

export const fetchTournaments = createAsyncThunk<Array<Tournament>, boolean>(
  'fetch-tournaments',
  async (isAuth: boolean, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      dayjs.extend(utc);
      const activeTournaments = await dispatch(
        (isAuth
          ? tournamentsApiSlice.endpoints.getPrivateTournaments
          : tournamentsApiSlice.endpoints.getPublicTournaments
        ).initiate(
          {
            status: TournamentStatus.Active,
            endDate: dayjs().add(6, 'days').format('YYYY-MM-DD'),
          }
        )
      ).unwrap();

      const { status: activeTournamentsStatus } =
        activeTournaments as OmegaApiResponse;
      if (activeTournamentsStatus === OmegaApiResponseStatusEnum.Success) {
        return rejectWithValue(new Error('Fail to fetch tournaments'));
      }

      const pendingTournaments = await dispatch(
        (isAuth
          ? tournamentsApiSlice.endpoints.getPrivateTournaments
          : tournamentsApiSlice.endpoints.getPublicTournaments
        ).initiate(
          {
            status: TournamentStatus.Pending,
            endDate: dayjs().add(6, 'days').format('YYYY-MM-DD'),
          }
        )
      ).unwrap();

      const { status: pendingTournamentsStatus } =
        pendingTournaments as OmegaApiResponse;
      if (pendingTournamentsStatus === OmegaApiResponseStatusEnum.Success) {
        return rejectWithValue(new Error('Fail to fetch tournaments'));
      }

      return fulfillWithValue([...activeTournaments, ...pendingTournaments]);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
