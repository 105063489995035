import { FC } from 'react'
import { Link } from '@mui/joy';
import { useModalQueryParam } from '@shared/lib';
import { ResetPasswordModalsQueries } from '../api';

export const ResetPasswordLink: FC = () => {
  const { toggleModal } = useModalQueryParam();

  const handleForgotPassword = () => {
    toggleModal('login', ResetPasswordModalsQueries.SentResetPassword)
  }

  return <Link
    color='neutral'
    underline='none'
    sx={{ fontSize: 14, height: 20 }}
    onClick={handleForgotPassword}>
    Forgot password
  </Link>
};