import { baseApi, baseOmegaApi, GAMES_LIST_TAG } from '@shared/api';
import { type GamesState, type FavoriteGame } from '../model';
import { mapGameCategories } from '../lib';

export const gamesListApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGamesCategories: build.query<Pick<GamesState, 'categories'>, void>({
      query: () => ({ url: '/api/game-categories' }),
      extraOptions: { showLoader: true },
      transformResponse: (response: any) => mapGameCategories(response),
      providesTags: [GAMES_LIST_TAG],
    }),
  }),
});

export const { useGetGamesCategoriesQuery } = gamesListApiSlice;

export const favoriteGamesApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getMyFavoriteGamesFromOmega: build.query<any, void>({
      query: () => ({ url: 'ips/getFavoriteGames' }),
      extraOptions: { auth: true, showLoader: true },
    }),
    addMyFavoriteGameToOmega: build.query<any, FavoriteGame>({
      query: (params: FavoriteGame) => ({ url: 'ips/addFavoriteGame', params }),
      extraOptions: { auth: true, showLoader: true },
    }),
    removeMyFavoriteGameFromOmega: build.query<any, FavoriteGame>({
      query: (params: FavoriteGame) => ({ url: 'ips/removeFavoriteGame', params }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});

export const {
  useLazyGetMyFavoriteGamesFromOmegaQuery,
  useLazyAddMyFavoriteGameToOmegaQuery,
  useLazyRemoveMyFavoriteGameFromOmegaQuery,
} = favoriteGamesApiSlice;

export const favoriteGamesApiReducer = favoriteGamesApiSlice.reducer;
