import { FC, ReactElement, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Box, Theme } from '@mui/joy';
import { SystemStyleObject } from '@mui/system';
import { isMobileDevice } from '@shared/lib';
import { GameItemLabelEnum, type GamesItem } from '@entities/games';
import { GameLabel } from './game-label.component';
import { GameListItemDetails } from './game-list-item-details.component';
import { GameRibbon } from './game-ribbon.component';

export type GameListItemProps = {
  game: GamesItem;
  favoriteSwitcherSlot: ReactElement;
  launchGameSlot: ReactElement;
  bonusFeature: boolean;
  volatility: string;
  rtp: string;
  type?: GameItemLabelEnum;
  // TODO: fix sx
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
};

export const GameListItem: FC<GameListItemProps> = ({
  game,
  favoriteSwitcherSlot,
  launchGameSlot,
  type,
  sx: styledSx,
}) => {
  const touchDevice = isMobileDevice();

  const [showDetails, setShowDetails] = useState(false);

  const handleHoverShowDetails = () => {
    if (touchDevice) return;
    setShowDetails(true);
  };

  const handleClickShowDetails = () => {
    if (!touchDevice) return;
    setShowDetails(true);
  };

  const handleCloseShowDetails = () => {
    setShowDetails(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseShowDetails}>
      <Box
        onMouseEnter={handleHoverShowDetails}
        onMouseLeave={handleCloseShowDetails}
        onClick={handleClickShowDetails}
        position={'relative'}
        sx={[
          (theme) => ({
            borderRadius: '0.5rem',
            [theme.breakpoints.up(768)]: {
              width: '12.5rem',
              height: '9.5rem',
            },
            [theme.breakpoints.down(390)]: {
              width: '150px',
              height: '112.5px',
            },
            height: '7.5rem',
            width: '10.8125rem',
            background: `url(${game.bannerUrl}), lightgray 50% / cover no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }),
          styledSx ?? false,
        ]}
      >
        {game.ribbon &&  <GameRibbon ribbon={game.ribbon} />}
        {!!type && <GameLabel type={type} />}
        {showDetails && (
          <GameListItemDetails
            name={game.name}
            features={game?.features ?? ''}
            volatility={game?.volatility ?? ''}
            rtp={game?.rtp ?? ''}
            favoriteSwitcherSlot={favoriteSwitcherSlot}
            launchGameSlot={launchGameSlot}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
