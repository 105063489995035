import { Button } from '@mui/joy';
import { GoogleIcon } from '@shared/ui';

export const GoogleAuthButton = () => (
  <Button
    startDecorator={<GoogleIcon />}
    size='sm'
    variant='plain'
    sx={({ breakpoints, palette }) => ({
      backgroundColor: palette.common.white,
      color: palette.common[485],
      borderColor: palette.common[650],
      borderRadius: 8,
      paddingBlock: 1.25,
      fontSize: 16,
      fontWeight: 600,
      ':hover': { backgroundColor: palette.common[650] },
      ':active': { backgroundColor: palette.common[137] },
      [breakpoints.down(490)]: {
        fontSize: 14,
        width: '100%'
      },
    })}>
    With Google
  </Button>
);