import {
  FC,
  useEffect,
  useId,
  useRef,
  useState
} from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Option,
  OptionProps,
  selectClasses,
  FormHelperText
} from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';

export type OptionType = {
  value: string;
  label: string;
};

export type DropdownProps = Maybe<{
  label?: Maybe<string>;
  options?: Array<OptionType>;
  value?: Maybe<string>;
  defaultValue?: Maybe<string>;
  placeholder?: Maybe<string>;
  error?: Maybe<string>;
  disabled?: Maybe<boolean>;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  inputProps?: Maybe<{
    root?: Maybe<FormControlProps>;
    label?: Maybe<FormLabelProps>;
    select?: Maybe<SelectProps<OptionType, false>>;
    option?: Maybe<OptionProps>;
  }>
}>;

export const Dropdown: FC<DropdownProps> = (props) => {
  const selectId = useId();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const formControlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formControlRef.current && !formControlRef.current.contains(event.target as Node)) {
        if (isFocused) {
          setIsFocused(false);
          props?.onBlur?.();

        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocused, props, props?.onBlur]);

  const handleChange = (event: any, newValue: Maybe<string>): void => {
    props?.onChange?.(newValue ?? '');
  };

  return (
    <FormControl
      ref={formControlRef}
      onFocus={() => setIsFocused(true)}
      disabled={props?.disabled ?? false}
      error={!!props?.error}
      sx={[{
        minHeight: 90
      },
      ...(props?.inputProps?.root
        && Array.isArray(props?.inputProps?.root?.sx)
        ? props?.inputProps?.root?.sx
        : [props?.inputProps?.root?.sx])
      ]}
      {...props?.inputProps?.root}>
      {props?.label && (
        <FormLabel
          htmlFor={selectId}
          sx={[
            { marginBottom: 0.5 },
            ...(props?.inputProps?.label
              && Array.isArray(props?.inputProps?.label?.sx)
              ? props?.inputProps?.label?.sx
              : [props?.inputProps?.label?.sx])
          ]}
          {...props?.inputProps?.label}>
          {props?.label}
        </FormLabel>
      )}
      <Select
        id={selectId}
        value={props?.value}
        placeholder={props?.placeholder}
        defaultValue={props?.defaultValue}
        disabled={props?.disabled ?? false}
        onChange={handleChange}
        indicator={<KeyboardArrowDown />}
        slotProps={{
          root: {
            sx: [({ palette }) => ({
              height: 40,
              color: palette.common.white,
              backgroundColor: palette.common[475],
              borderColor: `${palette.common[925]} !important`,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 8,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: palette.common[475],
              },
              ...(props?.error && ({
                borderColor: palette.common.error
              }))
            }),
            ...(props?.inputProps?.select
              && Array.isArray(props?.inputProps?.select?.sx)
              ? props?.inputProps?.select?.sx
              : [props?.inputProps?.select?.sx])
            ]
          },
          button: {
            'aria-labelledby': `${selectId}-label ${selectId}`,
          },
          listbox: {
            sx: ({ palette }) => ({
              zIndex: 99999,
              borderRadius: 12,
              border: 'none',
              background: palette.background.body,
              boxShadow: `0px 0px 16px 0px ${palette.common[300]}`,
              borderColor: palette.common[475],
              borderWidth: 1,
            })
          },
          indicator: {
            sx: {
              '& .MuiSvgIcon-root': {
                height: 24,
                width: 24
              }
            },
          }
        }}
        sx={{
          width: 240,
          [`& .${selectClasses.indicator}`]: {
            transition: '0.2s',
            [`&.${selectClasses.expanded}`]: {
              transform: 'rotate(-180deg)',
            },
          },
        }}>
        {props?.options?.map(
          ({ label, value }, index) => (
            <Option
              key={index}
              value={value}
              sx={[({ palette }) => ({
                '&.MuiOption-highlighted': {
                  backgroundColor: palette.common[475],
                },
                '&.MuiOption-root:hover': {
                  backgroundColor: palette.common[485],
                },
                '&.MuiOption-root:active': {
                  backgroundColor: palette.common[495],
                },
              }),
              ...(props?.inputProps?.option
                && Array.isArray(props?.inputProps?.option?.sx)
                ? props?.inputProps?.option?.sx
                : [props?.inputProps?.option?.sx])
              ]}>
              {label}
            </Option>
          ))}
      </Select>
      {props?.error && (
        <FormHelperText sx={{ marginTop: 0.5 }}>
          {props?.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};