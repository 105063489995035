import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlayArrowRounded } from '@mui/icons-material';
import { IconButton } from '@mui/joy';
import { selectIsAuthenticated } from '@entities/session';

export type LaunchGameProps = {
  id: number;
};

// TODO: refactoring needed
export const LaunchGame: FC<LaunchGameProps> = ({ id }) => {
  const navigate = useNavigate();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const isAuth = useSelector(selectIsAuthenticated);

  const handleOpenGame = () => {
    if (!isAuth) {
      urlSearchParams.append('login', 'true');
      setUrlSearchParams(urlSearchParams, { replace: true });
    } else {
      navigate(`/games/${id}`);
    }
  };

  return (
    <IconButton
      sx={(theme) => ({
        background: theme.colorSchemes.dark.palette.common[300],
        color: theme.colorSchemes.dark.palette.common.white,
        borderRadius: '50%',
        fontSize: '1.5rem',
        minWidth: '2rem',
        minHeight: '2rem',
      })}
      variant='solid'
      onClick={handleOpenGame}>
      <PlayArrowRounded />
    </IconButton>
  );
};
