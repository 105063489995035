import { createAsyncThunk } from '@reduxjs/toolkit';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { sessionApiSlicer } from './slice';
import { SignIn } from './types';

export const signInAndGetBalance = createAsyncThunk<any, any, any>(
  'session/signInAndFetchBalance',
  async (loginData: SignIn, { dispatch }) => {
    const signInResponse = await dispatch(sessionApiSlicer.endpoints.signIn.initiate(loginData)).unwrap();
    if (signInResponse.status === OmegaApiResponseStatusEnum.Success) {
      await dispatch(sessionApiSlicer.endpoints.balance.initiate(null));
      await dispatch(sessionApiSlicer.endpoints.getPlayerInfo.initiate());
    }
  }
);
