import { createAsyncThunk } from '@reduxjs/toolkit';
import { type GamesState, type GameCategory, gamesSlice } from '@entities/games';
import { type GetGamesByCategoryResponse, type GetGamesByCategory } from './types';

type RootState = {
  [gamesSlice.name]: GamesState;
};

export const fetchGamesByCategory = createAsyncThunk<GetGamesByCategoryResponse, GetGamesByCategory>(
  'gamesFilterByCategory/fetchGamesByCategory',
  async (payload: GetGamesByCategory, { dispatch, rejectWithValue, fulfillWithValue, getState }) => {
    const state = getState() as RootState;

    try {
      return fulfillWithValue({
        categoryId: payload.categoryId,
        page: payload.page,
        pageSize: payload.pageSize,
        games: state[gamesSlice.name].categories.find((category: GameCategory) => category.id === payload.categoryId)
          ?.games,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
