import { TextWithInlineLink, isEmpty, mapTextWithInlineLink, mapper } from '@shared/lib';
import { type ContactUsContent, type BoxedContactBlockItem } from '../model';

type MapBoxedContactBlockItem = (data: any) => BoxedContactBlockItem;
const mapBoxedContactBlockItem: MapBoxedContactBlockItem = mapper([
  { name: 'shortSummary', from: 'shortSummary' },
  { name: 'shortSummaryStyle', from: 'shortSummaryStyle' },
  {
    name: 'details',
    from: 'details',
    transform: (detailsData: TextWithInlineLink) => {
      const details = mapTextWithInlineLink(detailsData);
      return details;
    },
  },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
]);

export type MapContactUsContent = (data: any) => ContactUsContent;
export const mapContactUsContent: MapContactUsContent = mapper([
  {
    name: 'boxedContactBlock',
    from: 'content',
    transform: (contentData: Array<{ boxedContactBlockTitle: any; boxedContactBlock: Array<any> }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = content?.boxedContactBlockTitle;
      const items = content?.boxedContactBlock?.map((boxedContactBlockItemData: any) =>
        mapBoxedContactBlockItem(boxedContactBlockItemData)
      );
      return { title, items };
    },
  },
  { name: 'contactBlock', from: 'content' },
  {
    name: 'contactBlock',
    from: 'content',
    transform: (contentData: Array<{ contactBlockContent: string; contactBlockTitle: string }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const html = content?.contactBlockContent;
      const title = content?.contactBlockTitle;
      return { html, title };
    },
  },
]);
