import { ComponentType } from 'react';
import { Typography } from '@mui/joy';
import { Link } from '@shared/ui';
import {
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import { SingUpPromoBanner } from '@entities/page-layout';
import {
  SuccessConfirmationSignUpModal,
  ConfirmationSignUpModal,
  SignUpByEmailForm,
  SignUpByPhoneForm,
} from '@features/sign-up';
import { SocialLogin } from '@features/social-auth';
import { AuthModal } from '@widgets/auth-modal';

// eslint-disable-next-line operator-linebreak
export const withSignUpModal =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      const isOpenSignUpModal = useQueryParamModalVisibility('sign-up');
      const { toggleModal, closeModal } = useModalQueryParam();

      const handleClose = (): void => {
        closeModal('sign-up');
      };

      const handleSignIn = (): void => {
        toggleModal('sign-up', 'login');
      };

      return (
        <>
          <WrappedComponent {...props} />
          <AuthModal
            open={isOpenSignUpModal}
            onClose={handleClose}
            titleSlot={
              <Typography level='h3' fontSize='1.25rem'>
                Sign up
              </Typography>
            }
            headingSlot={<SocialLogin />}
            bannerSlot={<SingUpPromoBanner />}
            emailForm={<SignUpByEmailForm />}
            phoneForm={<SignUpByPhoneForm />}
            bottomSlot={
              <Typography
                textAlign='center'
                color='neutral'
                pb={4}>
                Already have an account?&nbsp;
                <Link
                  color='success'
                  underline='none'
                  onClick={handleSignIn}>
                  Log in
                </Link>
              </Typography>
            }
          />
          <SuccessConfirmationSignUpModal />
          <ConfirmationSignUpModal />
        </>
      );
    };
