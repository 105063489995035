import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Stack, Box, Skeleton, AspectRatio } from '@mui/joy';
import { IconButton } from '@shared/ui';
import {
  selectToggledCurrencyCode,
  selectCurrencySessionKey,
  selectIsAuthenticated,
  useLazyBalanceQuery
} from '@entities/session';
import { useLazyGetGameQuery } from '../api';
import { mapGameUrl } from '../lib';
import { GameIframe } from './game-iframe.component';

export const GameLaunchPage: FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currencySessionKey = useSelector(selectCurrencySessionKey);
  const currencyCode = useSelector(selectToggledCurrencyCode);

  const [getGame, { data: gameData, isFetching }] = useLazyGetGameQuery();
  const [getBalance] = useLazyBalanceQuery();

  const [gameUrl, setGameUrl] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState<boolean>(true);

  const fetchGameInfo = useCallback(async () => {
    const id = Number(params.id);
    if (!id) return;
    await getGame(id);
  }, [getGame, params?.id]);

  const handleLoad = () => {
    setIframeLoading(false);
  };

  const handleClose = () => {
    navigate('/games');
  };

  useLayoutEffect(() => {
    fetchGameInfo();
    return () => {
      if (isAuthenticated) getBalance();
    };
  }, [fetchGameInfo, getBalance, isAuthenticated]);

  useEffect(() => {
    if (gameData && currencySessionKey) {
      setIframeLoading(true);
      const url = mapGameUrl({
        gameId: gameData.gameId,
        platform: gameData.productCode,
        sessionKey: currencySessionKey,
        currencyCode: currencyCode,
      });
      setGameUrl(url);
    }
  }, [gameData, currencySessionKey, currencyCode]);

  return (
    <Box
      sx={({ breakpoints }) => ({
        maxWidth: '1440px',
        width: '100%',
        height: '100%',
        paddingBlock: '3rem',
        paddingInline: '3rem',
        [breakpoints.down(769)]: {
          padding: 0,
        },
        '@media (orientation: landscape) and (max-height: 500px)': {
          padding: 0,
        }
      })}>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent="center"
        gap='0.5rem'
        height='100%'
        width='100%'>
        <AspectRatio
          ratio="16/9"
          sx={({ breakpoints }) => ({
            height: '100%',
            aspectRatio: '16/9',
            [breakpoints.up(769)]: {
              maxHeight: '755px',
              borderRadius: '0.5rem',
              overflow: 'hidden',
            },
            [breakpoints.down(769)]: {
              display: 'flex',
            },
            '@media (orientation: landscape) and (max-height: 500px)': {
              display: 'flex',
              borderRadius: '0',
              overflow: 'unset',
              width: '100%',
              aspectRatio: 'unset',
              alignItems: 'flex-start',
              '& .MuiAspectRatio-content': {
                width: '100%',
                pb: 0,
              }
            }
          })}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%',
            }}>
            <Skeleton
              variant='overlay'
              loading={iframeLoading || isFetching}
            />
            {!isFetching && (
              <GameIframe
                onLoad={handleLoad}
                key={gameUrl}
                src={gameUrl}
              />
            )}
          </Box>
        </AspectRatio>
        <IconButton
          size='md'
          onClick={handleClose}
          sx={({ breakpoints }) => ({
            '@media (orientation: landscape) and (max-height: 500px)': {
              display: 'none',
            },
            [breakpoints.down(769)]: {
              display: 'none',
            },
          })}>
          <Close />
        </IconButton>
      </Stack>
    </Box>
  );
};
