import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { Input } from '@mui/joy';
import { Close, SearchRounded } from '@mui/icons-material';
import { IconButton } from '@shared/ui';
import { debounce, useMedia } from '@shared/lib';
import { selectSearch, setSearch, useGamesFilterBySearchSelector } from '../model';
import { fetchGamesBySearch } from '../api';

const AWAIT_TIME = 500;

export type SearchProps = {
  onSearch(): void;
  onClear(): void;
};

export const Search: FC<SearchProps> = ({ onSearch, onClear }) => {
  const isMobileView = useMedia(`(max-width: ${768}px)`);
  // TODO: fix this cringe useDispatch generic
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const search = useGamesFilterBySearchSelector(selectSearch);

  const clearSearch = (): void => {
    dispatch(setSearch(''));
    onClear();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce(async (value: string) => {
      if (value.length >= 3) {
        await dispatch(fetchGamesBySearch({ search: value, page: 1, pageSize: isMobileView ? 12 : 34 })).unwrap();
        onSearch();
      }

      if (!value.length) {
        clearSearch();
      }
    }, AWAIT_TIME),
    [dispatch, isMobileView]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    dispatch(setSearch(value));
    searchGames(value);
  };

  const handleClear = clearSearch;

  return (
    <Input
      className='search-input'
      onChange={handleSearch}
      placeholder='Search'
      value={search}
      startDecorator={<SearchRounded sx={{ fontSize: '1.5rem' }} />}
      endDecorator={
        <IconButton onClick={handleClear}>
          <Close sx={{ fontSize: '1.5rem' }} />
        </IconButton>
      }
      sx={(theme) => ({
        width: '22.375rem',
        border: 'none',
        padding: '0.5rem 1rem',
        background: theme.colorSchemes.dark.palette.common[900],
        borderColor: 'transparent',
        ':hover': {
          boxShadow: 'none',
        },
        '&.Mui-focused': {
          boxShadow: 'none',
        },
        [theme.breakpoints.down(912)]: {
          maxWidth: '100%',
          background: theme.colorSchemes.dark.palette.common[475],
        },
      })}
    />
  );
};
