import { FC, ReactNode } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/joy';

type StickyNavigationItemProps = {
  text: string;
  url?: Url;
  icon: ReactNode;
  onClick?: () => void;
};

export const StickyNavigationItem: FC<StickyNavigationItemProps> = ({ text, url, icon, onClick }) => {
  const navigate = useNavigate();
  const match = useMatch(url || '_');

  const handleClick = (): void => {
    if (url) return navigate(url);
    if (onClick) return onClick();
  };
  return (
    <Button
      variant='plain'
      onClick={handleClick}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: '0.625rem 0.125rem',
        alignItems: 'center',
        gap: '0.25rem',
        color: theme.colorSchemes.dark.palette.common[200],
        borderRadius: 0,
        '&:last-child': {
          gridColumn: 2,
        },
      })}>
      {icon}
      <Typography
        sx={(theme) => ({
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.125rem',
          color: theme.colorSchemes.dark.palette.common[200],
          ...(!!match && {
            color: theme.colorSchemes.dark.palette.common[300],
          }),
        })}
      >
        {text}
      </Typography>
    </Button>
  );
};
