import { FC, ReactElement, useMemo } from 'react';
import {
  Stack,
  Typography,
  useTheme
} from '@mui/joy';
import {
  CheckCircleOutline,
  ErrorOutline
} from '@mui/icons-material';
import { UserRegistrationStatusEnum } from '@shared/types';
import {
  UserKycStatusEnum,
  useSessionSelector,
  selectKycStatus,
  selectRegistrationStatus
} from '../model';

export type StatusInfo = {
  message: string;
  color: string;
  icon?: ReactElement;
};

export const UserVerificationIndicator: FC = () => {
  const theme = useTheme();
  const statusMapper = useMemo<Record<string, StatusInfo>>(() => ({
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Unknown}`]: {
      message: 'Please confirm your identity by completing your player profile as seen on your ID and uploading ID for verification',
      color: theme.palette.common[1325],
      icon: <ErrorOutline sx={{ fontSize: 16, color: theme.palette.common[1325] }} />
    },
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Pending}`]: {
      message: 'Please confirm your identity by completing your player profile as seen on your ID',
      color: theme.palette.common[1325],
      icon: <ErrorOutline sx={{ fontSize: 16, color: theme.palette.common[1325] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Unknown}`]: {
      message: 'Please confirm your identity by uploading ID for verification',
      color: theme.palette.common[1325],
      icon: <ErrorOutline sx={{ fontSize: 16, color: theme.palette.common[1325] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Pending}`]: {
      message: 'You identity is now being verified',
      color: theme.palette.common[1060],
      icon: <CheckCircleOutline sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Failed}`]: {
      message: 'Your identity verification wasn`t successful. Please contact Customer Support for assistance',
      color: theme.palette.common[1325],
      icon: <ErrorOutline sx={{ fontSize: 16, color: theme.palette.common[1325] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Pass}`]: {
      message: 'Your account is verified',
      color: theme.palette.common[825],
      icon: <CheckCircleOutline sx={{ fontSize: 16, color: theme.palette.common[825] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Suspended}`]: {
      message: 'Your account has been suspended for security reasons',
      color: theme.palette.common[1325],
      icon: <ErrorOutline sx={{ fontSize: 16, color: theme.palette.common[1325] }} />
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Requested}`]: {
      message: 'Additional documents are required to verify your identity. Please contact Customer Support for assistance',
      color: theme.palette.common[1060],
      icon: <CheckCircleOutline sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
    },
  }), [theme.palette.common]);
  const registrationStatus = useSessionSelector(selectRegistrationStatus);
  const kycStatus = useSessionSelector(selectKycStatus);
  const status = statusMapper[`${registrationStatus}:${kycStatus}`];
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      gap={.5}>
      {status?.icon}
      <Typography
        sx={{
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: status?.color
        }}>
        {status?.message}
      </Typography>
    </Stack>
  );
};