import { createSlice } from "@reduxjs/toolkit";
import { luxeClubPageApiSlice } from "../api";
import { LuxeClubPageState } from "./types";

const initialState: LuxeClubPageState = {
  canonical: null,
  description: null,
  heading: null,
  keywords: null,
  title: null,
}

export const luxeClubPageSlice = createSlice({
  name: 'luxe-club-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      luxeClubPageApiSlice.endpoints.getLuxeClubPage.matchFulfilled,
      (state, action) => {
        state.description = action.payload.title
        state.title = action.payload.description
        state.keywords = action.payload.keywords
        state.canonical = action.payload.canonical
        state.heading = action.payload.heading
      })
  },
})

export const luxeClubPageReducer = luxeClubPageSlice.reducer