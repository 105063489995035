import { ComponentType } from 'react';
import { CircularLoader } from '@shared/ui';

export const withLoaderRoot = <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) => (
    <>
      <WrappedComponent {...props} />
      <CircularLoader />
    </>
  );
