import { createBrowserRouter } from 'react-router-dom';
import { Home } from '@pages/home';
import { Games } from '@pages/games';
import { Tournaments } from '@pages/tournaments';
import { Dynamic } from '@pages/dynamic';
import { GameLaunchPage } from '@pages/game-launch-page';
import { SomethingWrongPage } from '@pages/something-wrong-page';
import { Promotions } from '@pages/promotions';
import { Account } from '@pages/account';
import { LuxeClubPage } from '@pages/luxe-club';
import { BaseLayout, GameLayout } from './layouts';
import { AuthenticatedOnlyRoute } from './guards';

export const appRouter = createBrowserRouter([
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/games', element: <Games /> },
      { path: '/races', element: <Tournaments /> },
      { path: '/:dynamicPageName', element: <Dynamic /> },
      { path: '/promotions', element: <Promotions /> },
      {
        path: '/luxe-club',
        element: <AuthenticatedOnlyRoute>
          <LuxeClubPage />
        </AuthenticatedOnlyRoute>
      }
    ],
  },
  {
    element: <GameLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/games/:id',
        element: (
          <AuthenticatedOnlyRoute>
            <GameLaunchPage />
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/account',
        element: (
          <AuthenticatedOnlyRoute>
            <Account />
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  }
]);
