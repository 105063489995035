type OmegaErrorsKey =
  'SIGN-IN' |
  'SIGN-UP' |
  'CONFIRMATION-SIGN-UP-MODAL' |
  'EDIT-FULL-PROFILE' |
  'NEW-PASSWORD-FORM';

export const omegaErrorsMapper: Record<OmegaErrorsKey, Record<string, string>> = {
  'SIGN-IN': {
    'NOT_FOUND': 'Invalid entry. Please try again'
  },
  'SIGN-UP': {
    'DUPLICATE': 'Already in use. Please use a different one or log in',
  },
  'CONFIRMATION-SIGN-UP-MODAL': {
    'NOT_FOUND': 'Invalid entry. Please try again'
  },
  'EDIT-FULL-PROFILE': {
    'DUPLICATE': '{{field}} already in use',
  },
  'NEW-PASSWORD-FORM': {
    'NOT_FOUND': 'This code has expired. Please try again',
    'INVALID_PASSWORD': 'Invalid entry. Please try again',
  }
};
