import { FC } from 'react';
import { Typography } from '@mui/joy';

export const NoTransactionsLabel: FC = () => (
  <Typography
    sx={({ palette }) => ({
      fontSize: 14,
      fontWeight: 600,
      color: palette.common[150],
      textAlign: 'center'
    })}>
    No transactions
  </Typography>
);
