import { PromotionCard } from '@entities/promotions';
import { mapper } from '@shared/lib';

type MapPromotionCards = (data: any) => PromotionCard
export const mapPromotionCards: MapPromotionCards = mapper([
  { name: 'id', from: 'did' },
  { name: 'title', from: 'title' },
  { name: 'description', from: 'description' },
  { name: 'bannerUrl', from: 'bannerUrl' },
  { name: 'imageSrc', from: 'imageSrc.data.attributes.url' },
  { name: 'actions', from: 'actions' },
])
