import { FC, useState } from 'react';
import { Box, BoxProps, Skeleton } from '@mui/joy';

export type SidebarMenuBannerProps = Omit<BoxProps, 'component'> & {
  imgSrc?: Url;
};

export const SidebarMenuBanner: FC<SidebarMenuBannerProps> = ({
  imgSrc,
  ...props
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const handleImageLoad = (): void => {
    setImageLoaded(true);
  };

  return (
    <Box
      sx={({ breakpoints }) => ({
        height: 179,
        width: 358,
        borderRadius: 8,
        overflow: 'hidden',
        ...(imageLoaded && imgSrc && ({
          height: 179,
          width: 'auto',
        })),
        [breakpoints.down(491)]: {
          height: '100%',
        }
      })}>
      <Box
        component='img'
        src={imgSrc}
        onLoad={handleImageLoad}
        alt='Sidebar menu banner'
        sx={{
          display: 'none',
          ...(imageLoaded && imgSrc && ({
            display: 'block',
            height: '100%',
            width: '100%'
          }))
        }}
        {...props}>
      </Box>
      {!imageLoaded && <Skeleton
        animation='wave'
        sx={{
          height: 179,
          width: 358,
          borderRadius: 8,
        }}
      />}
    </Box>
  );
};