import { TextWithInlineLink } from '@shared/lib';

export type PageHeader = {
  logo: {
    variant1SizeImgSrc: Url;
    variant2SizeImgSrc: Url;
  };
};

export type PageFooterNavigationItem = {
  title: string;
  items: Array<{ text: string; slug: string }>;
};

export type PageFooter = {
  logo: {
    variant1SizeImgSrc: Url;
  };
  textWithInlineLink: TextWithInlineLink;
  promotionLegalNote: string;
  navigationItems: Array<PageFooterNavigationItem>;
};

export enum AuthVisibilityEnum {
  AuthorizedOnly = 'authorized-only',
  UnauthorizedOnly = 'unauthorized-only',
  AllUsers = 'all-users'
};

export type SidebarMenuItem = {
  id: number;
  text: string;
  slug: Url;
  iconSrc: Url;
  order: number,
  authVisibility?: AuthVisibilityEnum;
};

export type StickyNavigationItem = SidebarMenuItem & {
  centerButton: boolean;
};

export type SidebarMenu = {
  logo: {
    variant1SizeImgSrc: Url;
  };
  banner: {
    variant1SizeImgSrc: Url;
  };
  menuItems: Array<SidebarMenuItem>;
};

export type StickyNavigation = {
  menuItemsAuth: Array<StickyNavigationItem>;
  menuItemsNotAuth: Array<StickyNavigationItem>;
};

export type LayoutState = {
  header: Maybe<PageHeader>;
  footer: Maybe<PageFooter>;
  sidebarMenu: Maybe<SidebarMenu>;
  stickyNavigation: Maybe<StickyNavigation>;
};
