import { FC, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, Box, Typography } from '@mui/joy';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  IconButton,
  Button
} from '@shared/ui';
import { useMedia } from '@shared/lib';
import { type GameCategory} from '@entities/games';
import { setSelectedCategoryId } from '@features/games-filter-by-category';
import { setSearch } from '@features/games-filter-by-search';

type GamesCategoryItemHeaderProps = {
  category: GameCategory;
  navigationNextRef: RefObject<HTMLButtonElement>;
  navigationPrevRef: RefObject<HTMLButtonElement>;
};

export const GamesCategoryItemHeader: FC<GamesCategoryItemHeaderProps> = ({
  category,
  navigationNextRef,
  navigationPrevRef
}) => {
  const isMobileView = useMedia(`(max-width: ${912}px)`);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewAllClick = (): void => {
    dispatch(setSelectedCategoryId(category.id));
    dispatch(setSearch(''));
    navigate('/games');
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'>
      <Stack
        direction='row'
        gap={1.5}>
        <Stack
          direction='row'
          alignItems='center'
          gap={0.5}>
          {category?.iconSrc && (
            <Box
              component='img'
              src={category.iconSrc}
              sx={({ breakpoints }) => ({
                width: '32px',
                aspectRatio: '1/1',
                [breakpoints.down(912)]: {
                  width: '24px',
                },
              })}
            />
          )}
          {category?.name && (
            <Typography
              sx={({ colorSchemes, breakpoints }) => ({
                fontSize: '28px',
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: '36px',
                color: colorSchemes.dark.palette.common.white,
                [breakpoints.down(912)]: {
                  fontSize: '24px',
                  lineHeight: '30px',
                },
              })}>
              {category?.name}
            </Typography>
          )}
        </Stack>

        {!isMobileView &&
          <Stack
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}>
            <IconButton
              ref={navigationPrevRef}
              sx={(theme) => ({
                background: 'transparent',
                color: theme.colorSchemes.dark.palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowLeftIcon />
            </IconButton>

            <IconButton
              ref={navigationNextRef}
              sx={(theme) => ({
                background: 'transparent',
                color: theme.colorSchemes.dark.palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        }
      </Stack>

      <Button
        type='button'
        variant='plain'
        sx={({ colorSchemes }) => ({
          color: colorSchemes.dark.palette.common[150],
          fontSize: '16px',
          fontWeight: 500,
        })}
        onClick={handleViewAllClick}>
        View All
      </Button>
    </Stack>
  );
};
