import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Tournament, TournsmentsState } from './types';
import { fetchTournaments } from '../api';

const initialState: TournsmentsState = {
  isLoading: true,
  publicTournaments: [],
};

export const tournamentsSlice = createSlice({
  name: 'tournaments-list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTournaments.pending, (state: TournsmentsState) => {
        state.isLoading = true;
      })
      .addCase(fetchTournaments.rejected, (state: TournsmentsState) => {
        state.isLoading = false;
      })
      .addCase(
        fetchTournaments.fulfilled,
        (state: TournsmentsState, action: PayloadAction<Array<Tournament>>) => {
          state.isLoading = false;
          state.publicTournaments = action.payload.sort(
            (a: Tournament, b: Tournament) =>
              new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
          );
        }
      );
  },
});

export const tournamentsReducer = tournamentsSlice.reducer;
