import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/joy';
import { CarouselSlider } from '@shared/ui';
import { FaqBlock } from '@widgets/faq-block';
import { HowItWorksBlock } from '@widgets/how-it-works-block';
import {
  selectSlider,
  selectHowItWorksBlock,
  selectPromotionFaqBlock,
  usePromotionPageContentSelector
} from '../model';

export const PromotionContent: FC = () => {
  const slider = useSelector(selectSlider);
  const howItWorksBlock = usePromotionPageContentSelector(selectHowItWorksBlock);
  const faqBlock = usePromotionPageContentSelector(selectPromotionFaqBlock);
  return (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 4,
        paddingBlockEnd: 8,
        [breakpoints.down(1350)]: {
          paddingInline: 2,
        },
        [breakpoints.down(414)]: {
          paddingBlockEnd: 3,
          paddingBlockStart: 2,
        }
      })}>
      {slider && (
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            mb: 8,
            [breakpoints.down(414)]: {
              mb: 6,
            },
            '& .swiper-slide': {
              borderRadius: 8,
              overflow: 'hidden'
            }
          })}>
          <CarouselSlider items={slider} />
        </Stack>
      )}
      {howItWorksBlock && (
        <HowItWorksBlock
          title={howItWorksBlock.title}
          items={howItWorksBlock.items}
        />
      )}
      {faqBlock && (
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            mt: 3,
            [breakpoints.down(414)]: {
              mt: 2,
            }
          })}>
          <FaqBlock
            items={faqBlock.items}
          />
        </Stack>
      )}
    </Stack>
  );
};