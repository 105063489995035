import { mapper } from '@shared/lib';
import { type HomePageState } from '../model/types';
import { mapPlayInThreeSteps } from './map-play-in-three-steps';
import { mapHomePageCarouselSliderItem } from './map-carousel-slider-item';
import { mapHomePageGamesByCategoriesItems } from './map-games-by-categories-items';

type MapHomePageState = (data: any) => HomePageState;
export const mapHomePageState: MapHomePageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  {
    name: 'playInThreeSteps',
    from: 'data.attributes.playInThreeSteps',
    transform: (playInThreeStepsData: any) => mapPlayInThreeSteps(playInThreeStepsData),
  },
  {
    name: 'slider',
    from: 'data.attributes.carouselSlider',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapHomePageCarouselSliderItem),
  },
  {
    name: 'gamesByCategoriesBlockTop',
    from: 'data.attributes.gamesByCategoriesBlockTop',
    transform: (gamesByCategoriesItemsData: Maybe<Array<any>>) =>
      mapHomePageGamesByCategoriesItems(gamesByCategoriesItemsData),
  },
  {
    name: 'gamesByCategoriesBlockBottom',
    from: 'data.attributes.gamesByCategoriesBlockBottom',
    transform: (gamesByCategoriesItemsData: Maybe<Array<any>>) =>
      mapHomePageGamesByCategoriesItems(gamesByCategoriesItemsData),
  },
  { name: 'tournamentWidgetTitle', from: 'data.attributes.tournamentWidgetTitle' },
]);
