import { FC } from 'react';
import { Button, ButtonProps } from '@shared/ui';
import { useModalQueryParam } from '@shared/lib';

export type SignInButtonProps = Pick<
  ButtonProps,
  'color' | 'size' | 'variant' | 'children'
>;

export const SignInButton: FC<SignInButtonProps> = ({
  children,
  size = 'lg',
  ...props
}) => {
  const { openModal } = useModalQueryParam();

  const handleOpenSignInModal = (): void => {
    openModal('login');
  };

  return (
    <Button
      color='success'
      size={size}
      onClick={handleOpenSignInModal}
      {...props}>
      {children}
    </Button>
  );
};
