import { FC, ReactNode } from 'react';
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/joy';
import { useMedia, isEmpty, type TextWithInlineLink as TextWithInlineLinkType } from '@shared/lib';
import { TextWithInlineLink } from '@shared/ui';
import { StepLoader } from './step-loader.component';

type StepPlayInThreeSteps = {
  iconSrc: Url;
  title: string;
  description: string;
};

export type PlayInThreeStepsProps = {
  actionSlot?: ReactNode;
  title: TextWithInlineLinkType;
  steps: Array<StepPlayInThreeSteps>;
  loading?: boolean;
};

export const PlayInThreeSteps: FC<PlayInThreeStepsProps> = ({ title, steps, actionSlot, loading }) => {
  const theme = useTheme();
  const isMobile = useMedia(`(max-width: ${490}px)`);
  const isTablet = useMedia(`(max-width: ${912}px)`);
  return (
    <Stack
      direction='column'
      sx={{
        backgroundColor: theme.colorSchemes.dark.palette.common[475],
        borderRadius: 8,
        gap: 5,
        paddingBlock: 5,
        paddingInline: 8,
        position: 'relative',
        ...(isTablet && {
          gap: 2.5,
          paddingBlock: 3,
          paddingInline: 2,
        }),
      }}
    >
      {loading ? (
        <Skeleton
          width={120}
          height={42}
          variant='inline'
          loading={loading}
          sx={{ marginInline: 'auto' }}
        />
      ) : !isEmpty(title) ? (
        <TextWithInlineLink
          data={title}
          typographyProps={{
            textAlign: 'center',
            fontSize: 28,
            ...(isMobile && {
              textAlign: 'start',
              fontSize: 24,
            }),
          }}
        />
      ) : null}

      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: 3,
          justifyContent: 'space-between',
          ...(isTablet && {
            flexDirection: 'column',
            gap: 2.5,
          }),
        }}
      >
        {loading &&
          Array(3)
            .fill(null)
            .map((_, i) => <StepLoader key={i} />)}
        {!loading &&
          steps.map(({ iconSrc, title, description }, index) => (
            <Box maxWidth={326} key={index}>
              <Stack direction='row' justifyContent={isMobile ? 'flex-start' : 'center'} alignItems='center' gap={1.5}>
                <Box component='img' src={iconSrc} />
                <Typography
                  sx={{
                    fontSize: 18,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '28px',
                    color: theme.colorSchemes.dark.palette.common.white,
                  }}
                >
                  {title}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: theme.colorSchemes.dark.palette.common[150],
                  ...(isMobile && {
                    textAlign: 'start',
                  }),
                }}
              >
                {description}
              </Typography>
            </Box>
          ))}
      </Stack>
      {actionSlot && (
        <Stack direction='row' justifyContent='center'>
          {actionSlot}
        </Stack>
      )}
    </Stack>
  );
};
