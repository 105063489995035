import { FC, ReactNode, useState, useRef, useCallback, useEffect } from 'react';
import { SocketContext } from './use-socket';

export interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const socketRef = useRef<Maybe<WebSocket>>(null);

  const connectSocket = useCallback((url: string) => {
    if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
      socketRef.current = new WebSocket(url);
      socketRef.current.onopen = () => setIsConnected(true);
      socketRef.current.onclose = () => setIsConnected(false);
    }
  }, []);

  const disconnectSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setIsConnected(false);
    }
  }, []);

  const subscribe = useCallback((handler: (data: any) => void) => {
    if (!socketRef.current) return () => {};
    socketRef.current.onmessage = handler;
    return () => {
      if (socketRef.current) socketRef.current.onmessage = () => {};
    };
  }, []);

  useEffect(() => {
    return () => disconnectSocket();
  }, [disconnectSocket]);

  return (
    <SocketContext.Provider
      value={{
        isConnected,
        connectSocket,
        disconnectSocket,
        subscribe,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
