import { mapper } from '@shared/lib';
import { type HomePageCarouselSliderItem } from '../model/types';

type MapHomePageCarouselSliderItemAction = (data: any) => {
  text: string;
  slug: Url;
};
const mapHomePageCarouselSliderItemAction: MapHomePageCarouselSliderItemAction =
  mapper([
    { name: 'text', from: 'text' },
    { name: 'url', from: 'url' },
    { name: 'userState', from: 'userState' },
  ]);

type MapHomePageCarouselSliderItem = (data: any) => HomePageCarouselSliderItem;
export const mapHomePageCarouselSliderItem: MapHomePageCarouselSliderItem = mapper([
  { name: 'id', from: 'id' },
  {
    name: 'actions',
    from: 'actions',
    transform: (actionData: Array<any>) =>
      actionData?.map(mapHomePageCarouselSliderItemAction),
  },
  {
    name: 'variant1ImgSrc',
    from: 'variant1ImgSrc.data.attributes.url',
  },
  {
    name: 'variant2ImgSrc',
    from: 'variant2ImgSrc.data.attributes.url',
  },
  { name: 'bannerUrl', from: 'bannerUrl' },
]);
