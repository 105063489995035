import {
  BaseQueryApi,
  type BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isEmpty } from '@shared/lib';
import {
  incrementActiveRequests,
  decrementActiveRequests
} from '@shared/ui/circular-loader';
import { OMEGA_API_URL } from '../config';

type RootState = {
  session: {
    sessionKey: string;
  };
};

const omegaQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = fetchBaseQuery({
  baseUrl: OMEGA_API_URL,
});

type OmegaBaseQueryExtraOptions = {
  auth?: boolean;
  showLoader?: boolean;
};

export const baseOmegaQuery = async (
  arg: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: OmegaBaseQueryExtraOptions = {}
) => {
  let newArgs = arg;

  if (extraOptions?.auth && typeof newArgs !== 'string') {
    const rootState = api.getState() as RootState;
    const sessionKey = rootState?.session?.sessionKey;

    if (!isEmpty(newArgs?.params)) {
      newArgs = {
        ...newArgs,
        params: {
          ...newArgs.params,
          sessionKey,
        },
      };
    } else {
      newArgs = {
        ...newArgs,
        params: { sessionKey },
      };
    }
  }

  const shouldShowLoader = extraOptions?.showLoader ?? true;
  if (shouldShowLoader) api.dispatch(incrementActiveRequests());

  try {
    const result = await omegaQuery(newArgs, api, {});
    return result;
  } catch (error) {
    return { error };
  } finally {
    if (shouldShowLoader) api.dispatch(decrementActiveRequests());
  }
};