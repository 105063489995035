import { combineReducers } from 'redux';
import { baseApi, baseOmegaApi } from '@shared/api';
import {
  circularLoaderSlice,
  circularLoaderReducer
} from '@shared/ui/circular-loader';
import {
  sessionSlice,
  sessionSliceReducer,
  sessionApiSlicer
} from '@entities/session';
import {
  gamesReducer,
  gamesSlice,
  gamesListApiSlice
} from '@entities/games';
import {
  messagesSlice,
  messagesReducer
} from '@entities/messages';
import {
  pageLayoutSlice,
  pageLayoutReducer,
  pageLayoutApiSlice
} from '@entities/page-layout';
import {
  coinPackagesSlice,
  coinPackagesReducer,
  coinPackagesApiSlice
} from '@entities/coin-packages';
import {
  transactionsSlice,
  transactionsReducer,
  transactionsApiSlicer
} from '@entities/transactions';
import { tournamentsSlice, tournamentsReducer } from '@entities/tournaments';
import { signUpSlice, signUpSliceReducer } from '@features/sign-up';
import {
  managePromotionalSubscriptionsSlice,
  managePromotionalSubscriptionsReducer,
  managePromotionalSubscriptionsApiSlicer
} from '@features/manage-promotional-subscriptions';
import { editFullProfileApiSlicer } from '@features/edit-full-profile';
import {
  resetPasswordSlice,
  resetPasswordReducer
} from '@features/reset-password';
import {
  gamesFilteredByCategoriesSlice,
  gamesFilteredByCategoriesReducer
} from '@features/games-filter-by-category';
import {
  gamesFilteredBySearchSlice,
  gamesFilteredBySearchReducer
} from '@features/games-filter-by-search';
import {
  gamesFilteredByFavoriteSlice,
  gamesFilteredByFavoriteReducer
} from '@features/games-filter-by-favorite';
import {
  documentsSlice,
  documentsReducer
} from '@features/upload-documents';
import {
  sidebarMenuSlice,
  sidebarMenuReducer
} from '@widgets/sidebar-menu';
import {
  transactionListReducer,
  transactionListSlice
} from '@widgets/transaction-list';
import {
  gamesListWidgetSlice,
  gamesListWidgetReducer
} from '@widgets/game-list';
import {
  homePageSlice,
  homePageReducer,
  homePageApiSlice
} from '@pages/home';
import {
  gamesPageReducer,
  gamesPageSlice
} from '@pages/games';
import {
  tournamentsPageReducer,
  tournamentsPageSlice
} from '@pages/tournaments';
import {
  dynamicPageReducer,
  dynamicPageSlice,
  dynamicPageApiSlice
} from '@pages/dynamic';
import {
  promotionsPageApiSlice,
  promotionsPageReducer,
  promotionsPageSlice
} from '@pages/promotions';
import {
  accountPageSlice,
  accountPageReducer
} from '@pages/account';

export const rootReducer = combineReducers({
  [circularLoaderSlice.name]: circularLoaderReducer,
  [pageLayoutSlice.name]: pageLayoutReducer,
  [coinPackagesSlice.name]: coinPackagesReducer,
  [tournamentsSlice.name]: tournamentsReducer,
  [sidebarMenuSlice.name]: sidebarMenuReducer,
  [homePageSlice.name]: homePageReducer,
  [sessionSlice.name]: sessionSliceReducer,
  [signUpSlice.name]: signUpSliceReducer,
  [gamesPageSlice.name]: gamesPageReducer,
  [gamesSlice.name]: gamesReducer,
  [tournamentsPageSlice.name]: tournamentsPageReducer,
  [messagesSlice.name]: messagesReducer,
  [gamesFilteredByCategoriesSlice.name]: gamesFilteredByCategoriesReducer,
  [gamesFilteredBySearchSlice.name]: gamesFilteredBySearchReducer,
  [gamesFilteredByFavoriteSlice.name]: gamesFilteredByFavoriteReducer,
  [documentsSlice.name]: documentsReducer,
  [gamesListWidgetSlice.name]: gamesListWidgetReducer,
  [dynamicPageSlice.name]: dynamicPageReducer,
  [promotionsPageSlice.name]: promotionsPageReducer,
  [accountPageSlice.name]: accountPageReducer,
  [managePromotionalSubscriptionsSlice.name]: managePromotionalSubscriptionsReducer,
  [resetPasswordSlice.name]: resetPasswordReducer,
  [transactionsSlice.name]: transactionsReducer,
  [transactionListSlice.name]: transactionListReducer,

  // API
  [baseApi.reducerPath]: baseApi.reducer,
  [baseOmegaApi.reducerPath]: baseOmegaApi.reducer,
  [homePageApiSlice.reducerPath]: homePageApiSlice.reducer,
  [pageLayoutApiSlice.reducerPath]: pageLayoutApiSlice.reducer,
  [coinPackagesApiSlice.reducerPath]: coinPackagesApiSlice.reducer,
  [sessionApiSlicer.reducerPath]: sessionApiSlicer.reducer,
  [gamesListApiSlice.reducerPath]: gamesListApiSlice.reducer,
  [dynamicPageApiSlice.reducerPath]: dynamicPageApiSlice.reducer,
  [promotionsPageApiSlice.reducerPath]: promotionsPageApiSlice.reducer,
  [editFullProfileApiSlicer.reducerPath]: editFullProfileApiSlicer.reducer,
  [managePromotionalSubscriptionsApiSlicer.reducerPath]: managePromotionalSubscriptionsApiSlicer.reducer,
  [transactionsApiSlicer.reducerPath]: transactionsApiSlicer.reducer
});
