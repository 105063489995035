import { Tournament } from '../model';
import { mapTournament } from './map-tournament';

type MapTournaments = (data: any) => Array<Tournament>;

export const mapTournaments: MapTournaments = (data: any) => {
  return data.tournamentList.map((tournament: any) =>
    mapTournament(tournament)
  );
};
