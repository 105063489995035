import { baseApi, HOME_PAGE_TAG } from '@shared/api';
import { mapHomePageState } from '../lib';
import { HomePageState } from '../model';

export const homePageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getHomePage: build.query<HomePageState, void>({
      query: () => ({ url: 'api/home-page' }),
      extraOptions: { showLoader: true },
      providesTags: [HOME_PAGE_TAG],
      transformResponse: (response: any) => mapHomePageState(response),
    })
  }),
})

export const { useGetHomePageQuery } = homePageApiSlice;
