import { FC, useMemo } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/joy';
import { AccessTime, Check, Description, RemoveRedEyeOutlined } from '@mui/icons-material';
import { isEmpty, useMedia } from '@shared/lib';
import {
  type UserDocument,
  type DocumentType,
  selectDocumentTypes,
  selectUserDocuments,
  useDocumentsSelector,
} from '../model';

export const UserDocumentsList: FC = () => {
  const isMobile = useMedia(`(max-width: ${767}px)`);
  const theme = useTheme();

  const documentTypes = useDocumentsSelector(selectDocumentTypes);
  const userDocuments = useDocumentsSelector(selectUserDocuments);
  
  const pretifyFileName = (document: UserDocument): string => {
    return document.fileName
      .slice(document.fileName.indexOf(document.documentType) 
        + document.documentType.length + 1);
  }

  const grouppedUserDocuments: Record<string, Array<UserDocument>> = useMemo(() => {
    const documents: Record<string, Array<UserDocument>> = {};
    documentTypes.forEach((documentType: DocumentType) => {
      const documentsInType: Array<UserDocument> = userDocuments.filter(
        (userDocoment: UserDocument) => userDocoment.documentType === documentType.documentType
      );

      if (!isEmpty(documentsInType)) {
        documents[documentType.documentType] = documentsInType;
      }
    });

    return documents;
  }, [documentTypes, userDocuments]);

  return (
    <>
      <Stack component='aside' direction='column' width='100%' gap={2}>
        {!isEmpty(userDocuments) && (
          <>
            <Typography
              sx={({ palette }) => ({
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                color: palette.common.white,
              })}>
              Uploaded documents
            </Typography>

            {Object.keys(grouppedUserDocuments).map((key: string) => (
              <Stack
                key={key}
                sx={({ palette }) => ({
                  padding: '12px',
                  gap: 1.5,
                  alignItems: 'start',
                  backgroundColor: palette.common[475],
                  borderRadius: 10,
                })}>
                <Typography
                  sx={({ palette }) => ({
                    textAlign: 'center',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: palette.common.white,
                  })}>
                  {key}
                </Typography>

                {grouppedUserDocuments[key].map((document: UserDocument, index: number) => (
                  <Stack
                    key={index}
                    sx={{
                      width: '100%',
                      gap: 1.5,
                    }}>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr auto', gap: 1 }}>
                      <Stack direction='row' alignItems='center' gap={0.5} sx={{ wordBreak: 'break-all' }}>
                        <Description
                          sx={({ palette }) => ({
                            width: 16,
                            height: 16,
                            fill: palette.common.black,
                            opacity: 0.8
                          })}/>

                        <Typography
                          sx={({ palette }) => ({
                            textAlign: 'center',
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                            color: palette.common.white,
                          })}>
                          {pretifyFileName(document)}
                        </Typography>
                      </Stack>

                      <Stack direction='row' gap={1.5}>
                        {!isMobile && (
                          <Typography
                            sx={({ palette }) => ({
                              textAlign: 'center',
                              fontSize: 14,
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '18px',
                              color: document.status === 'APPROVED' ? palette.common[825] : palette.common[1060],
                            })}>
                            {document.status === 'APPROVED' ? 'Reviewed' : 'Not reviewed'}
                          </Typography>
                        )}

                        {isMobile && document.status !== 'APPROVED' && (
                          <AccessTime sx={{
                            width: 20,
                            height: 20,
                            fill: theme.palette.common[1060]
                          }}/>
                        )}

                        {isMobile && document.status === 'APPROVED' && (
                          <Check sx={{
                            width: 20,
                            height: 20,
                            fill: theme.palette.common[825]
                          }}/>
                        )}

                        <RemoveRedEyeOutlined sx={{
                          width: 20,
                          height: 20,
                          fill: theme.palette.common[300]
                        }}/>
                      </Stack>
                    </Stack>

                    {index !== grouppedUserDocuments[key].length - 1 && (
                      <Box
                        sx={({ palette }) => ({
                          height: '1px',
                          width: '100%',
                          backgroundColor: palette.common[925]
                        })}/>
                    )}
                  </Stack>
                ))}
              </Stack>
            ))}
          </>
        )}

        {isEmpty(userDocuments) && (
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              width: '100%',
              height: '100%',
              gap: 2
            }}>
            <Description
              sx={{
                fontSize: 71,
                color: theme.palette.common[475],
              }}/>

            <Typography
              sx={({ palette }) => ({
                textAlign: 'center',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '18px',
                color: palette.common.white,
                maxWidth: 250,
              })}>
              Your documents will appear here as soon as you upload them
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};
