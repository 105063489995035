import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { type GamesPageState } from './types';
import { gamesPageSlice } from './slice';

type RootState = {
  [gamesPageSlice.name]: GamesPageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[gamesPageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[gamesPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[gamesPageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[gamesPageSlice.name]?.canonical
);

export const selectIsLoading = (state: RootState): boolean => (
  state[gamesPageSlice.name].isLoading
)

export const useGamesPageSelector: TypedUseSelectorHook<{
  [gamesPageSlice.name]: GamesPageState;
}> = useSelector;
