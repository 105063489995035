import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/joy';

export type HowItWorksBlockItem = {
  iconSrc?: Maybe<Url>;
  details?: Maybe<string>;
};

export type HowItWorksBlockProps = {
  title?: Maybe<string>;
  items?: Maybe<Array<HowItWorksBlockItem>>;
  action?: Maybe<{
    url: Url;
    text: string;
  }>;
};

export const HowItWorksBlock: FC<HowItWorksBlockProps> = ({
  title,
  items,
  action
}) => {
  const navigate = useNavigate();
  const handleNavigateTo = (slug: Url) => (): void => {
    navigate(slug);
  };
  return (
    <Stack
      direction='column'
      gap={1.5}>
      {title && (
        <Typography sx={({ breakpoints, colorSchemes }) => ({
          width: '100%',
          fontSize: 28,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '36px',
          textAlign: 'start',
          color: colorSchemes.dark.palette.common.white,
          paddingBlockEnd: 2,
          [breakpoints.down(769)]: {
            fontSize: 20,
            lineHeight: '30px',
          }
        })}>
          {title}
        </Typography >
      )}
      {items?.map(({ iconSrc, details }, index) => (
        <Box
          key={index}
          sx={({ breakpoints, colorSchemes }) => ({
            display: 'grid',
            gridTemplateColumns: '40px auto',
            columnGap: 1.5,
            padding: 3,
            borderRadius: 8,
            backgroundColor: colorSchemes.dark.palette.common[475],
            [breakpoints.down(769)]: {
              padding: 1.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1.5
            }
          })}>
          {iconSrc ? (
            <Box
              component={iconSrc ? 'img' : 'div'}
              src={iconSrc}
              sx={{
                width: 40,
                height: 40,
                aspectRatio: '1/1'
              }}
            />
          ) : <Box />}
          <Typography
            sx={({ breakpoints, colorSchemes }) => ({
              width: '100%',
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'start',
              color: colorSchemes.dark.palette.common.white,
              [breakpoints.down(769)]: {
                textAlign: 'center',
              }
            })}>
            {details}
          </Typography>
        </Box>
      ))}
      {action && (
        <Button
          color='primary'
          size='sm'
          onClick={handleNavigateTo(action.url)}>
          {action.text}
        </Button>
      )}
    </Stack>
  );
};
